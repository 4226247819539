var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "third_hot" },
    [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 }
          }),
          _vm._m(0)
        ],
        1
      ),
      _c("col-form", {
        ref: "colForm",
        attrs: {
          type: "thirdHot",
          title: "三栏式",
          "goods-num": 3,
          "form-data": _vm.configData
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("图片宽度690px，高度415px。")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }