"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _audit = require("@/api/audit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editId', 'goodsId'],
  data: function data() {
    return {
      submitForm: {
        setmeal_id: "",
        title: "",
        lease_set: 1,
        is_huabei: 2,
        huabei_data: [{
          lease_term: "",
          rent_total: "",
          three_bear: "",
          three_fee: "",
          six_bear: "",
          six_fee: "",
          twelve_bear: "",
          twelve_fee: ""
        }],
        quantity: "",
        foregift: "",
        is_buy_out: 1,
        price: "",
        start_date: ""
      },
      rules: {
        title: [{
          required: true,
          message: '请输入套餐名字',
          trigger: 'blur'
        }],
        huabei_data: [{
          required: true,
          message: '请填写花呗设置',
          trigger: 'change'
        }],
        quantity: [{
          required: true,
          message: '请输入库存数量',
          trigger: 'blur'
        }],
        foregift: [{
          required: true,
          message: '请输入押金',
          trigger: 'blur'
        }],
        is_buy_out: [{
          required: true,
          message: '请选择是否买断',
          trigger: 'change'
        }],
        price: [{
          required: true,
          message: '请输入销售价格',
          trigger: 'blur'
        }],
        lease_set: [{
          required: true,
          message: '请选择租期设置',
          trigger: 'change'
        }]
      },
      monthList: [{
        lease_time: null,
        lease_price: ""
      }],
      // 按月租期设置
      monthSelect: [],
      // 可选月租期
      dayList: [{
        lease_time: "",
        lease_price: ""
      }],
      // 按天租期设置
      customList: [],
      //自定义租期
      customForm: {
        lease_days: "",
        withhold_cycle: "",
        lease_price: ""
      },
      intervalList: [],
      // 可选租期间隔
      setForm: {
        third: "",
        sixth: "",
        twelve: ""
      },
      feeData: [{
        num: 3,
        merchant: '1.80%',
        user: '2.30%'
      }, {
        num: 6,
        merchant: '4.50%',
        user: '4.50%'
      }, {
        num: 12,
        merchant: '7.50%',
        user: '7.50%'
      }],
      startDateSelect: [],
      rentType: 0
    };
  },
  methods: {
    handleHbCheck: function handleHbCheck(val) {
      if (val == 1) {
        if (this.submitForm.lease_set == 1) {
          for (var index in this.monthList) {
            var _this$monthList$index = this.monthList[index],
                lease_time = _this$monthList$index.lease_time,
                lease_price = _this$monthList$index.lease_price;
            var rentTotal = (parseFloat(lease_price) * lease_time).toFixed(2);

            if (this.submitForm.huabei_data[index]) {
              this.submitForm.huabei_data[index].lease_term = lease_time;
              this.submitForm.huabei_data[index].rent_total = rentTotal;
            } else {
              this.submitForm.huabei_data[index] = {
                lease_term: lease_time,
                rent_total: rentTotal,
                three_bear: "",
                three_fee: "",
                six_bear: "",
                six_fee: "",
                twelve_bear: "",
                twelve_fee: ""
              };
            }
          }
        } else if (this.submitForm.lease_set == 2) {
          for (var _index in this.dayList) {
            var _this$dayList$_index = this.dayList[_index],
                _lease_time = _this$dayList$_index.lease_time,
                _lease_price = _this$dayList$_index.lease_price;

            var _rentTotal = (parseFloat(_lease_price) * _lease_time).toFixed(2);

            if (this.submitForm.huabei_data[_index]) {
              this.submitForm.huabei_data[_index].lease_term = _lease_time;
              this.submitForm.huabei_data[_index].rent_total = _rentTotal;
            } else {
              this.submitForm.huabei_data[_index] = {
                lease_term: _lease_time,
                rent_total: _rentTotal,
                three_bear: "",
                three_fee: "",
                six_bear: "",
                six_fee: "",
                twelve_bear: "",
                twelve_fee: ""
              };
            }
          }
        }
      } else {
        this.submitForm.huabei_data = [{
          lease_term: "",
          rent_total: "",
          three_bear: "",
          three_fee: "",
          six_bear: "",
          six_fee: "",
          twelve_bear: "",
          twelve_fee: ""
        }];
      }
    },
    save: function save() {
      var _this = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          if (!_this.validCheck()) {
            return;
          }

          var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({
            goods_id: _this.goodsId,
            spec: []
          }, _this.submitForm), {}, {
            operation_type: 3
          });

          if (_this.submitForm.lease_set == 1) {
            params.spec = (0, _toConsumableArray2.default)(_this.monthList);
          } else if (_this.submitForm.lease_set == 2) {
            params.spec = (0, _toConsumableArray2.default)(_this.dayList);
          } else {
            params.spec = (0, _toConsumableArray2.default)(_this.customList);
          }

          (0, _audit.editGoodsSetmeal)(params).then(function (res) {
            _this.$router.push({
              name: 'goodsSuccess',
              params: {
                goodsId: _this.goodsId
              }
            });
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    },
    // 表单校验
    validCheck: function validCheck() {
      // 校验租期设置
      if (this.submitForm.lease_set == 1) {
        if (this.monthList.length === 0) {
          this.$message({
            message: "租期设置不能为空",
            type: "warning"
          });
          return false;
        }

        var _iterator = (0, _createForOfIteratorHelper2.default)(this.monthList),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;

            if (!item.lease_time || !item.lease_price) {
              this.$message({
                message: "租期设置必填项不能为空",
                type: "warning"
              });
              return false;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        var arr = this.monthList.map(function (k) {
          return k.lease_time;
        });
        var uniqueArr = (0, _toConsumableArray2.default)(new Set(arr));

        if (uniqueArr.length < arr.length) {
          this.$message({
            message: "租期设置租期不能重复",
            type: "warning"
          });
          return false;
        }
      } else if (this.submitForm.lease_set == 2) {
        if (this.dayList.length === 0) {
          this.$message({
            message: "租期设置不能为空",
            type: "warning"
          });
          return false;
        }

        var _iterator2 = (0, _createForOfIteratorHelper2.default)(this.dayList),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var _item = _step2.value;

            if (!_item.lease_time || !_item.lease_price) {
              this.$message({
                message: "租期设置必填项不能为空",
                type: "warning"
              });
              return false;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        var _arr = this.dayList.map(function (k) {
          return k.lease_time;
        });

        var _uniqueArr = (0, _toConsumableArray2.default)(new Set(_arr));

        if (_uniqueArr.length < _arr.length) {
          this.$message({
            message: "租期设置租期不能重复",
            type: "warning"
          });
          return false;
        }
      } else {
        if (this.customList.length === 0) {
          this.$message({
            message: "租期设置不能为空",
            type: "warning"
          });
          return false;
        }

        var _iterator3 = (0, _createForOfIteratorHelper2.default)(this.customList),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _item2 = _step3.value;

            var _iterator4 = (0, _createForOfIteratorHelper2.default)(_item2),
                _step4;

            try {
              for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                var row = _step4.value;

                if (!row.lease_price) {
                  this.$message({
                    message: "租期设置每期租金不能为空",
                    type: "warning"
                  });
                  return false;
                }
              }
            } catch (err) {
              _iterator4.e(err);
            } finally {
              _iterator4.f();
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      }

      if (this.submitForm.is_huabei == 1) {
        // 开启花呗，校验花呗设置数组
        if (this.submitForm.huabei_data.length === 0) {
          this.$message({
            message: "花呗设置不能为空",
            type: "warning"
          });
          return false;
        }

        var _iterator5 = (0, _createForOfIteratorHelper2.default)(this.submitForm.huabei_data),
            _step5;

        try {
          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
            var _item3 = _step5.value;

            if (!_item3.three_bear || !_item3.six_bear || !_item3.twelve_bear) {
              this.$message({
                message: "花呗设置承担选项不能为空",
                type: "warning"
              });
              return false;
            }
          }
        } catch (err) {
          _iterator5.e(err);
        } finally {
          _iterator5.f();
        }
      }

      return true;
    },
    // 切换租期设置类型
    changeType: function changeType(val) {
      // 切换重置
      this.submitForm.is_huabei = 2;
      this.submitForm.huabei_data = [{
        lease_term: "",
        rent_total: "",
        three_bear: "",
        three_fee: "",
        six_bear: "",
        six_fee: "",
        twelve_bear: "",
        twelve_fee: ""
      }];
      this.monthList = [{
        lease_time: null,
        lease_price: ""
      }];
      this.dayList = [{
        lease_time: "",
        lease_price: ""
      }];
      this.customForm = {
        lease_days: "",
        withhold_cycle: "",
        lease_price: ""
      };
    },
    quickSet: function quickSet() {
      var _this2 = this;

      this.submitForm.huabei_data.forEach(function (element) {
        if (_this2.setForm.third) {
          element.three_bear = _this2.setForm.third;
        }

        if (_this2.setForm.sixth) {
          element.six_bear = _this2.setForm.sixth;
        }

        if (_this2.setForm.twelve) {
          element.twelve_bear = _this2.setForm.twelve;
        }

        _this2.setHbFee(element);
      }); // 清空上次设置

      this.setForm = {
        third: "",
        sixth: "",
        twelve: ""
      };
    },
    // 花呗承担切换
    changeHbBear: function changeHbBear(val, obj, key) {
      if (val == 1 || val == 2) {
        this.setHbFee(obj);
      } else {
        obj[key] = "";
      }
    },
    // 保留两位小数
    toFix2: function toFix2(calVal) {
      return (Math.round(calVal * 100) / 100).toFixed(2);
    },
    // 设置花呗分期承担金额
    setHbFee: function setHbFee(tableItem) {
      if (tableItem.three_bear == 1) {
        tableItem.three_fee = this.toFix2(0.018 * tableItem.rent_total);
      }

      if (tableItem.three_bear == 2) {
        tableItem.three_fee = this.toFix2(0.023 * tableItem.rent_total);
      }

      if (tableItem.six_bear == 1) {
        tableItem.six_fee = this.toFix2(0.045 * tableItem.rent_total);
      }

      if (tableItem.six_bear == 2) {
        tableItem.six_fee = this.toFix2(0.045 * tableItem.rent_total);
      }

      if (tableItem.twelve_bear == 1) {
        tableItem.twelve_fee = this.toFix2(0.075 * tableItem.rent_total);
      }

      if (tableItem.twelve_bear == 2) {
        tableItem.twelve_fee = this.toFix2(0.075 * tableItem.rent_total);
      }
    },
    // 按月租期项更改
    itemChange: function itemChange(index) {
      var _ref = this.submitForm.lease_set == 1 ? this.monthList[index] : this.dayList[index],
          lease_time = _ref.lease_time,
          lease_price = _ref.lease_price;

      if (!lease_time || !lease_price) {
        return;
      }

      var hbSetItem = this.submitForm.huabei_data[index];
      hbSetItem.lease_term = lease_time;
      hbSetItem.rent_total = (parseFloat(lease_price) * lease_time).toFixed(2);
      this.setHbFee(hbSetItem);
    },
    changeNumberInput: function changeNumberInput(obj, key) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时

      if (!pattern.test(obj[key])) {
        // input 框绑定的内容为空
        obj[key] = '';
      }
    },
    changeMoneyInput: function changeMoneyInput(obj, key) {
      var pattern = /^[0-9]+([.]{1}[0-9]{1,2})?$/; // 正数的正则表达式
      // 不符合正数时

      if (!pattern.test(obj[key])) {
        // input 框绑定的内容为空
        obj[key] = '';
      }
    },
    // 删除自定义租期
    removeCustomItem: function removeCustomItem(index) {
      this.customList.splice(index, 1);
    },
    // 新增自定义租期
    addCustomItem: function addCustomItem() {
      if (!this.customForm.lease_days || !this.customForm.withhold_cycle) {
        this.$message({
          message: "总租期、租期间隔不能为空",
          type: "warning"
        });
        return;
      }

      var periodTotal = Math.ceil(parseInt(this.customForm.lease_days) / parseInt(this.customForm.withhold_cycle));
      var data = [];

      for (var index = 1; index <= periodTotal; index++) {
        var obj = {
          which_period: index,
          lease_days: 0,
          withhold_cycle: this.customForm.withhold_cycle,
          lease_price: this.customForm.lease_price ? this.customForm.lease_price : 0
        };

        if (index * parseInt(this.customForm.withhold_cycle) <= this.customForm.lease_days) {
          obj.lease_days = this.customForm.withhold_cycle;
        } else {
          obj.lease_days = parseInt(this.customForm.lease_days) % parseInt(this.customForm.withhold_cycle);
        }

        data.push(obj);
      }

      this.customList.push(data); // 清空表单

      this.customForm = {
        lease_days: "",
        withhold_cycle: "",
        lease_price: ""
      };
    },
    // 删除天租期
    removeDayItem: function removeDayItem(index) {
      this.dayList.splice(index, 1);
      this.submitForm.huabei_data.splice(index, 1);
    },
    // 新增天租期
    addDayItem: function addDayItem() {
      this.dayList.push({
        lease_time: "",
        lease_price: ""
      });
      this.submitForm.huabei_data.push({
        lease_term: "",
        rent_total: "",
        three_bear: "",
        three_fee: "",
        six_bear: "",
        six_fee: "",
        twelve_bear: "",
        twelve_fee: ""
      });
    },
    // 删除月租期
    removeMonthItem: function removeMonthItem(index) {
      this.monthList.splice(index, 1);
      this.submitForm.huabei_data.splice(index, 1);
    },
    // 新增月租期
    addMonthItem: function addMonthItem() {
      this.monthList.push({
        lease_time: null,
        lease_price: ""
      });
      this.submitForm.huabei_data.push({
        lease_term: "",
        rent_total: "",
        three_bear: "",
        three_fee: "",
        six_bear: "",
        six_fee: "",
        twelve_bear: "",
        twelve_fee: ""
      });
    },
    init: function init() {
      var _this3 = this;

      (0, _audit.getParameterList)().then(function (res) {
        _this3.startDateSelect = res.start_date || [];
        _this3.intervalList = res.withhold_cycle || [];
        _this3.monthSelect = res.lease_term_month || [];
      });
    },
    getInfo: function getInfo() {
      var _this4 = this;

      (0, _audit.getGoodsSetmealInfo)(this.editId).then(function (res) {
        for (var key in _this4.submitForm) {
          if (key === 'huabei_data') {
            if (res.is_huabei == 1) {
              _this4.submitForm[key] = res[key];
            }
          } else if (key === 'setmeal_id') {
            _this4.submitForm[key] = res.id;
          } else {
            _this4.submitForm[key] = res[key];
          }
        }

        _this4.rentType = res.pact_type;

        if (res.lease_set == 1) {
          _this4.monthList = res.spec;

          if (_this4.submitForm.is_huabei == 2) {
            for (var index in _this4.monthList) {
              var _this4$monthList$inde = _this4.monthList[index],
                  lease_time = _this4$monthList$inde.lease_time,
                  lease_price = _this4$monthList$inde.lease_price;
              var rentTotal = (parseFloat(lease_price) * lease_time).toFixed(2);

              if (index == 0) {
                _this4.submitForm.huabei_data[index].lease_term = lease_time;
                _this4.submitForm.huabei_data[index].rent_total = rentTotal;
              } else {
                _this4.submitForm.huabei_data.push({
                  lease_term: lease_time,
                  rent_total: rentTotal,
                  three_bear: "",
                  three_fee: "",
                  six_bear: "",
                  six_fee: "",
                  twelve_bear: "",
                  twelve_fee: ""
                });
              }
            }
          }
        } else if (res.lease_set == 2) {
          _this4.dayList = res.spec;

          if (_this4.submitForm.is_huabei == 2) {
            for (var _index2 in _this4.dayList) {
              var _this4$dayList$_index = _this4.dayList[_index2],
                  _lease_time2 = _this4$dayList$_index.lease_time,
                  _lease_price2 = _this4$dayList$_index.lease_price;

              var _rentTotal2 = (parseFloat(_lease_price2) * _lease_time2).toFixed(2);

              if (_index2 == 0) {
                _this4.submitForm.huabei_data[_index2].lease_term = _lease_time2;
                _this4.submitForm.huabei_data[_index2].rent_total = _rentTotal2;
              } else {
                _this4.submitForm.huabei_data.push({
                  lease_term: _lease_time2,
                  rent_total: _rentTotal2,
                  three_bear: "",
                  three_fee: "",
                  six_bear: "",
                  six_fee: "",
                  twelve_bear: "",
                  twelve_fee: ""
                });
              }
            }
          }
        } else {
          _this4.customList = res.spec;
        }
      });
    }
  },
  mounted: function mounted() {
    this.init();
    this.getInfo();
  }
};
exports.default = _default;