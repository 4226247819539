var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "add_dialog",
      attrs: {
        width: "600px",
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-width": "90px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "轮播图:", prop: "banner_img" } },
            [
              _c("crop-upload", {
                attrs: {
                  accept: ".jpg,.png",
                  "init-val": _vm.form.banner_img,
                  "file-size": "1M",
                  "wrapper-width": "100%",
                  "wrapper-height": "100px",
                  "img-width": 1920,
                  "img-height": 430,
                  tip: "图片格式JPG、PNG等格式，图片大小不可超过1M，",
                  "sub-tip": "尺寸要求：宽度1920像素，高度430像素。"
                },
                on: {
                  setVal: function(val) {
                    return _vm.setUrl(val)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "链接类型:" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.jump_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "jump_type", $$v)
                    },
                    expression: "form.jump_type"
                  }
                },
                [_vm._v("商品")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.form.jump_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "jump_type", $$v)
                    },
                    expression: "form.jump_type"
                  }
                },
                [_vm._v("页面")]
              )
            ],
            1
          ),
          _vm.form.jump_type == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "页面外链:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请输入带有http://或https://的网址链接"
                    },
                    model: {
                      value: _vm.form.link_url,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "link_url", $$v)
                      },
                      expression: "form.link_url"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.form.jump_type == 1
            ? _c("el-form-item", { attrs: { label: "产品链接:" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex align-center justify-between link_wrapper"
                  },
                  [
                    _c("p", { staticClass: "flex-1 text-ellipsis" }, [
                      _vm._v(_vm._s(_vm.urlName))
                    ]),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        nativeOn: {
                          click: function($event) {
                            return _vm.openSelect($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.form.banner_url && _vm.urlName
                              ? "更改"
                              : "添加链接"
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _c("goods-dialog", {
        ref: "goodsDialog",
        attrs: {
          "show-cate": false,
          "append-to-body": true,
          "show-checked-tool": true
        },
        on: { setSelect: _vm.setSelect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }