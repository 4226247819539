"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.removeToken = removeToken;
exports.getName = getName;
exports.setName = setName;
exports.removeName = removeName;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = "xxz_token";
var NameKey = "xxz_name";

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}

function getName() {
  return _jsCookie.default.get(NameKey);
}

function setName(name) {
  return _jsCookie.default.set(NameKey, name);
}

function removeName() {
  return _jsCookie.default.remove(NameKey);
}