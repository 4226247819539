"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

var _goodsAdd = _interopRequireDefault(require("./goodsAdd"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsAdd: _goodsAdd.default,
    cropUpload: _cropUpload.default
  },
  props: ['goodsNum', 'levelName'],
  data: function data() {
    return {
      background_image: '',
      formData: {
        main_title: '',
        background_image: '',
        goods: []
      }
    };
  },
  watch: {
    formData: function formData(newName, oldName) {
      if (typeof newName.background_image == 'string') {
        this.background_image = newName.background_image;
      }
    }
  },
  methods: {
    setGoods: function setGoods(list) {
      this.formData.goods = list;
    },
    save: function save() {
      if (!this.formData.main_title) {
        this.$message({
          message: "主标题不能为空",
          type: "error"
        });
        return false;
      }

      var goodsidStr = '';

      if (this.formData.goods.length > 0) {
        goodsidStr += this.formData.goods.map(function (k) {
          return k.goods_id;
        }).join(',');
      }

      return {
        background_image: this.background_image,
        level: this.levelName,
        main_title: this.formData.main_title,
        goodsidStr: goodsidStr
      };
    }
  }
};
exports.default = _default;