"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "AppMain",
  computed: {
    key: function key() {
      return this.$route.fullPath;
    },
    scrollHeight: function scrollHeight() {
      return this.$refs.appMain.scrollHeight;
    },
    clientHeight: function clientHeight() {
      return this.$refs.appMain.clientHeight;
    }
  },
  data: function data() {
    return {
      fixFooter: false,
      noPaddingList: ['auditGoods', 'goodsBaseEdit', 'goodsMealEdit', 'goodsMealInfo', 'orderList', 'billList', 'reconciliationWithdrawal'],
      noShadowList: ['auditGoods', 'goodsList', 'billList', 'reconciliationWithdrawal', 'reconciliationFlow', 'financeWithdrawal', 'auditSupplier', 'financingDeclaration']
    };
  },
  watch: {
    $route: function $route(val) {
      this.fixFooter = false;
    }
  },
  mounted: function mounted() {// this.fixFooter = this.scrollHeight > this.clientHeight;
  },
  methods: {
    handleScroll: function handleScroll(e) {
      var scrollBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;

      if (scrollBottom < 80) {
        this.fixFooter = false;
      } else {
        this.fixFooter = true;
      }
    },
    scrollTo: function scrollTo() {
      var pos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      if (pos == 'bottom') {
        pos = this.$refs.appMain.scrollHeight;
      }

      this.$refs.appMain.scrollTo(0, pos);
    }
  }
};
exports.default = _default;