var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("\n            平台数据列表\n        ")
            ],
            1
          ),
          _vm.$hasMethod("#add")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.openAddDialog()
                    }
                  }
                },
                [_vm._v("添加账号")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length > -1,
              expression: "tableData.length > -1"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "selection",
              width: "55",
              selectable: function(row, index) {
                return row.rules !== "*"
              }
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", label: "id", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "platform_id", label: "平台ID" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "remark", label: "平台备注" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "include_platform",
              label: "白名单"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formatPlatform(scope.row.include_platform)) +
                        "\n            "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "exclude_platform",
              label: "黑名单"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formatPlatform(scope.row.exclude_platform)) +
                        "\n            "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.openAddDialog(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("add-dialog", {
        ref: "addDialog",
        attrs: {
          "platform-id": _vm.platformId,
          "platform-white-list": _vm.platformWhiteList,
          "platform-black-list": _vm.platformBlackList,
          "edit-item": _vm.platformStatisList
        },
        on: { getList: _vm.getList }
      }),
      _c("demoPage", { ref: "sb" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }