"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _audit = require("@/api/audit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      form: {
        reason: "",
        other: ""
      },
      rules: {
        reason: [{
          required: true,
          message: '请选择原因',
          trigger: 'change'
        }]
      },
      dialogVisible: false,
      goodsId: null,
      goodsList: [],
      listType: 1,
      dialogType: 1 // 1：拒绝审核，2：批量通过审核，3：批量拒绝审核

    };
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          if (_this.dialogType == 1) {
            //拒绝审核"
            var params = {
              goods_id: _this.goodsId,
              check_reason: ''
            };

            if (_this.form.reason == 3) {
              params.check_reason = _this.form.other;
            } else {
              params.check_reason = _this.form.reason;
            }

            if (_this.listType == 1) {
              (0, _audit.goodsCheckRefuse)(params).then(function (res) {
                _this.dialogVisible = false;

                _this.$emit('getList', true);
              });
            } else {
              (0, _audit.goodsShelfCheckRefuse)(params).then(function (res) {
                _this.dialogVisible = false;

                _this.$emit('getList', true);
              });
            }
          } else if (_this.dialogType == 2) {
            //批量通过审核"
            var ids = _this.goodsList.map(function (k) {
              return k.id;
            }).join(',');

            if (_this.listType == 1) {
              (0, _audit.goodsCheckPass)(ids).then(function (res) {
                _this.dialogVisible = false;

                _this.$emit('getList', true);
              });
            } else {
              (0, _audit.goodsShelfCheckPass)(ids).then(function (res) {
                _this.dialogVisible = false;

                _this.$emit('getList', true);
              });
            }
          } else {
            //批量拒绝审核"
            var _params = {
              goods_id: _this.goodsList.map(function (k) {
                return k.id;
              }).join(','),
              check_reason: ''
            };

            if (_this.form.reason == 3) {
              _params.check_reason = _this.form.other;
            } else {
              _params.check_reason = _this.form.reason;
            }

            if (_this.listType == 1) {
              (0, _audit.goodsCheckRefuse)(_params).then(function (res) {
                _this.dialogVisible = false;

                _this.$emit('getList', true);
              });
            } else {
              (0, _audit.goodsShelfCheckRefuse)(_params).then(function (res) {
                _this.dialogVisible = false;

                _this.$emit('getList', true);
              });
            }
          }
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (!val) {
        this.goodsId = null;
        this.goodsList = [];
      } else {
        this.listType = this.$route.name === 'auditOffGoods' ? 2 : 1;
      }
    }
  },
  computed: {
    dialogTitle: function dialogTitle() {
      if (this.dialogType == 1) {
        return "拒绝审核";
      } else if (this.dialogType == 2) {
        return "批量通过审核";
      } else {
        return "批量拒绝审核";
      }
    }
  }
};
exports.default = _default;