var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goods_detail" },
    [
      _c("el-main", { staticStyle: { padding: "0" } }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "flex top_center width mar0" }, [
            _c(
              "div",
              { staticClass: "product_img" },
              [
                _c("el-image", {
                  staticClass: "curImg",
                  attrs: {
                    src: _vm.currentImg,
                    "preview-src-list": _vm.imageList
                  }
                }),
                _c(
                  "p",
                  [
                    _vm._l(_vm.imageList, function(item, index) {
                      return [
                        _c("img", {
                          key: index,
                          class: { selected: _vm.currentImg === item },
                          staticStyle: { width: "4rem", height: "4rem" },
                          attrs: { src: item, alt: "缩略图" },
                          on: {
                            click: function($event) {
                              return _vm.showImg(item)
                            }
                          }
                        })
                      ]
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c("div", { staticClass: "info_wrapper" }, [
              _c("h3", [_vm._v(_vm._s(_vm.goodsInfo.name))]),
              _c("h6", [_vm._v(_vm._s(_vm.goodsInfo.sku_name))]),
              _c("p", { staticClass: "gray_bg" }, [
                _vm._v("\n            月租金: "),
                _c("span", [
                  _vm._v("￥" + _vm._s(_vm.goodsInfo.unit_price) + "/月")
                ]),
                _vm._v(" \n            总租金: "),
                _c("span", [_vm._v("￥" + _vm._s(_vm.totalRent))])
              ]),
              _c("div", [
                _c("div", { staticClass: "mealDiv" }, [
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("套餐：")]),
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.goodsInfo.sku_name))
                    ])
                  ]),
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("租期：")]),
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.goodsInfo.month) + "个月")
                    ])
                  ]),
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("数量：")]),
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.goodsInfo.number))
                    ])
                  ])
                ]),
                _c("p", [
                  _c("span", { staticClass: "label" }, [_vm._v("押金：")]),
                  _vm._v("￥" + _vm._s(_vm.totalRent))
                ]),
                _c("p", [
                  _c("span", { staticClass: "label" }, [_vm._v("配送方式：")]),
                  _vm._v(_vm._s("寄出包邮") + "-" + _vm._s("归还自付"))
                ]),
                _c("p", [
                  _c("span", { staticClass: "label" }, [_vm._v("是否买断：")]),
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.goodsInfo.is_buy_out == 0 ? "不可买断" : "可买断"
                      ) +
                      "\n            "
                  )
                ]),
                _c("p", [
                  _c("span", { staticClass: "label" }, [_vm._v("买断金额：")]),
                  _vm._v(_vm._s(_vm.buyoutRent))
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "bottom width mar0" }, [
          _c("p", { staticClass: "blue-font text-center detail_label" }, [
            _c("span", [_vm._v("商品详情")])
          ]),
          _c("p", {
            staticClass: "goodsContent",
            domProps: { innerHTML: _vm._s(_vm.goodsInfo.info) }
          })
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }