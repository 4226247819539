"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _system = require("@/api/system");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _add = _interopRequireDefault(require("./add"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      tableData: [],
      editItem: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _system.getPayMentList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
      });
    },
    // 删除
    removeItem: function removeItem(item) {
      var _this2 = this;

      var tip = "<span class='red-font'>\u5F53\u524D\u901A\u9053 ".concat(item.key, " - ").concat(item.title, "</span><br><span>\u60A8\u786E\u5B9A\u8981\u5220\u9664\u901A\u9053\u5417\uFF1F</span>");
      this.$confirm(tip, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(function () {
        (0, _system.delPayMent)(item.id).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog() {
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.editItem = val;
      this.$refs.addDialog.dialogVisible = true;
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    },
    // 启用/禁用
    changeStatus: function changeStatus(item) {
      var _this3 = this;

      var tip = "";
      var status = item.status;

      if (status == 1) {
        tip = "<span class='red-font'>\u5F53\u524D\u901A\u9053 ".concat(item.key, " - ").concat(item.title, "</span><br><span>\u60A8\u786E\u5B9A\u8981\u7981\u7528\u901A\u9053\u5417\uFF1F</span>");
      } else {
        tip = "<span>您确定要启用通道吗？</span>";
      }

      this.$confirm(tip, status == 1 ? '禁用通道' : '启用通道', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(function () {
        item.status = status == 1 ? 0 : 1;
        (0, _system.editPayMent)({
          form_data: item
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    // 修改排序
    editSort: function editSort(item, sort) {
      var _this4 = this;

      console.log('editSort', item, sort);
      (0, _system.editPayMent)({
        form_data: item
      }).then(function (res) {
        _this4.getList(true);
      });
    }
  }
};
exports.default = _default;