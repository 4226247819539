"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderList = orderList;
exports.getLease = getLease;
exports.getOrderRiskControl = getOrderRiskControl;
exports.setAddress = setAddress;
exports.getRegion = getRegion;
exports.setDeviceNumber = setDeviceNumber;
exports.setOrderRemark = setOrderRemark;
exports.getOrderRemark = getOrderRemark;
exports.getLeaseFlow = getLeaseFlow;
exports.getLogisticAll = getLogisticAll;
exports.deliverGoods = deliverGoods;
exports.confirmGoods = confirmGoods;
exports.getLeaseInfo = getLeaseInfo;
exports.getLogisticInfo = getLogisticInfo;
exports.setOrderVoucher = setOrderVoucher;
exports.getOrderStatus = getOrderStatus;
exports.orderListExport = orderListExport;
exports.orderHuaBeiList = orderHuaBeiList;
exports.orderHuaBeiExport = orderHuaBeiExport;
exports.orderBuyOutList = orderBuyOutList;
exports.orderBuyOutListExport = orderBuyOutListExport;
exports.orderSignedList = orderSignedList;
exports.orderSignedListExport = orderSignedListExport;
exports.orderFinish = orderFinish;
exports.orderReturnAgree = orderReturnAgree;
exports.orderReturnRefuse = orderReturnRefuse;
exports.orderComfirmReturn = orderComfirmReturn;
exports.orderReturnFinish = orderReturnFinish;
exports.orderClose = orderClose;
exports.orderRenewalList = orderRenewalList;
exports.editOrderExpress = editOrderExpress;
exports.orderSuccessList = orderSuccessList;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

/**
 * 订单管理
 */
// 订单列表
function orderList(params) {
  return _fetch.default.post("/Admin/Order/orderList", params);
} // 查看租期


function getLease(orderId) {
  return _fetch.default.post("/Admin/Order/getLease", {
    orderId: orderId
  });
} // 查看风控


function getOrderRiskControl(params) {
  return _fetch.default.post("/Admin/Order/getOrderRisk", params, true);
} // 设置修改收货地址


function setAddress(params) {
  return _fetch.default.post("/Admin/Order/setAddress", params, true);
} // 获取所有区域


function getRegion() {
  return _fetch.default.post("/Admin/Order/getRegion");
} // 添加设备编号


function setDeviceNumber(params) {
  return _fetch.default.post("/Admin/Order/setDeviceNumber", params, true);
} // 添加客服备注


function setOrderRemark(params) {
  return _fetch.default.post("/Admin/Order/setOrderRemark", params, true);
} // 获取客服备注记录


function getOrderRemark(params) {
  return _fetch.default.post("/Admin/Order/getOrderRemark", params);
} // 查看流水记录


function getLeaseFlow(orderNo) {
  return _fetch.default.post("/Admin/Order/getLeaseFlow", {
    orderNo: orderNo
  });
} // 获取物流公司列表


function getLogisticAll() {
  return _fetch.default.post("/Admin/Order/getLogisticAll");
} // 发货


function deliverGoods(params) {
  return _fetch.default.post("/Admin/Order/deliverGoods", params, true);
} // 确认收货


function confirmGoods(params) {
  return _fetch.default.post("/Admin/Order/confirmGoods", params, true);
} // 获取账单详情


function getLeaseInfo(orderId) {
  return _fetch.default.post("/Admin/Order/getLeaseInfo", {
    orderId: orderId
  });
} // 获取物流信息


function getLogisticInfo(params) {
  return _fetch.default.post("/Admin/Order/getLogisticInfo", params);
} // 补充发货确认收货凭证


function setOrderVoucher(params) {
  return _fetch.default.post("/Admin/Order/setOrderVoucher", params, true);
} // 获取订单状态列表


function getOrderStatus() {
  return _fetch.default.post("/Admin/Order/getOrderStatus");
} // 订单列表导出


function orderListExport(params) {
  return _fetch.default.export("/Admin/Order/orderListExport", params, "订单列表");
} // 花呗订单列表


function orderHuaBeiList(params) {
  return _fetch.default.post("/Admin/Order/orderHuaBeiList", params);
} // 花呗订单列表-导出


function orderHuaBeiExport(params) {
  return _fetch.default.export("/Admin/Order/orderHuaBeiExport", params, "花呗订单列表");
} // 买断订单列表


function orderBuyOutList(params) {
  return _fetch.default.post("/Admin/Order/orderBuyOutList", params);
} // 买断订单列表-导出


function orderBuyOutListExport(params) {
  return _fetch.default.export("/Admin/Order/orderBuyOutListExport", params, "买断订单列表");
} // 签约待付款订单列表


function orderSignedList(params) {
  return _fetch.default.post("/Admin/Order/orderSignedList", params);
} // 签约待支付订单列表-导出


function orderSignedListExport(params) {
  return _fetch.default.export("/Admin/Order/orderSignedListExport", params, "签约待支付订单列表");
} // 交易完成


function orderFinish(orderId) {
  return _fetch.default.post("/Admin/Order/orderFinish", {
    orderId: orderId
  }, true);
} // 订单同意归还


function orderReturnAgree(orderId) {
  return _fetch.default.post("/Admin/Order/orderReturnAgree", {
    orderId: orderId
  }, true);
} // 订单归还审核拒绝


function orderReturnRefuse(params) {
  return _fetch.default.post("/Admin/Order/orderReturnRefuse", params, true);
} // 订单确认归还


function orderComfirmReturn(orderId) {
  return _fetch.default.post("/Admin/Order/orderComfirmReturn", {
    orderId: orderId
  }, true);
} // 订单归还完成


function orderReturnFinish(orderId) {
  return _fetch.default.post("/Admin/Order/orderReturnFinish", {
    orderId: orderId
  }, true);
} // 订单关闭


function orderClose(orderId) {
  return _fetch.default.post("/Admin/Order/orderClose", {
    orderId: orderId
  }, true);
} // 续租订单列表


function orderRenewalList(params) {
  return _fetch.default.post("/Admin/Order/orderRenewalList", params);
} // 修改订单物流信息


function editOrderExpress(params) {
  return _fetch.default.post("/Admin/Order/editOrderExpress", params, true);
} // 风控列表


function orderSuccessList(params) {
  return _fetch.default.post("/Admin/Order/orderSuccessList", params);
}