var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-page" },
    [
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("\n            全局配置\n        ")
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "add-btn",
              attrs: { size: "small", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.addChildren()
                }
              }
            },
            [_vm._v("添加配置")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.tableData,
            "row-key": "id",
            border: "",
            "default-expand-all": "",
            "tree-props": { children: "children" },
            height: "60vh"
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "left", label: "", prop: "", width: "120" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "key",
              prop: "key",
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "table-key" }, [
                      _c("div", { staticClass: "table-key-level" }, [
                        scope.row.level != 0
                          ? _c(
                              "span",
                              [
                                _vm._l(scope.row.level - 1, function(index) {
                                  return _c("span", [_vm._v(_vm._s("　├─"))])
                                }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s("　└─") +
                                    "\n                        "
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "table-key-input" },
                        [
                          _c("el-input", {
                            staticClass: "text-left",
                            attrs: {
                              id: scope.row.id,
                              placeholder: "请输入key"
                            },
                            model: {
                              value: scope.row.key,
                              callback: function($$v) {
                                _vm.$set(scope.row, "key", $$v)
                              },
                              expression: "scope.row.key"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "title",
              prop: "title",
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      staticClass: "text-left",
                      attrs: { placeholder: "请输入title" },
                      model: {
                        value: scope.row.title,
                        callback: function($$v) {
                          _vm.$set(scope.row, "title", $$v)
                        },
                        expression: "scope.row.title"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "备注", prop: "remark" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      staticClass: "text-left",
                      attrs: { placeholder: "请输入备注" },
                      model: {
                        value: scope.row.remark,
                        callback: function($$v) {
                          _vm.$set(scope.row, "remark", $$v)
                        },
                        expression: "scope.row.remark"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "排序",
              prop: "sort",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      on: {
                        change: function($event) {
                          return _vm.sortConfig()
                        }
                      },
                      model: {
                        value: scope.row.sort,
                        callback: function($$v) {
                          _vm.$set(scope.row, "sort", $$v)
                        },
                        expression: "scope.row.sort"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "默认值", prop: "default" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.children.length == 0
                      ? _c("el-input", {
                          staticClass: "text-left",
                          attrs: { placeholder: "默认值" },
                          model: {
                            value: scope.row.default,
                            callback: function($$v) {
                              _vm.$set(scope.row, "default", $$v)
                            },
                            expression: "scope.row.default"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "锁定",
              prop: "lock",
              width: "70"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.children.length == 0
                      ? _c(
                          "div",
                          {
                            staticClass: "pointer",
                            on: {
                              click: function($event) {
                                return _vm.switchLock(scope.row)
                              }
                            }
                          },
                          [
                            scope.row.lock
                              ? _c("el-tag", [_vm._v("锁定")])
                              : _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("正常")
                                ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "status",
              width: "70"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function($event) {
                            return _vm.switchStatus(scope.row)
                          }
                        }
                      },
                      [
                        scope.row.status
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("显示")
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("隐藏")
                            ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "180",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "success" },
                        on: {
                          click: function($event) {
                            return _vm.addChildren(scope.row)
                          }
                        }
                      },
                      [_vm._v("添加")]
                    ),
                    scope.row.level > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "warning" },
                            on: {
                              click: function($event) {
                                return _vm.copyChildren(scope.row)
                              }
                            }
                          },
                          [_vm._v("复制")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.delChildren(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-footer-btns" },
        [
          [
            _c(
              "el-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [_vm._v("返回")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.submit()
                  }
                }
              },
              [_vm._v("提交")]
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }