"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserList = getUserList;
exports.getOrderList = getOrderList;
exports.getGoodsList = getGoodsList;
exports.getPromiseList = getPromiseList;
exports.getRentalList = getRentalList;
exports.getLogisticsList = getLogisticsList;
exports.getFinancList = getFinancList;
exports.financDetails = financDetails;
exports.toChain = toChain;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

// 订单用户
function getUserList(params) {
  return _fetch.default.post("/Antchain/zlb/orderUser", params);
} // 订单


function getOrderList(params) {
  return _fetch.default.post("/Antchain/zlb/orderList", params);
} // 订单商品


function getGoodsList(params) {
  return _fetch.default.post("/Antchain/zlb/orderGoods", params);
} // 订单承诺


function getPromiseList(params) {
  return _fetch.default.post("/Antchain/zlb/orderPromise", params);
} // 订单履约


function getRentalList(params) {
  return _fetch.default.post("/Antchain/zlb/orderRental", params);
} // 订单物流


function getLogisticsList(params) {
  return _fetch.default.post("/Antchain/zlb/orderLogistics", params);
} // 融资查询


function getFinancList(params) {
  return _fetch.default.post("/Antchain/zlb/financingList", params);
} // 融资查询详情


function financDetails(params) {
  return _fetch.default.post("/Antchain/zlb/orderInfo", params);
} // 租赁宝添加上链任务


function toChain(params) {
  return _fetch.default.post("/Antchain/zlb/toTask", params);
}