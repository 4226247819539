var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goods_add" },
    [
      _vm._l(_vm.goodsList, function(item, index) {
        return _c("goods-link", {
          key: item.goods_id,
          attrs: { "goods-data": item, "show-tool": true },
          on: {
            handleDelete: function($event) {
              return _vm.deleteGoods(index)
            },
            upSort: function($event) {
              return _vm.upSort(index)
            },
            downSort: function($event) {
              return _vm.downSort(index)
            }
          }
        })
      }),
      _vm.goodsList.length < _vm.maxNum
        ? _c("div", { staticClass: "add-div", on: { click: _vm.openSelect } }, [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v(
              "添加商品(" +
                _vm._s(_vm.goodsList.length) +
                "/" +
                _vm._s(_vm.maxNum) +
                ")\n  "
            )
          ])
        : _vm._e(),
      _c("goods-dialog", {
        ref: "goodsDialog",
        attrs: {
          multiple: true,
          maxNum: _vm.maxNum,
          "show-checked-tool": true
        },
        on: { setSelect: _vm.setSelect }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }