var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "拒绝退款", visible: _vm.dialogVisible, width: "500px" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("您确定要拒绝退款吗？")]),
      _c(
        "el-form",
        { attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原因：", "label-width": _vm.formLabelWidth } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeSelect },
                  model: {
                    value: _vm.form.refuse_reason,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "refuse_reason", $$v)
                    },
                    expression: "form.refuse_reason"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { label: "订单商品已发货", value: 1 }
                  }),
                  _c("el-option", { attrs: { label: "其他原因", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _vm.isShowOther
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "其他原因：",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autocomplete: "off",
                      rows: 2,
                      placeholder: "请输入拒绝退款原因！"
                    },
                    model: {
                      value: _vm.form.other,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "other", $$v)
                      },
                      expression: "form.other"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit_refuse } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }