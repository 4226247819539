"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["id"],
  data: function data() {
    return {
      form: {
        code1: "",
        code2: ""
      },
      rules: {
        code1: [{
          required: true,
          message: "密码不能为空",
          trigger: "blur"
        }, {
          min: 6,
          message: "密码长度不对",
          trigger: "blur"
        }],
        code2: [{
          required: true,
          message: "密码不能为空",
          trigger: "blur"
        }, {
          min: 6,
          message: "密码长度不对",
          trigger: "blur"
        }, {
          validator: this.validateRepeatPassword,
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    validateRepeatPassword: function validateRepeatPassword(rule, value, callback) {
      if (value !== this.form.code1) {
        callback(new Error("两次密码不一样"));
      } else {
        callback();
      }
    },
    onSubmit: function onSubmit() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var params = {
            member_id: _this.id,
            code1: _this.$md5(_this.form.code1),
            code2: _this.$md5(_this.form.code2)
          };
          console.log("params", params);
          (0, _user.changePwdForPay)(params).then(function (res) {
            console.log("支付密码修改成功", res);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;