"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _audit = require("@/api/audit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      form: {
        reason: "",
        other: ""
      },
      rules: {
        reason: [{
          required: true,
          message: '请选择原因',
          trigger: 'change'
        }]
      },
      dialogVisible: false,
      goodsId: null
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = {
            goods_id: _this.goodsId,
            check_reason: ''
          };

          if (_this.form.reason == 3) {
            params.check_reason = _this.form.other;
          } else {
            params.check_reason = _this.form.reason;
          }

          (0, _audit.itemDownShelf)(params).then(function (res) {
            _this.dialogVisible = false;

            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;

      if (val) {
        this.$nextTick(function () {
          _this2.$refs['ruleForm'].resetFields();
        });
      }
    }
  }
};
exports.default = _default;