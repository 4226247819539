"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.copyGoodsType = copyGoodsType;
exports.copyGoods = copyGoods;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

//复制商品栏目
function copyGoodsType(params) {
  return _fetch.default.post("/Admin/Goods/copyGoodsType", params, true);
} // 平台文章列表


function copyGoods(params) {
  return _fetch.default.post("/Admin/Goods/copyGoods", params, true);
}