"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    dialogTitle: {
      type: String
    },
    batchPassTitle: {
      type: String
    },
    batchPassTotal: {
      type: Number
    },
    batchPassVisible: {
      type: Boolean,
      default: false
    },
    batchPassData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    refuseList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    batchRefuseForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isShowRefuseFrom: {
      type: Boolean,
      default: false
    },
    isShowRateFrom: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    sourceList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    sourceForm: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    closeDialog: function closeDialog(param) {
      this.$emit("closeDialog", param);
    },
    submitPass: function submitPass(param) {
      var _this = this;

      // console.log('param',param, this.isShowRateFrom)
      if (this.isShowRateFrom) {
        // console.log('param',this.sourceForm)
        var flag = false;
        this.sourceForm.forEach(function (item) {
          for (var key in item) {
            if (key == "source_id" && !item[key]) {
              _this.$message.error("请选择订单来源");

              flag = false;
              return false;
            } else if (key != "source_id" && key != "is_delete") {
              if (!item[key]) {
                _this.$message.error("请确保已设置费率");

                flag = false;
                return false;
              } else if (item[key]) {
                var isNum = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;

                if (!isNum.test(item[key])) {
                  _this.$message.error("费率为正数且最多两位小数");

                  flag = false;
                  return false;
                } else {
                  flag = true;
                  return;
                }
              }
            } else {
              flag = true;
            }
          }
        });

        if (this.sourceForm.length > 1) {
          var ids = this.sourceForm.map(function (item) {
            return item.source_id;
          });
          var idsSet = new Set(ids);

          if (idsSet.size != ids.length) {
            this.$message.error("订单来源不能重复");
            flag = false;
            return false;
          } else {
            flag = true;
          }
        }

        if (flag) {
          this.$emit("submitPass", this.sourceForm);
        }
      } else {
        this.$emit("submitPass", param);
      }
    },
    changeOption: function changeOption(param) {
      this.$emit("changeOption", param);
    },
    addSource: function addSource(param) {
      this.$emit("addSource");
    },
    deleteFrom: function deleteFrom(item, index) {
      this.$emit("deleteFrom", item, index);
    },
    changeSourceId: function changeSourceId(val) {
      this.$emit("changeSourceId", val);
    }
  }
};
exports.default = _default2;