var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-page" }, [
    _c("div", { staticClass: "base_info" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "info_list" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tableData.length > -1,
                  expression: "tableData.length > -1"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", prop: "ip", label: "ip" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "limit_sec",
                  label: "限制时长(秒)"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "count", label: "访问次数" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "is_block", label: "是否封禁" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.is_block == 0
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("正常")
                            ])
                          : _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("封禁")
                            ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "block_sec",
                  label: "封禁时长(秒)"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm.$hasMethod("#edit")
      ? _c(
          "div",
          { staticClass: "page-footer-btns" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.clearBlockIp }
              },
              [_vm._v("清除封禁")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "edit-header" }, [
      _c("span", [_vm._v("IP访问列表")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }