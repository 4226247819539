var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-title-page goods_list" },
    [
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "工单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入工单号", clearable: "" },
                    model: {
                      value: _vm.searchForm.work_order,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "work_order", $$v)
                      },
                      expression: "searchForm.work_order"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "投诉类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部", clearable: "" },
                      model: {
                        value: _vm.searchForm.suggest_type,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "suggest_type", $$v)
                        },
                        expression: "searchForm.suggest_type"
                      }
                    },
                    _vm._l(_vm.typeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.type_name, value: item.type_name }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "工单状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部", clearable: "" },
                      model: {
                        value: _vm.searchForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "status", $$v)
                        },
                        expression: "searchForm.status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "待处理", value: "1" }
                      }),
                      _c("el-option", {
                        attrs: { label: "处理中", value: "2" }
                      }),
                      _c("el-option", {
                        attrs: { label: "已处理", value: "3" }
                      }),
                      _c("el-option", {
                        attrs: { label: "已撤销", value: "4" }
                      }),
                      _c("el-option", {
                        attrs: { label: "已超时", value: "5" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间" } },
                [_c("my-date-picker", { ref: "myDatePicker" })],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "flex-1 nav_tabs",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: {
              label: "全部工单(" + _vm.count.grade_total + ")",
              name: "0"
            }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "普通工单(" + _vm.count.ordinary_grade_total + ")",
              name: "1"
            }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "紧急工单(" + _vm.count.urgent_grade_total + ")",
              name: "2"
            }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "严重工单(" + _vm.count.serious_grade_total + ")",
              name: "3"
            }
          })
        ],
        1
      ),
      _c("el-divider", { staticClass: "middle_divider" }),
      _c(
        "div",
        { staticClass: "child_page" },
        [
          _c("div", { staticClass: "flex align-center table-top-btns" }, [
            _c(
              "h5",
              { staticClass: "flex align-center" },
              [
                _c("svg-icon", {
                  staticStyle: { "margin-right": "15px" },
                  attrs: { "icon-class": "list", size: 15 }
                }),
                _vm._v("数据列表\n        ")
              ],
              1
            )
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tableData.length > -1,
                  expression: "tableData.length > -1"
                }
              ],
              attrs: { data: _vm.tableData, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "left",
                  align: "center",
                  prop: "id",
                  label: "ID",
                  width: "60"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "供应商信息" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("p", [
                          !scope.row.shopname
                            ? _c(
                                "span",
                                { staticStyle: { margin: "0 5px 0 20px" } },
                                [_vm._v("默认")]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass: "blue-font",
                              on: {
                                click: function($event) {
                                  _vm.$func.openBlank(
                                    _vm.$router.resolve({
                                      path: "/supplier/view/" + scope.row.shopid
                                    })
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(_vm._s(scope.row.shopname)),
                              _c("p", [_vm._v(_vm._s(scope.row.shopmobile))])
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "work_order",
                  label: "工单号",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "user_mobile",
                  label: "联系手机",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "suggest_type",
                  label: "投诉类型",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "suggest_cate",
                  label: "投诉类别",
                  width: "110"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("p", { staticClass: "text-ellipsis" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                scope.row.suggest_cate == 1
                                  ? "商品名称"
                                  : scope.row.suggest_cate == 2
                                  ? "商家名称"
                                  : "订单编号"
                              ) +
                              "\n            "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "suggest_title",
                  label: "类别内容"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "工单等级", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.grade
                          ? _c("span", [
                              _c("span", {
                                staticClass: "before",
                                class:
                                  scope.row.grade == 1
                                    ? "primary"
                                    : scope.row.grade == 2
                                    ? "warning"
                                    : "danger"
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.grade == 1
                                      ? "普通"
                                      : scope.row.grade == 2
                                      ? "紧急"
                                      : "严重"
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "工单状态", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    scope.row.status == "3"
                                      ? "success"
                                      : scope.row.status == "1"
                                      ? ""
                                      : scope.row.status == "2"
                                      ? "warning"
                                      : "danger"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.status == "1"
                                        ? "待处理"
                                        : scope.row.status == "2"
                                        ? "处理中"
                                        : scope.row.status == "3"
                                        ? "已处理"
                                        : scope.row.status == "4"
                                        ? "已撤销"
                                        : "已超时"
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "create_time",
                  label: "创建时间",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "end_time",
                  label: "截止期限",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.check(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        ),
                        scope.row.status != "3" && _vm.$hasMethod("#intervene")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.intervene(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("介入")]
                            )
                          : _vm._e(),
                        _vm.$hasMethod("#note")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.remark(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("备注")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("custom-page", {
            ref: "customPage",
            attrs: { total: _vm.total },
            on: { getList: _vm.getList }
          })
        ],
        1
      ),
      _c("dispose", {
        ref: "disposes",
        attrs: { "suggest-id": _vm.suggestId }
      }),
      _c("note-records", {
        ref: "records",
        attrs: { "suggest-id": _vm.suggestId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }