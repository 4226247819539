"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _audit = require("@/api/audit");

var _pass = _interopRequireDefault(require("./pass"));

var _refuse = _interopRequireDefault(require("./refuse"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    passDialog: _pass.default,
    refuseDialog: _refuse.default
  },
  data: function data() {
    return {
      searchForm: {
        shopName: "",
        payeeName: "",
        payeeAccount: "",
        status: "",
        applyTime: "",
        arrivalTime: ""
      },
      tableData: [],
      total: 0,
      count: {},
      activeName: "all",
      selectedId: ""
    };
  },
  methods: {
    refuse: function refuse(id) {
      this.selectedId = id;
      this.$refs.refuseDialog.dialogVisible = true;
    },
    pass: function pass(id) {
      // this.selectedId = id;
      // this.$refs.passDialog.dialogVisible = true;
      var that = this;
      this.$confirm("确定同意提现审核吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _audit.merchantWithdrawalPass)({
          id: id
        }).then(function (res) {
          if (res) {
            that.getList(true);
          }
        });
      }).catch(function () {});
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 获取数据
    getList: function getList() {
      var _this = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      this.searchForm.status = this.activeName === "all" ? "" : this.activeName;
      (0, _audit.merchantWithdrawalExamineList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        var _res$list = res.list,
            data = _res$list.data,
            total = _res$list.total,
            count = res.count;
        _this.tableData = data || [];
        _this.total = total || 0;
        var obj = {};

        if (_this.activeName === "all") {
          count.forEach(function (element) {
            obj[element.status] = element.count || 0;
          });
          _this.count = obj;
        }
      });
    },
    // 查询
    search: function search() {
      var pickerVal1 = this.$refs.myDatePicker1.getVal();
      var pickerVal2 = this.$refs.myDatePicker2.getVal();

      if (pickerVal1 !== "error" || pickerVal2 !== "error") {
        this.searchForm.applyTime = pickerVal1;
        this.searchForm.arrivalTime = pickerVal2;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker1) {
        this.$refs.myDatePicker1.reset();
      }

      if (this.$refs.myDatePicker2) {
        this.$refs.myDatePicker2.reset();
      }

      this.searchForm = {
        shopName: "",
        payeeName: "",
        payeeAccount: "",
        status: "",
        applyTime: "",
        arrivalTime: ""
      };
      this.getList();
    },
    exportFile: function exportFile() {
      (0, _audit.merchantWithdrawalExamineListExport)(this.searchForm);
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;