var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category_page recommend_custom_page footer-page" },
    [
      _c(
        "div",
        { staticClass: "top_tabs" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.tabList, function(item) {
              return _c("el-tab-pane", {
                key: item.id,
                attrs: { label: item.main_title, name: item.id + "" }
              })
            }),
            1
          ),
          _vm.tabList.length > 0 && _vm.$hasMethod("#add")
            ? _c(
                "el-link",
                {
                  attrs: {
                    href: "/webConfig/show",
                    underline: false,
                    type: "primary"
                  }
                },
                [_vm._v("添加板块")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "flex tab_content" }, [
        _c(
          "div",
          { staticClass: "img_wrapper" },
          [
            _c(
              "el-image",
              {
                attrs: {
                  src: require("../../../assets/images/demo_custom.jpg")
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error"
                  },
                  [_c("i", { staticClass: "el-icon-picture-outline" })]
                )
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "config_wrapper" }, [
          !_vm.$hasMethod("#edit")
            ? _c("div", { staticClass: "mask" })
            : _vm._e(),
          _c("h5", [_vm._v(_vm._s(_vm.configTitle))]),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "div",
                { staticClass: "warn-box" },
                [
                  _c("svg-icon", {
                    staticStyle: { "margin-right": "17px" },
                    attrs: { "icon-class": "warn", size: 20 }
                  }),
                  _vm._m(0)
                ],
                1
              ),
              _c("div", { staticClass: "input_wrapper" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "label",
                      { staticClass: "flex align-center justify-center" },
                      [_vm._v("主标题")]
                    ),
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        placeholder: "请输入主标题",
                        maxlength: 5,
                        clearable: ""
                      },
                      model: {
                        value: _vm.submitForm.main_title,
                        callback: function($$v) {
                          _vm.$set(_vm.submitForm, "main_title", $$v)
                        },
                        expression: "submitForm.main_title"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "label",
                      { staticClass: "flex align-center justify-center" },
                      [_vm._v("副标题")]
                    ),
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        placeholder: "请输入副标题",
                        maxlength: 10,
                        clearable: ""
                      },
                      model: {
                        value: _vm.submitForm.sub_title,
                        callback: function($$v) {
                          _vm.$set(_vm.submitForm, "sub_title", $$v)
                        },
                        expression: "submitForm.sub_title"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("p", { staticClass: "title" }, [_vm._v("一级推荐位")]),
              _c("crop-upload", {
                staticStyle: { width: "360px", margin: "20px auto" },
                attrs: {
                  "show-type": "photo",
                  "init-val": _vm.submitForm.background_img,
                  "wrapper-width": "360px",
                  "wrapper-height": "115px",
                  "img-width": 474,
                  "img-height": 280,
                  "photo-width": 360,
                  "photo-height": 115
                },
                on: {
                  setVal: function(val) {
                    _vm.submitForm.background_img = val
                  }
                }
              }),
              _c("goods-form", {
                attrs: {
                  "form-data": _vm.goodsData,
                  "show-checked-tool": true,
                  "show-tool": _vm.$hasMethod("#edit")
                }
              }),
              _c("p", { staticClass: "title" }, [_vm._v("二级推荐位")]),
              _vm._l(_vm.dataList, function(item, index) {
                return [
                  _c("goods-link", {
                    key: index,
                    attrs: {
                      "goods-data": item,
                      "show-tool": _vm.$hasMethod("#edit")
                    },
                    on: {
                      handleDelete: function($event) {
                        return _vm.deleteGoods(index)
                      },
                      upSort: function($event) {
                        return _vm.upSort(index)
                      },
                      downSort: function($event) {
                        return _vm.downSort(index)
                      }
                    }
                  })
                ]
              }),
              _vm.dataList.length < 8 && _vm.$hasMethod("#edit")
                ? _c(
                    "div",
                    { staticClass: "add-div", on: { click: _vm.openSelect } },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(
                        "添加商品(" +
                          _vm._s(_vm.dataList.length) +
                          "/8)\n        "
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _vm.$hasMethod("#edit")
        ? _c(
            "div",
            { staticClass: "page-footer-btns" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.reset } },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        : _vm._e(),
      _c("goods-dialog", {
        ref: "goodsDialog",
        attrs: { multiple: true, maxNum: 8, "show-checked-tool": true },
        on: { setSelect: _vm.setSelect }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("商品图片要求PNG格式透明底图片；"),
      _c("br"),
      _vm._v("推荐位背景图尺寸474×280px；"),
      _c("br"),
      _vm._v("图片尺寸要求500×500px；")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }