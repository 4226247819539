var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "base_form",
          attrs: {
            model: _vm.submitForm,
            rules: _vm.rules,
            "label-width": "200px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品品类", prop: "category_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择商品品类" },
                  on: { change: _vm.categoryChange },
                  model: {
                    value: _vm.submitForm.category_id,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "category_id", $$v)
                    },
                    expression: "submitForm.category_id"
                  }
                },
                _vm._l(_vm.categoryList, function(item) {
                  return _c("el-option", {
                    key: item.category_id,
                    attrs: { label: item.name, value: item.category_id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称", prop: "goods_name" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "50",
                  "show-word-limit": "",
                  placeholder: "请输入商品名称"
                },
                model: {
                  value: _vm.submitForm.goods_name,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "goods_name", $$v)
                  },
                  expression: "submitForm.goods_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推广关键字" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "50",
                  "show-word-limit": "",
                  placeholder: "请输入推广关键字，以中文逗号隔开"
                },
                model: {
                  value: _vm.submitForm.spread_title,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "spread_title", $$v)
                  },
                  expression: "submitForm.spread_title"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "商品参数" } }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap params_wrap" },
              [
                _vm._l(_vm.goodsParams, function(param, index) {
                  return [
                    _c(
                      "div",
                      { key: index },
                      [
                        _c("label", [
                          param.must_is
                            ? _c(
                                "i",
                                {
                                  staticClass: "required-icon",
                                  staticStyle: { "margin-right": "5px" }
                                },
                                [_vm._v("*")]
                              )
                            : _vm._e(),
                          _vm._v(_vm._s(param.field_title) + ":")
                        ]),
                        param.field_type == 1
                          ? [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: param.field_val,
                                  callback: function($$v) {
                                    _vm.$set(param, "field_val", $$v)
                                  },
                                  expression: "param.field_val"
                                }
                              })
                            ]
                          : _vm._e(),
                        param.field_type == 2
                          ? [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: param.field_val,
                                    callback: function($$v) {
                                      _vm.$set(param, "field_val", $$v)
                                    },
                                    expression: "param.field_val"
                                  }
                                },
                                _vm._l(param.field_list, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item, value: item }
                                  })
                                }),
                                1
                              )
                            ]
                          : _vm._e(),
                        param.field_type == 3
                          ? [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    multiple: ""
                                  },
                                  model: {
                                    value: param.field_val,
                                    callback: function($$v) {
                                      _vm.$set(param, "field_val", $$v)
                                    },
                                    expression: "param.field_val"
                                  }
                                },
                                _vm._l(param.field_list, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item, value: item }
                                  })
                                }),
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                })
              ],
              2
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "新旧", prop: "new_grade" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.submitForm.new_grade,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "new_grade", $$v)
                    },
                    expression: "submitForm.new_grade"
                  }
                },
                _vm._l(_vm.initData.new_grade, function(val, key) {
                  return _c("el-radio", { key: key, attrs: { label: key } }, [
                    _vm._v(_vm._s(val))
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "租赁标签" } },
            _vm._l(_vm.initData.rent_label, function(item) {
              return _c(
                "div",
                {
                  key: item,
                  staticClass: "inline-block pointer rent_tag",
                  class: { active: _vm.isTagCheck(item) },
                  on: {
                    click: function($event) {
                      return _vm.handleTagClick(item)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "inline-block color-666" }, [
                    _vm._v(_vm._s(item))
                  ]),
                  _c("span", { staticClass: "inline-block text-center" }, [
                    _vm.isTagCheck(item)
                      ? _c("i", {
                          staticClass:
                            "el-icon-check blue-font v-middle check_icon"
                        })
                      : _vm._e()
                  ])
                ]
              )
            }),
            0
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品封面", prop: "imgurl" } },
            [
              _c("crop-upload", {
                ref: "coverUpload",
                attrs: {
                  "show-type": "photo",
                  fixScale: true,
                  "is-square": true,
                  "always-crop": true,
                  fixedBox: false,
                  "init-val": _vm.submitForm.imgurl,
                  "wrapper-width": "120px",
                  "wrapper-height": "120px",
                  "photo-width": 120,
                  "photo-height": 120,
                  autoCropWidth: 150,
                  autoCropHeight: 150,
                  cropperWidth: "400px",
                  cropperHeight: "400px",
                  dialogWidth: "600px",
                  "min-width": 750,
                  "min-height": 750,
                  enlarge: 5
                },
                on: { setVal: _vm.setUrl }
              }),
              _c(
                "div",
                { staticClass: "color-999" },
                [
                  _vm._v(
                    "注：尺寸为750*750px及以上正方形，图片要求为纯白色底图\n        "
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        width: "400",
                        trigger: "hover"
                      }
                    },
                    [
                      _c("div", { staticClass: "img_exp" }, [
                        _c("h5", [_vm._v("商品封面图片示例")]),
                        _c("div", { staticClass: "exp_wrap" }, [
                          _c(
                            "p",
                            { staticClass: "item" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: require("@/assets/images/exp1.png")
                                }
                              }),
                              _c("span", [
                                _c("i", {
                                  staticClass: "green-font el-icon-success"
                                }),
                                _vm._v("推荐")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "item" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: require("@/assets/images/exp2.png")
                                }
                              }),
                              _c("span", [
                                _c("i", {
                                  staticClass: "red-font el-icon-error"
                                }),
                                _vm._v("不推荐")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "item" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: require("@/assets/images/exp3.png")
                                }
                              }),
                              _c("span", [
                                _c("i", {
                                  staticClass: "red-font el-icon-error"
                                }),
                                _vm._v("不推荐")
                              ])
                            ],
                            1
                          )
                        ]),
                        _c("p", { staticClass: "red-font" }, [
                          _vm._v("注：图片要求750*750像素以上的正方形图片；"),
                          _c("br"),
                          _vm._v("注：上传封面图片要求纯白色底图；"),
                          _c("br"),
                          _vm._v("注：精品纯白色底图可上首页推荐位！")
                        ])
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference"
                        },
                        [_vm._v("图片示例>")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品图片", prop: "carousel_img" } },
            [
              _c("crop-upload", {
                ref: "cropUpload",
                attrs: {
                  fixScale: true,
                  sort: true,
                  multiple: true,
                  "always-crop": true,
                  "show-type": "photo",
                  fixedBox: false,
                  "is-square": true,
                  "init-val": _vm.submitForm.carousel_img,
                  "wrapper-width": "120px",
                  "wrapper-height": "120px",
                  autoCropWidth: 150,
                  autoCropHeight: 150,
                  cropperWidth: "400px",
                  cropperHeight: "400px",
                  dialogWidth: "600px",
                  enlarge: 5,
                  maxNum: 8,
                  "photo-width": 120,
                  "photo-height": 120,
                  "min-width": 750,
                  "min-height": 750
                },
                on: { setVal: _vm.bannerChange }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "商品详情" } }, [
            _c(
              "div",
              { staticClass: "editor_wrap" },
              [
                _c("text-editor", {
                  model: {
                    value: _vm.submitForm.goods_content,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "goods_content", $$v)
                    },
                    expression: "submitForm.goods_content"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "租赁方式", prop: "pact_type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.submitForm.pact_type,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "pact_type", $$v)
                    },
                    expression: "submitForm.pact_type"
                  }
                },
                _vm._l(_vm.initData.pact_type, function(val, key) {
                  return _c("el-radio", { key: key, attrs: { label: key } }, [
                    _vm._v(_vm._s(val))
                  ])
                }),
                1
              ),
              _c(
                "p",
                { staticClass: "type_tip" },
                [
                  _vm.submitForm.pact_type == 1
                    ? [
                        _c("span", [
                          _vm._v(
                            "固定租期的芝麻信用授权可扣款金额=商品套餐押金+总租金"
                          )
                        ]),
                        _c("br"),
                        _vm._v(
                          "商品设置固定租期后，承租人须待租赁期满后才能退还租赁物。固定租期无法使用花呗分期功能。\n        "
                        )
                      ]
                    : _vm._e(),
                  _vm.submitForm.pact_type == 2
                    ? [
                        _c("span", [
                          _vm._v("租完即送的芝麻信用授权可扣款金额=总租金")
                        ]),
                        _c("br"),
                        _vm._v(
                          "商品设置租完即送后，租赁期内承租人不得单方面解除协议提前退还租赁物，承租人须按订单约定按期支付租金。租完即送可使用花呗分期功能。\n        "
                        )
                      ]
                    : _vm._e(),
                  _vm.submitForm.pact_type == 3
                    ? [
                        _c("span", [
                          _vm._v(
                            "随租随还的芝麻信用授权可扣款金额=商品套餐押金+总租金"
                          )
                        ]),
                        _c("br"),
                        _vm._v(
                          "商品设置随租随还后，承租人可以随时退还租赁物。随租随还无法使用花呗分期功能。\n        "
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务城市", prop: "province" } },
            [
              _c("el-cascader", {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择",
                  options: _vm.areaList,
                  props: _vm.optionProps,
                  separator: "-"
                },
                model: {
                  value: _vm.submitForm.province,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "province", $$v)
                  },
                  expression: "submitForm.province"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "租赁地址" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.submitForm.service_address,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "service_address", $$v)
                    },
                    expression: "submitForm.service_address"
                  }
                },
                _vm._l(_vm.initData.shop_address, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.address, value: item.address }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "0px" },
              attrs: { label: "配送方式", prop: "send_out" }
            },
            [
              _c(
                "div",
                { staticClass: "flex align-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-right",
                      staticStyle: { width: "40px", "margin-right": "20px" }
                    },
                    [
                      _c("span", { staticClass: "required-icon" }, [
                        _vm._v("*")
                      ]),
                      _vm._v("寄出")
                    ]
                  ),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.submitForm.send_out,
                        callback: function($$v) {
                          _vm.$set(_vm.submitForm, "send_out", $$v)
                        },
                        expression: "submitForm.send_out"
                      }
                    },
                    _vm._l(_vm.initData.send_out, function(val, key) {
                      return _c(
                        "el-radio",
                        { key: key, attrs: { label: key } },
                        [_vm._v(_vm._s(val))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "flex align-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "40px", "margin-right": "20px" }
                  },
                  [_vm._v("归还")]
                ),
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.submitForm.return,
                      callback: function($$v) {
                        _vm.$set(_vm.submitForm, "return", $$v)
                      },
                      expression: "submitForm.return"
                    }
                  },
                  _vm._l(_vm.initData.return, function(val, key) {
                    return _c("el-radio", { key: key, attrs: { label: key } }, [
                      _vm._v(_vm._s(val))
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fix-footer-btns" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          ),
          _vm.$hasMethod("#save")
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("保存")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }