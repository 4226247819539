var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nine_grid" },
    [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 }
          }),
          _vm._m(0)
        ],
        1
      ),
      _vm._l(_vm.iconList, function(item, index) {
        return [
          _c("icon-form", {
            key: index,
            attrs: { "item-index": index, "form-data": item },
            on: {
              upSortItem: function($event) {
                return _vm.upSortItem(index)
              },
              downSortItem: function($event) {
                return _vm.downSortItem(index)
              }
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("图片尺寸请采用1:1，例如100*100px")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }