"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.sub");

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.regexp.replace");

var _order = require("@/api/order");

var _validate = require("@/utils/validate");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editItem'],
  data: function data() {
    var validateMobile = function validateMobile(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };

    var validateString = function validateString(rule, value, callback) {
      if (!(0, _validate.checkString)(value)) {
        callback(new Error('不能包含特殊字符'));
      } else {
        callback();
      }
    };

    return {
      form: {
        orderId: '',
        realname: '',
        mobile: '',
        address: '',
        prov: '',
        city: '',
        area: '',
        province: ''
      },
      rules: {
        realname: [{
          required: true,
          message: '姓名不能为空',
          trigger: 'blur'
        }, {
          validator: validateString,
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        province: [{
          required: true,
          message: '请选择地区',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '详细地址不能为空',
          trigger: 'blur'
        } // { validator: validateString, trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      areaList: []
    };
  },
  methods: {
    handleMobileChange: function handleMobileChange() {
      this.form.mobile = this.form.mobile.replace(/[^\d]/g, "");
    },
    submit: function submit() {
      var _this = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)({}, _this.form);
          params.prov = params.province[0];
          params.city = params.province[1];
          params.area = params.province[2];
          delete params.province;
          (0, _order.setAddress)(params).then(function (res) {
            _this.dialogVisible = false;

            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    },
    init: function init() {
      var _this2 = this;

      (0, _order.getRegion)().then(function (res) {
        _this2.areaList = res ? res[0].sub : [];

        for (var key in _this2.form) {
          _this2.form[key] = _this2.editItem[key] || '';
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};
exports.default = _default;