var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "add_dialog",
          attrs: {
            width: "600px",
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "项目名称:", prop: "rate_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "项目名称" },
                    model: {
                      value: _vm.form.rate_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rate_name", $$v)
                      },
                      expression: "form.rate_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "项目代号:", prop: "rate_value" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入项目代号" },
                    model: {
                      value: _vm.form.rate_value,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rate_value", $$v)
                      },
                      expression: "form.rate_value"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "扣费类型:", prop: "is_percentage" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_percentage,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "is_percentage", $$v)
                        },
                        expression: "form.is_percentage"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("定额")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("比例")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属用户:", prop: "is_member" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_member,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "is_member", $$v)
                        },
                        expression: "form.is_member"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("商户")]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("供应商")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "默认费用:", prop: "rate_default" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请输入默认费用" },
                      model: {
                        value: _vm.form.rate_default,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "rate_default", $$v)
                        },
                        expression: "form.rate_default"
                      }
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.unit))
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }