var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center success_page" }, [
    _vm._m(0),
    _c("h2", { staticClass: "green-font" }, [_vm._v("恭喜您！编辑商品成功")]),
    _c("p", { staticClass: "color-999" }, [
      _vm._v("商品编号：" + _vm._s(_vm.goodsId))
    ]),
    _c(
      "p",
      { staticClass: "btns" },
      [
        _c(
          "el-button",
          {
            staticClass: "blue_border",
            on: {
              click: function($event) {
                return _vm.$router.push(_vm.routeParam)
              }
            }
          },
          [_vm._v(_vm._s(_vm.btnText))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "green-font" }, [
      _c("i", {
        staticClass: "el-icon-circle-check",
        staticStyle: { "font-size": "88px" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }