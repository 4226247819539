var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex recommend_form" },
        [
          _c("crop-upload", {
            attrs: {
              "show-type": "photo",
              accept: ".png",
              "init-val": _vm.formData.goods_img,
              "wrapper-width": "90px",
              "wrapper-height": "90px",
              "img-width": 500,
              "img-height": 500
            },
            on: {
              setVal: function(val) {
                _vm.formData.goods_img = val
              }
            }
          }),
          _c("div", { staticClass: "flex-1 input_wrapper" }, [
            _c(
              "div",
              { staticClass: "flex name" },
              [
                _vm._m(0),
                _c("el-input", {
                  attrs: {
                    type: "text",
                    placeholder: "最多展示10个字段",
                    maxlength: "20"
                  },
                  model: {
                    value: _vm.formData.goods_name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "goods_name", $$v)
                    },
                    expression: "formData.goods_name"
                  }
                })
              ],
              1
            ),
            _vm.showSubtitle
              ? _c(
                  "div",
                  { staticClass: "flex price" },
                  [
                    _c(
                      "label",
                      { staticClass: "flex align-center justify-center" },
                      [_vm._v("标语")]
                    ),
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        placeholder: "请输入每天租赁价格",
                        maxlength: "20"
                      },
                      model: {
                        value: _vm.formData.goods_title,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "goods_title", $$v)
                        },
                        expression: "formData.goods_title"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.formData.goodsData
        ? _c("goods-link", {
            ref: "goodsLink",
            attrs: {
              "goods-data": _vm.formData.goodsData,
              "show-sort": false,
              "show-tool": _vm.showTool
            },
            on: { handleDelete: _vm.deleteGoods }
          })
        : _vm.showTool
        ? _c("div", { staticClass: "add-div", on: { click: _vm.openSelect } }, [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v("选择商品链接\n  ")
          ])
        : _vm._e(),
      _c("goods-dialog", {
        ref: "goodsDialog",
        attrs: {
          "dialog-width": "1340px",
          "page-size": 12,
          "show-type-select": true,
          "show-checked-tool": _vm.showCheckedTool
        },
        on: { setSelect: _vm.setSelect }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("名称")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }