var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info_config_page" },
    [
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "form-inline",
              attrs: { inline: true, model: _vm.searchForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入订单号" },
                    model: {
                      value: _vm.searchForm.orderNo,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "orderNo", $$v)
                      },
                      expression: "searchForm.orderNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物流单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入物流单号" },
                    model: {
                      value: _vm.searchForm.number,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "number", $$v)
                      },
                      expression: "searchForm.number"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物流名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入物流名称" },
                    model: {
                      value: _vm.searchForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "name", $$v)
                      },
                      expression: "searchForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物流状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.searchForm.logisticsStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "logisticsStatus", $$v)
                        },
                        expression: "searchForm.logisticsStatus"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { value: -1, label: "未发货" }
                      }),
                      _c("el-option", { attrs: { value: 1, label: "已发货" } }),
                      _c("el-option", { attrs: { value: 3, label: "已签收" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上链状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.searchForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "status", $$v)
                        },
                        expression: "searchForm.status"
                      }
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "未上链" } }),
                      _c("el-option", { attrs: { value: 1, label: "已上链" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发货时间" } },
                [_c("my-date-picker", { ref: "myDatePicker1" })],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签收时间" } },
                [_c("my-date-picker", { ref: "myDatePicker2" })],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider"),
      _c("div", { staticClass: "flex align-center table-top-btns" }, [
        _c(
          "h5",
          { staticClass: "flex align-center" },
          [
            _c("svg-icon", {
              staticStyle: { "margin-right": "15px" },
              attrs: { "icon-class": "list", size: 15 }
            }),
            _vm._v("数据列表\n    ")
          ],
          1
        )
      ]),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              fixed: "",
              prop: "id",
              label: "ID",
              width: "60"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              fixed: "",
              prop: "order_no",
              label: "订单ID",
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              prop: "logistics_status",
              label: "物流状态",
              width: "110"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.logistics_status == -1
                            ? "未发货"
                            : scope.row.logistics_status == 1
                            ? "已发货"
                            : scope.row.logistics_status == 2
                            ? "运输中"
                            : scope.row.logistics_status == 3
                            ? "已签收"
                            : "其他"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              fixed: "",
              prop: "logistics_name",
              label: "物流公司",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              label: "物流单号"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.logistics_no != 0
                            ? scope.row.logistics_no
                            : "无"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              prop: "deliver_time",
              label: "发货时间",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              prop: "confirm_time",
              label: "签收时间",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              prop: "chain_time",
              width: "100",
              label: "上链时间"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              prop: "chain_status",
              label: "上链状态",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        staticClass: "pointer",
                        attrs: {
                          type:
                            scope.row.user_chain_status === 0
                              ? "primary"
                              : "success",
                          "disable-transitions": ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.getParams(scope.row.chain_params)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.user_chain_status == 0
                                ? "未上链"
                                : "已上链"
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              fixed: "right",
              label: "操作",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "operate_col" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.jumpLogistics(scope.row.order_no)
                              }
                            }
                          },
                          [_vm._v("\n            物流记录\n          ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      }),
      _c("param-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          dialogType: _vm.dialogType,
          requestInfo: _vm.requestInfo,
          responseInfo: _vm.responseInfo
        },
        on: { close: _vm.closeDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }