"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _offDialog = _interopRequireDefault(require("./components/offDialog"));

var _shareDialog = _interopRequireDefault(require("./components/shareDialog"));

var _setDialog = _interopRequireDefault(require("./components/setDialog"));

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _audit = require("@/api/audit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    myDatePicker: _myDatePicker.default,
    offDialog: _offDialog.default,
    shareDialog: _shareDialog.default,
    setDialog: _setDialog.default,
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        goods_name: "",
        shop_name: "",
        cate_id: "",
        rent_type: "",
        create_time: ""
      },
      activeName: '1',
      count: {
        status_put: 0,
        status_off: 0
      },
      total: 0,
      tableData: [],
      categoryList: []
    };
  },
  methods: {
    // 设置活动
    setActivity: function setActivity(id) {
      this.$refs.setDialog.goodsId = id;
      this.$refs.setDialog.dialogVisible = true;
    },
    // 上架/下架
    changeStatus: function changeStatus(id, status) {
      var _this = this;

      if (status == 1) {
        // 下架
        this.$refs.offDialog.goodsId = id;
        this.$refs.offDialog.dialogVisible = true;
      } else {
        // 上架
        this.$confirm("您确定要上架商品吗？", "上架", {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(function () {
          (0, _audit.goodsGrounding)(id).then(function (res) {
            _this.getList(true);
          });
        }).catch(function () {});
      }
    },
    handleMore: function handleMore(command, goodsId) {
      if (command == 1) {
        // 查看
        this.$router.push({
          name: 'goodsView',
          params: {
            goodsId: goodsId
          }
        });
      } else {
        // 分享链接
        this.$refs.shareDialog.goodsId = goodsId;
        this.$refs.shareDialog.dialogVisible = true;
      }
    },
    handleCommand: function handleCommand(command, goodsId) {
      if (command == 1) {
        this.$router.push({
          name: 'goodsBaseEdit',
          params: {
            editId: goodsId
          }
        });
      } else {
        this.$router.push({
          name: 'goodsMealInfo',
          params: {
            goodsId: goodsId
          }
        });
      }
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== 'error') {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        goods_name: "",
        shop_name: "",
        cate_id: "",
        rent_type: "",
        create_time: ""
      };
      this.getList();
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 获取数据
    getList: function getList(flag) {
      var _this2 = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _audit.getGoodsAll)((0, _objectSpread2.default)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        goods_status: this.activeName
      })).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;

        for (var key in _this2.count) {
          _this2.count[key] = res.count[key];
        }
      });
    },
    getSelectList: function getSelectList() {
      var _this3 = this;

      (0, _audit.getCategory)().then(function (res) {
        _this3.categoryList = res || [];
      });
    }
  },
  mounted: function mounted() {
    this.getSelectList();
    this.getList();
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (vm) {
      vm.getList(true);
    });
  }
};
exports.default = _default;