var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "add_dialog",
          attrs: {
            width: "600px",
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级菜单:" } },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.menuList,
                      placeholder: "请选择上级菜单",
                      "search-nested": ""
                    },
                    model: {
                      value: _vm.form.pid,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "pid", $$v)
                      },
                      expression: "form.pid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "菜单名称:", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入菜单名称" },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "控制器:" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入控制器" },
                    model: {
                      value: _vm.form.controller,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "controller", $$v)
                      },
                      expression: "form.controller"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "方法:" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入方法",
                      autosize: { minRows: 2, maxRows: 4 }
                    },
                    model: {
                      value: _vm.form.action,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "action", $$v)
                      },
                      expression: "form.action"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "路径名称:" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入路径名称" },
                    model: {
                      value: _vm.form.path,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态:", prop: "status" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v("禁用")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属平台:", prop: "type" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    [_vm._v("管理后台")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    [_vm._v("供应商后台")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序:" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.form.sort_num,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sort_num", $$v)
                      },
                      expression: "form.sort_num"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }