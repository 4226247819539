var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "meal_form",
          attrs: {
            model: _vm.submitForm,
            rules: _vm.rules,
            "label-width": "200px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "套餐名字", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "50",
                  "show-word-limit": "",
                  placeholder: "请输入套餐名字，如城市+品牌+型号+产品属性"
                },
                model: {
                  value: _vm.submitForm.title,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "title", $$v)
                  },
                  expression: "submitForm.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "租期设置", prop: "lease_set" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.submitForm.lease_set,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "lease_set", $$v)
                    },
                    expression: "submitForm.lease_set"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("按月")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("按天")]),
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v("自定义租期")
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "set_wrap" },
                [
                  _vm.submitForm.lease_set == 1
                    ? [
                        _vm.monthList.length > 0
                          ? _c(
                              "div",
                              { staticClass: "set_form" },
                              [
                                _vm._l(_vm.monthList, function(item, index) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "flex align-center item"
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("label", [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "required-icon"
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("租期:")
                                            ]),
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "请选择"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.itemChange(index)
                                                  }
                                                },
                                                model: {
                                                  value: item.lease_time,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "lease_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.lease_time"
                                                }
                                              },
                                              _vm._l(_vm.monthSelect, function(
                                                item
                                              ) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: {
                                                    label: item + "个月",
                                                    value: item
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "40px",
                                              "margin-right": "20px"
                                            }
                                          },
                                          [
                                            _c("label", [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "required-icon"
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("月租金:")
                                            ]),
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "元",
                                                clearable: ""
                                              },
                                              on: {
                                                blur: function($event) {
                                                  return _vm.changeMoneyInput(
                                                    item,
                                                    "lease_price"
                                                  )
                                                },
                                                change: function($event) {
                                                  return _vm.itemChange(index)
                                                }
                                              },
                                              model: {
                                                value: item.lease_price,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "lease_price",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.lease_price"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeMonthItem(
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticClass: "add_btn",
                            attrs: {
                              icon: "el-icon-plus",
                              size: "small",
                              type: "primary"
                            },
                            on: { click: _vm.addMonthItem }
                          },
                          [_vm._v("添加租期")]
                        )
                      ]
                    : _vm._e(),
                  _vm.submitForm.lease_set == 2
                    ? [
                        _c("p", { staticClass: "color-999" }, [
                          _vm._v("租期设置超过30天时，租期间隔为30天扣款一次。")
                        ]),
                        _vm.dayList.length > 0
                          ? _c(
                              "div",
                              { staticClass: "set_form day_form" },
                              [
                                _vm._l(_vm.dayList, function(
                                  dayItem,
                                  dayIndex
                                ) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: dayIndex,
                                        staticClass: "flex align-center item"
                                      },
                                      [
                                        _c("div", [
                                          _c("label", [
                                            _c(
                                              "i",
                                              { staticClass: "required-icon" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("租期:")
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "inline-block" },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  placeholder: "请输入",
                                                  clearable: ""
                                                },
                                                on: {
                                                  input: function(val) {
                                                    return _vm.changeNumberInput(
                                                      dayItem,
                                                      "lease_time"
                                                    )
                                                  },
                                                  change: function($event) {
                                                    return _vm.itemChange(
                                                      dayIndex
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: dayItem.lease_time,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      dayItem,
                                                      "lease_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dayItem.lease_time"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "suffix" },
                                                [_vm._v("天")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "color-999",
                                              staticStyle: {
                                                "margin-left": "10px"
                                              }
                                            },
                                            [_vm._v("输入范围：1-365")]
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "40px",
                                              "margin-right": "20px"
                                            }
                                          },
                                          [
                                            _c("label", [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "required-icon"
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("日租金:")
                                            ]),
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "元",
                                                clearable: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.itemChange(
                                                    dayIndex
                                                  )
                                                },
                                                blur: function($event) {
                                                  return _vm.changeMoneyInput(
                                                    dayItem,
                                                    "lease_price"
                                                  )
                                                }
                                              },
                                              model: {
                                                value: dayItem.lease_price,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    dayItem,
                                                    "lease_price",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dayItem.lease_price"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeDayItem(
                                                  dayIndex
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticClass: "add_btn",
                            attrs: {
                              icon: "el-icon-plus",
                              size: "small",
                              type: "primary"
                            },
                            on: { click: _vm.addDayItem }
                          },
                          [_vm._v("添加租期")]
                        )
                      ]
                    : _vm._e(),
                  _vm.submitForm.lease_set == 3
                    ? [
                        _c("div", [
                          _c("p", [
                            _vm._v("自定义租期设置"),
                            _c(
                              "span",
                              {
                                staticClass: "color-999",
                                staticStyle: { "margin-left": "20px" }
                              },
                              [_vm._v("在下方栏中选择内容并生成表格")]
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-wrap align-center custom_form"
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("span", { staticClass: "suffix before" }, [
                                    _c("i", { staticClass: "required-icon" }, [
                                      _vm._v("*")
                                    ]),
                                    _vm._v("总租期")
                                  ]),
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      placeholder: "0-365天",
                                      clearable: ""
                                    },
                                    on: {
                                      input: function(val) {
                                        return _vm.changeNumberInput(
                                          _vm.customForm,
                                          "lease_days"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.customForm.lease_days,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.customForm,
                                          "lease_days",
                                          $$v
                                        )
                                      },
                                      expression: "customForm.lease_days"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("span", { staticClass: "suffix before" }, [
                                    _c("i", { staticClass: "required-icon" }, [
                                      _vm._v("*")
                                    ]),
                                    _vm._v("租期间隔")
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择"
                                      },
                                      model: {
                                        value: _vm.customForm.withhold_cycle,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.customForm,
                                            "withhold_cycle",
                                            $$v
                                          )
                                        },
                                        expression: "customForm.withhold_cycle"
                                      }
                                    },
                                    _vm._l(_vm.intervalList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item + "天",
                                          value: item
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("span", { staticClass: "suffix before" }, [
                                    _vm._v("每期租金")
                                  ]),
                                  _c("el-input", {
                                    staticClass: "no_border",
                                    staticStyle: { width: "160px" },
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: ""
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.changeMoneyInput(
                                          _vm.customForm,
                                          "lease_price"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.customForm.lease_price,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.customForm,
                                          "lease_price",
                                          $$v
                                        )
                                      },
                                      expression: "customForm.lease_price"
                                    }
                                  }),
                                  _c("span", { staticClass: "suffix" }, [
                                    _vm._v("元")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    icon: "el-icon-plus",
                                    size: "small",
                                    type: "primary"
                                  },
                                  on: { click: _vm.addCustomItem }
                                },
                                [_vm._v("生成自定义租期")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "color-999",
                              staticStyle: { "line-height": "23px" }
                            },
                            [
                              _vm._v(
                                "1. 租期间隔根据设置总租期生成期数，多余的租赁天数单独生成一个期数。"
                              ),
                              _c("br"),
                              _vm._v("2. 每个租期可单独设置租赁金额。")
                            ]
                          )
                        ]),
                        _vm._l(_vm.customList, function(customItem, cIndex) {
                          return _c(
                            "div",
                            { key: cIndex, staticClass: "custom_item" },
                            [
                              _c("p", [_vm._v("租期" + _vm._s(cIndex + 1))]),
                              _c(
                                "div",
                                {
                                  staticClass: "flex align-center",
                                  staticStyle: {
                                    width: "800px",
                                    position: "relative",
                                    "padding-right": "70px"
                                  }
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "table_item",
                                      attrs: { data: customItem, border: "" }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "期数",
                                          width: "100px"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      "第" +
                                                        _vm._s(
                                                          scope.row.which_period
                                                        ) +
                                                        "期"
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "租赁天数"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.lease_days
                                                      ) + "天"
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "租期间隔"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.withhold_cycle
                                                      ) + "天"
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            align: "center",
                                            width: "200"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "120px"
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              "请输入",
                                                            clearable: ""
                                                          },
                                                          on: {
                                                            blur: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeMoneyInput(
                                                                scope.row,
                                                                "lease_price"
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .lease_price,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "lease_price",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.lease_price"
                                                          }
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "suffix"
                                                          },
                                                          [_vm._v("元")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c("span", [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "required-icon"
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("每期租金")
                                            ])
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "delete_btn" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeCustomItem(
                                                cIndex
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _vm.rentType == 2 &&
          (_vm.submitForm.lease_set == 2 || _vm.submitForm.lease_set == 1)
            ? _c(
                "el-form-item",
                { attrs: { label: "花呗分期" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 2 },
                      model: {
                        value: _vm.submitForm.is_huabei,
                        callback: function($$v) {
                          _vm.$set(_vm.submitForm, "is_huabei", $$v)
                        },
                        expression: "submitForm.is_huabei"
                      }
                    },
                    [_vm._v("商品支持花呗分期")]
                  ),
                  _c("p", { staticClass: "tip" }, [
                    _vm._v(
                      "注：花呗分期商品成单后并结算后，供应商得租期总金额。了解花呗分期介绍"
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.rentType == 2 &&
          (_vm.submitForm.lease_set == 2 || _vm.submitForm.lease_set == 1) &&
          _vm.submitForm.is_huabei == 1
            ? _c("el-form-item", { attrs: { label: "花呗设置" } }, [
                _c(
                  "div",
                  { staticClass: "set_wrap" },
                  [
                    _c("p", [
                      _vm._v("批量设置"),
                      _c(
                        "span",
                        {
                          staticClass: "color-999",
                          staticStyle: { "margin-left": "20px" }
                        },
                        [_vm._v("在下方栏中选择内容进行批量填充")]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-wrap align-center custom_form"
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("span", { staticClass: "suffix before" }, [
                              _vm._v("花呗3期")
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "120px" },
                                attrs: { clearable: "", placeholder: "请选择" },
                                model: {
                                  value: _vm.setForm.third,
                                  callback: function($$v) {
                                    _vm.$set(_vm.setForm, "third", $$v)
                                  },
                                  expression: "setForm.third"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "商家承担", value: 1 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "用户承担", value: 2 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "关闭", value: 3 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("span", { staticClass: "suffix before" }, [
                              _vm._v("花呗6期")
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "120px" },
                                attrs: { clearable: "", placeholder: "请选择" },
                                model: {
                                  value: _vm.setForm.sixth,
                                  callback: function($$v) {
                                    _vm.$set(_vm.setForm, "sixth", $$v)
                                  },
                                  expression: "setForm.sixth"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "商家承担", value: 1 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "用户承担", value: 2 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "关闭", value: 3 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("span", { staticClass: "suffix before" }, [
                              _vm._v("花呗12期")
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "120px" },
                                attrs: { clearable: "", placeholder: "请选择" },
                                model: {
                                  value: _vm.setForm.twelve,
                                  callback: function($$v) {
                                    _vm.$set(_vm.setForm, "twelve", $$v)
                                  },
                                  expression: "setForm.twelve"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "商家承担", value: 1 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "用户承担", value: 2 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "关闭", value: 3 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.quickSet }
                          },
                          [_vm._v("立即设置")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        staticClass: "set_table",
                        attrs: { data: _vm.submitForm.huabei_data, border: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "租期设置",
                            width: "100px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.lease_term
                                            ? scope.row.lease_term +
                                                (_vm.submitForm.lease_set == 1
                                                  ? "个月"
                                                  : "天")
                                            : ""
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3251279160
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "花呗总金额",
                            prop: "rent_total"
                          }
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: { align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.changeHbBear(
                                                scope.row.three_bear,
                                                scope.row,
                                                "three_fee"
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.three_bear,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "three_bear",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.three_bear"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "商家承担",
                                              value: 1
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "用户承担",
                                              value: 2
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: { label: "关闭", value: 3 }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-block text-left",
                                          staticStyle: {
                                            "min-width": "60px",
                                            "margin-left": "10px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.three_fee
                                                ? "￥" + scope.row.three_fee
                                                : ""
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3484342369
                            )
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("span", [
                                _c("i", { staticClass: "required-icon" }, [
                                  _vm._v("*")
                                ]),
                                _vm._v("花呗3期承担-承担金额")
                              ])
                            ])
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: { align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.changeHbBear(
                                                scope.row.six_bear,
                                                scope.row,
                                                "six_fee"
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.six_bear,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "six_bear",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.six_bear"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "商家承担",
                                              value: 1
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "用户承担",
                                              value: 2
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: { label: "关闭", value: 3 }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-block text-left",
                                          staticStyle: {
                                            "min-width": "60px",
                                            "margin-left": "10px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.six_fee
                                                ? "￥" + scope.row.six_fee
                                                : ""
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              492543405
                            )
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("span", [
                                _c("i", { staticClass: "required-icon" }, [
                                  _vm._v("*")
                                ]),
                                _vm._v("花呗6期承担-承担金额")
                              ])
                            ])
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: { align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.changeHbBear(
                                                scope.row.twelve_bear,
                                                scope.row,
                                                "twelve_fee"
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.twelve_bear,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "twelve_bear",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.twelve_bear"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "商家承担",
                                              value: 1
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "用户承担",
                                              value: 2
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: { label: "关闭", value: 3 }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-block text-left",
                                          staticStyle: {
                                            "min-width": "60px",
                                            "margin-left": "10px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.twelve_fee
                                                ? "￥" + scope.row.twelve_fee
                                                : ""
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              871201750
                            )
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("span", [
                                _c("i", { staticClass: "required-icon" }, [
                                  _vm._v("*")
                                ]),
                                _vm._v("花呗12期承担-承担金额")
                              ])
                            ])
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex color-999",
                        staticStyle: {
                          "line-height": "1",
                          "margin-top": "14px"
                        }
                      },
                      [
                        _c("span", [_vm._v("注：")]),
                        _c(
                          "p",
                          [
                            _vm._v(
                              "1、花呗分期每笔均产生分期手续费，商户可选择用户或自己承担或者关闭当前租期花呗功能（无法共同承担）。"
                            ),
                            _c("br"),
                            _vm._v(
                              "2、花呗手续费承担方选择后，将自动计算承担金额。\n            "
                            ),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "400",
                                  trigger: "hover"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "fee_exp" },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticStyle: { "margin-bottom": "14px" }
                                      },
                                      [_vm._v("手续费花呗分期费率表")]
                                    ),
                                    _c(
                                      "el-table",
                                      {
                                        staticClass: "fee_table",
                                        attrs: { data: _vm.feeData, border: "" }
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "期数",
                                            prop: "num"
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "商户承担",
                                            prop: "merchant"
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "用户承担",
                                            prop: "user"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference"
                                  },
                                  [_vm._v("查看费率表")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "起租日设置" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.submitForm.start_date,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "start_date", $$v)
                    },
                    expression: "submitForm.start_date"
                  }
                },
                _vm._l(_vm.startDateSelect, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item + "天", value: item }
                  })
                }),
                1
              ),
              _c("p", { staticClass: "tip" }, [
                _vm._v("起租日为租赁合同起租时间，默认起租日为下单日3天后。")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "数量", prop: "quantity" } },
            [
              _c("el-input", {
                attrs: { placeholder: "库存数量", clearable: "" },
                on: {
                  input: function(val) {
                    return _vm.changeNumberInput(_vm.submitForm, "quantity")
                  }
                },
                model: {
                  value: _vm.submitForm.quantity,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "quantity", $$v)
                  },
                  expression: "submitForm.quantity"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "押金", prop: "foregift" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  staticClass: "suffix_input",
                  attrs: { placeholder: "请输入押金", clearable: "" },
                  on: {
                    blur: function($event) {
                      return _vm.changeMoneyInput(_vm.submitForm, "foregift")
                    }
                  },
                  model: {
                    value: _vm.submitForm.foregift,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "foregift", $$v)
                    },
                    expression: "submitForm.foregift"
                  }
                }),
                _c("span", { staticClass: "suffix" }, [_vm._v("元")])
              ],
              1
            ),
            _c("p", { staticClass: "tip" }, [
              _vm._v(
                "当前商品合同类型为随租随还，芝麻信用授权可扣款金额=商品套餐押金+总租金"
              )
            ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "是否买断", prop: "is_buy_out" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.submitForm.is_buy_out,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "is_buy_out", $$v)
                    },
                    expression: "submitForm.is_buy_out"
                  }
                },
                [_vm._v("是")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.submitForm.is_buy_out,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "is_buy_out", $$v)
                    },
                    expression: "submitForm.is_buy_out"
                  }
                },
                [_vm._v("否")]
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "销售价格", prop: "price" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  staticClass: "suffix_input",
                  attrs: { placeholder: "请输入销售价格", clearable: "" },
                  on: {
                    blur: function($event) {
                      return _vm.changeMoneyInput(_vm.submitForm, "price")
                    }
                  },
                  model: {
                    value: _vm.submitForm.price,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "price", $$v)
                    },
                    expression: "submitForm.price"
                  }
                }),
                _c("span", { staticClass: "suffix" }, [_vm._v("元")])
              ],
              1
            ),
            _c("p", { staticClass: "tip" }, [
              _vm._v(
                "商家需设置销售价格，租户在租期中，买断价格=销售价格-累计支付的租期金额，商家也可根据实际情况在订单单独设置买断金额。"
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fix-footer-btns" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          ),
          _vm.$hasMethod("#save")
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("保存")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }