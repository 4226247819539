var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col_form office_first" },
    [
      _vm.levelName == "first_level"
        ? _c("crop-upload", {
            staticClass: "w-100 img_bg",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.background_image,
              "wrapper-width": "359px",
              "wrapper-height": "388px",
              "img-width": 750,
              "img-height": 810,
              "photo-width": 359,
              "photo-height": 388
            },
            on: {
              setVal: function(val) {
                _vm.background_image = val
              }
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "input_wrapper" }, [
        _c(
          "div",
          { staticClass: "flex name" },
          [
            _vm._m(0),
            _c("el-input", {
              attrs: { placeholder: "请输入主标题", clearable: "" },
              model: {
                value: _vm.formData.main_title,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "main_title", $$v)
                },
                expression: "formData.main_title"
              }
            })
          ],
          1
        )
      ]),
      _c("goods-add", {
        attrs: { maxNum: _vm.goodsNum, "goods-list": _vm.formData.goods },
        on: { setGoods: _vm.setGoods }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("主标题")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }