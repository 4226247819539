var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-page" },
    [
      _c("div", { staticClass: "details_page" }, [
        _c("div", { staticClass: "top flex flex-between align-center" }, [
          _c("div", { staticClass: "top_left" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/tip-icon.png"),
                alt: "tip"
              }
            }),
            _c("span", [
              _vm._v(
                "工单状态：" +
                  _vm._s(
                    _vm.suggestInfo.status == 1
                      ? "待处理"
                      : _vm.suggestInfo.status == 2
                      ? "处理中"
                      : _vm.suggestInfo.status == 3
                      ? "已处理"
                      : _vm.suggestInfo.status == 4
                      ? "已撤销"
                      : "已超时"
                  )
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "top_right" },
            [
              _vm.suggestInfo.status != "3"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btns",
                      attrs: { size: "small", type: "warning" },
                      on: {
                        click: function($event) {
                          return _vm.intervene(_vm.suggestInfo.id)
                        }
                      }
                    },
                    [_vm._v("介入")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "btns",
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.remark(_vm.suggestInfo.id)
                    }
                  }
                },
                [_vm._v("备注")]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "bottom_info" }, [
          _c("div", { staticClass: "orderInfo" }, [
            _vm._m(0),
            _c("div", { staticClass: "orderInfo_left" }, [
              _c("div", [
                _c("p", [
                  _c("span", { staticStyle: { "margin-left": "14px" } }, [
                    _vm._v("工单号：")
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.suggestInfo.work_order))])
                ]),
                _c("p", [
                  _vm._v("\n              截止时间："),
                  _c("span", [_vm._v(_vm._s(_vm.suggestInfo.end_time))])
                ]),
                _c("p", [
                  _vm._v("\n              联系手机："),
                  _c("span", [_vm._v(_vm._s(_vm.suggestInfo.user_mobile))])
                ])
              ])
            ]),
            _c("div", { staticClass: "orderInfo_right" }, [
              _c("div", [
                _c("p", [
                  _vm._v("\n              创建时间："),
                  _c("span", [_vm._v(_vm._s(_vm.suggestInfo.create_time))])
                ]),
                _c("p", [
                  _vm._v("\n              工单等级："),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.suggestInfo.grade == 1
                          ? "普通"
                          : _vm.suggestInfo.grade == 2
                          ? "紧急"
                          : "严重"
                      )
                    )
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "orderGoods" }, [
            _vm._m(1),
            _c("div", { staticClass: "explain" }, [
              _c("p", [
                _vm._v("\n            投诉类型："),
                _c("span", { staticClass: "red-font" }, [
                  _vm._v(_vm._s(_vm.suggestInfo.suggest_type))
                ])
              ]),
              _c("p", [
                _vm._v("\n            投诉类别："),
                _c("span", { staticClass: "red-font" }, [
                  _vm._v(
                    _vm._s(
                      _vm.suggestInfo.suggest_cate == 1
                        ? "商品名称"
                        : _vm.suggestInfo.suggest_cate == 2
                        ? "商家名称"
                        : "订单编号"
                    )
                  )
                ])
              ]),
              _c("p", [
                _vm._v("\n            类别内容："),
                _c("span", { staticClass: "red-font" }, [
                  _vm._v(_vm._s(_vm.suggestInfo.suggest_title))
                ])
              ]),
              _c("p", [
                _vm._v("\n            详细说明："),
                _c("span", { staticClass: "red-font" }, [
                  _vm._v(_vm._s(_vm.suggestInfo.content))
                ])
              ]),
              _c(
                "div",
                _vm._l(_vm.imgList, function(item, index) {
                  return _c("preview-img", {
                    key: index,
                    attrs: { "img-url": item }
                  })
                }),
                1
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "orderRecords" },
            [
              _vm._m(2),
              _c(
                "el-table",
                { attrs: { data: _vm.suggestList } },
                [
                  _c("el-table-column", {
                    attrs: { label: "内容", prop: "operator_content" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作人", prop: "operator" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "时间", prop: "create_time" }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer setBtn",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.toClose()
                }
              }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      ),
      _c("dispose", {
        ref: "disposes",
        attrs: { "suggest-id": _vm.suggestId }
      }),
      _c("note-records", {
        ref: "noteRecord",
        attrs: { "suggest-id": _vm.suggestId }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mar20" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/xcx.png"), alt: "icon" }
      }),
      _c("span", { staticClass: "orderInfo_title" }, [_vm._v("订单信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mar20" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/xcx.png"), alt: "icon" }
      }),
      _c("span", { staticClass: "orderInfo_title" }, [_vm._v("详情说明")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mar20" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/xcx.png"), alt: "icon" }
      }),
      _c("span", { staticClass: "orderInfo_title" }, [_vm._v("投诉进度记录")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }