"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _recommend = require("@/api/recommend");

var _xcx = require("@/api/xcx");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['cateId'],
  data: function data() {
    return {
      dialogVisible: false,
      searchForm: {
        page: 1,
        goods_name: '',
        cate_id: '',
        shop_name: ''
      },
      categoryList: [],
      total: 0,
      tableData: [],
      pageSize: 5
    };
  },
  methods: {
    add: function add(id) {
      var _this = this;

      (0, _xcx.addList)({
        goodsId: id,
        listCateId: this.cateId
      }).then(function (res) {
        _this.getList();

        _this.$emit('getList');
      });
    },
    getList: function getList() {
      var _this2 = this;

      (0, _xcx.chooseGoods)((0, _objectSpread2.default)({
        pageSize: this.pageSize,
        list_cate_id: this.cateId
      }, this.searchForm)).then(function (res) {
        _this2.tableData = res.data;
        _this2.total = res.total;
      });
    },
    // 页码切换
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchForm.page = val;
      this.getList();
    },
    search: function search() {
      this.searchForm.page = 1;
      this.getList();
    },
    reset: function reset() {
      this.searchForm = {
        page: 1,
        goods_name: '',
        cate_id: '',
        shop_name: ''
      };
      this.getList();
    },
    getSelectList: function getSelectList() {
      var _this3 = this;

      (0, _recommend.getCategoryList)().then(function (res) {
        _this3.categoryList = res || [];
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this4 = this;

      if (val) {
        this.getSelectList();
        this.$nextTick(function () {
          _this4.getList();
        });
      } else {
        // 重置
        this.searchForm = {
          page: 1,
          goods_name: '',
          cate_id: '',
          shop_name: ''
        };
      }
    }
  }
};
exports.default = _default;