"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

require("core-js/modules/es6.array.find");

var _recommend = require("@/api/recommend");

var _form = _interopRequireDefault(require("../compoments/form"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsForm: _form.default
  },
  data: function data() {
    return {
      activeName: "",
      tabList: [],
      dataList: []
    };
  },
  computed: {
    configTitle: function configTitle() {
      var _this = this;

      var tab = this.tabList.find(function (item) {
        return item.id == _this.activeName;
      });
      return tab ? tab.cate_name : "商品推荐设置";
    }
  },
  methods: {
    // 升序
    upSortPos: function upSortPos(index) {
      if (index === 0) {
        return;
      }

      var item = this.dataList[index];
      this.$set(this.dataList, index, this.dataList[index - 1]);
      this.$set(this.dataList, index - 1, item);
    },
    // 降序
    downSortPos: function downSortPos(index) {
      if (index === this.dataList.length - 1) {
        return;
      }

      var item = this.dataList[index];
      this.$set(this.dataList, index, this.dataList[index + 1]);
      this.$set(this.dataList, index + 1, item);
    },
    // 删除推荐位
    deletePos: function deletePos(index) {
      this.dataList.splice(index, 1);
    },
    // 添加推荐位
    addRecommend: function addRecommend() {
      this.dataList.push({
        goods_name: "",
        goods_img: "",
        goods_id: null
      });
    },
    submit: function submit() {
      var _this2 = this;

      // 保存
      console.log(this.activeName, this.dataList);

      for (var index in this.dataList) {
        if (!this.dataList[index].goods_name) {
          this.$message({
            message: "推荐位" + (parseInt(index) + 1) + "名称不能为空",
            type: "warning"
          });
          return;
        }

        if (!this.dataList[index].goods_img) {
          this.$message({
            message: "推荐位" + (parseInt(index) + 1) + "图片不能为空",
            type: "warning"
          });
          return;
        }

        if (!this.dataList[index].goods_id) {
          this.$message({
            message: "推荐位" + (parseInt(index) + 1) + "请绑定商品链接",
            type: "warning"
          });
          return;
        }
      }

      (0, _recommend.addCategoryGoods)({
        cate_id: this.activeName,
        goods_arr: this.dataList
      }).then(function (res) {
        _this2.getGoods();
      });
    },
    reset: function reset() {
      // 重置
      this.getGoods();
    },
    handleClick: function handleClick(tab) {
      this.getGoods();
    },
    // 获取对应商品列表
    getGoods: function getGoods() {
      var _this3 = this;

      (0, _recommend.getCategoryGoodsById)(this.activeName).then(function (res) {
        var list = [];

        var _iterator = (0, _createForOfIteratorHelper2.default)(res),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            list.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              goodsData: {
                goods_id: item.goods_id,
                title: item.title,
                imgurl: item.imgurl,
                cate_name: item.cate_name
              }
            }));
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        _this3.dataList = list;
      });
    },
    // 获取tabs
    getTabs: function getTabs() {
      var _this4 = this;

      (0, _recommend.getCategory)().then(function (res) {
        _this4.tabList = res;
        _this4.activeName = _this4.tabList[0].id + "";

        _this4.getGoods();
      });
    }
  },
  mounted: function mounted() {
    this.getTabs();
  }
};
exports.default = _default;