"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _user = require("@/api/user");

var _system = require("@/api/system");

var _goods = require("@/api/goods");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['id'],
  data: function data() {
    return {
      loading: false,
      memberList: [{
        id: 0,
        shopname: '请选择'
      }],
      memberId: 0,
      form: {
        memberId: 0
      },
      timer: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      (0, _system.getMemberList)({
        type: 1
      }).then(function (res) {
        var _this$memberList;

        console.log(res);

        (_this$memberList = _this.memberList).push.apply(_this$memberList, (0, _toConsumableArray2.default)(res));
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      var data = this.form;

      if (data.memberId == 0) {
        this.$message.error('请选择要复制的供应商商品');
        return false;
      }

      this.loading = true;
      (0, _goods.copyGoodsType)({
        member_id: this.id,
        copy_source: data.memberId
      }).then(function (res) {
        console.log(res);
        _this2.timer = setInterval(function () {
          (0, _goods.copyGoods)({
            member_id: _this2.id,
            copy_source: data.memberId
          }).then(function (res) {
            console.log(res);
          }).catch(function (err) {
            console.log('copyGoods-err', err);

            if (err.msg.indexOf('复制完成') > -1) {
              _this2.onClear();
            }
          });
        }, 5000);
      });
    },
    onClear: function onClear() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = 0;
      }

      this.loading = false;
    },
    download: function download() {
      window.location.href = process.env.VUE_APP_BASE_API + '/muban/vipOpenCatd.doc';
    }
  }
};
exports.default = _default;