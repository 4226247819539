var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-page" }, [
    _c("div", { staticClass: "flex align-center table-top-btns" }, [
      _c(
        "h5",
        { staticClass: "flex align-center" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "15px" },
            attrs: { "icon-class": "list", size: 15 }
          }),
          _vm._v(
            "配置列表 - " + _vm._s(_vm.supplierInfo.shopname) + "\n        "
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      [
        _c(
          "el-form",
          { ref: "formData" },
          _vm._l(_vm.formData, function(topLevel) {
            return topLevel.status
              ? _c(
                  "div",
                  { staticClass: "level-0" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "edit-header pointer flex flex-between",
                        on: {
                          click: function($event) {
                            return _vm.switchLevel(topLevel)
                          }
                        }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(topLevel.title))]),
                        topLevel.show
                          ? _c("i", { staticClass: "el-icon-arrow-up" })
                          : _c("i", { staticClass: "el-icon-arrow-down" })
                      ]
                    ),
                    _vm._l(topLevel.children, function(item) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: topLevel.show,
                              expression: "topLevel.show"
                            }
                          ],
                          staticClass: "flex flex-wrap list_info"
                        },
                        [
                          item.status
                            ? [
                                item.is_parent == 1
                                  ? _c("div", { staticClass: "list_item" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "parent flex-1",
                                          style: {
                                            margin:
                                              "0 " +
                                              (20 + item.level * 30) +
                                              "px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.full_key) +
                                              " " +
                                              _vm._s(
                                                item.remark
                                                  ? " - " + item.remark
                                                  : ""
                                              )
                                          )
                                        ]
                                      )
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "list_item" },
                                      [
                                        _c("div", { staticClass: "label" }, [
                                          _vm._v(_vm._s(item.title) + " ：")
                                        ]),
                                        _c("el-input", {
                                          staticClass: "flex-1",
                                          attrs: {
                                            type:
                                              item.data.length > 50
                                                ? "textarea"
                                                : "",
                                            rows:
                                              item.data.length > 200 ? 5 : 2,
                                            clearable: "",
                                            disabled: item.lock == 1
                                          },
                                          model: {
                                            value: item.data,
                                            callback: function($$v) {
                                              _vm.$set(item, "data", $$v)
                                            },
                                            expression: "item.data"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "remark flex align-center"
                                          },
                                          [_vm._v(_vm._s(item.remark))]
                                        )
                                      ],
                                      1
                                    )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          }),
          0
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page-footer-btns" },
      [
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v("返回")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("提交")]
          )
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }