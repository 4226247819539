"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _wangeditor = _interopRequireDefault(require("wangeditor"));

var _index = require("@/api/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      editor: null,
      info_: null
    };
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isClear: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isClear: function isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear();
        this.info_ = null;
      }
    },
    value: function value(_value) {
      if (_value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value);
      }
    }
  },
  mounted: function mounted() {
    this.seteditor();
    this.editor.txt.html(this.value);
  },
  methods: {
    getHtml: function getHtml() {
      console.log(this.editor.txt.html());
    },
    seteditor: function seteditor() {
      var _this = this;

      this.editor = new _wangeditor.default(this.$refs.toolbar, this.$refs.editor);
      this.editor.customConfig = this.editor.customConfig ? this.editor.customConfig : this.editor.config;
      this.editor.customConfig.uploadImgShowBase64 = false; // base 64 存储图片，如果这个参数设置为true的话，就不用配置服务器端上传地址

      this.editor.customConfig.showLinkImg = false; //   禁止上传网络图片
      // this.editor.customConfig.uploadImgServer = '/upload'// 这是服务器端上传图片的接口路径
      // this.editor.customConfig.uploadImgHeaders = { 'Content-Type': 'multipart/form-data' }// 自定义 header  上传文件格式为file文件，用form-data格式
      // this.editor.customConfig.uploadFileName = 'file' // 后端接受上传文件的参数名
      // this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
      // this.editor.customConfig.uploadImgMaxLength = 6 // 限制一次最多上传 6张图片
      // this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
      // 配置菜单

      this.editor.customConfig.menus = ['head', // 标题
      'bold', // 粗体
      'fontSize', // 字号
      'fontName', // 字体
      'italic', // 斜体
      'underline', // 下划线
      'strikeThrough', // 删除线
      'foreColor', // 文字颜色
      'backColor', // 背景颜色
      'link', // 插入链接
      'list', // 列表
      'justify', // 对齐方式
      'quote', // 引用
      'emoticon', // 表情
      'image', // 插入图片
      'table', // 表格
      'video', // 插入视频
      'code', // 插入代码
      'undo', // 撤销
      'redo', // 重复
      'fullscreen' // 全屏
      ];
      this.editor.customConfig.uploadImgHooks = {
        fail: function fail(xhr, editor, result) {
          // 插入图片失败回调
          console.log(xhr, editor, result);
        },
        success: function success(xhr, editor, result) {
          // 图片上传成功回调
          console.log(xhr, editor);
        },
        timeout: function timeout(xhr, editor) {
          // 网络超时的回调
          console.log(xhr, editor);
        },
        error: function error(xhr, editor) {
          console.log(xhr, editor); // 图片上传错误的回调
        }
      };

      this.editor.customConfig.customUploadImg = function (files, insert) {
        for (var i = 0; i < files.length; i++) {
          var formData = new FormData();
          formData.append("file", files[i], files[i].name);
          (0, _index.upload)(formData).then(function (res) {
            insert(_this.$globalObj.imgBaseUrl + res.image);
          });
        }
      };

      this.editor.customConfig.onchange = function (html) {
        _this.info_ = html;

        _this.$emit('change', _this.info_);
      }; // 创建富文本编辑器


      this.editor.create();
    }
  }
};
exports.default = _default;