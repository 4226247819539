var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category_page footer-page" },
    [
      _c(
        "div",
        { staticClass: "top_tabs" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.tabList, function(item) {
              return _c("el-tab-pane", {
                key: item.id,
                attrs: { label: item.main_title, name: item.id + "" }
              })
            }),
            1
          ),
          _vm.tabList.length > 0 && _vm.$hasMethod("#add")
            ? _c(
                "el-link",
                {
                  attrs: {
                    href: "/webConfig/show",
                    underline: false,
                    type: "primary"
                  }
                },
                [_vm._v("添加板块")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "flex tab_content" }, [
        _c(
          "div",
          { staticClass: "img_wrapper" },
          [
            _c(
              "el-image",
              {
                attrs: {
                  src: require("../../../assets/images/demo_activity.jpg")
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error"
                  },
                  [_c("i", { staticClass: "el-icon-picture-outline" })]
                )
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "config_wrapper" }, [
          !_vm.$hasMethod("#edit")
            ? _c("div", { staticClass: "mask" })
            : _vm._e(),
          _c("h5", [_vm._v(_vm._s(_vm.configTitle))]),
          _c(
            "div",
            { staticClass: "main" },
            [
              _vm._l(_vm.dataList, function(item, index) {
                return [
                  _c("goods-link", {
                    key: index,
                    attrs: {
                      "goods-data": item,
                      "show-tool": _vm.$hasMethod("#edit")
                    },
                    on: {
                      handleDelete: function($event) {
                        return _vm.deleteGoods(index)
                      },
                      upSort: function($event) {
                        return _vm.upSort(index)
                      },
                      downSort: function($event) {
                        return _vm.downSort(index)
                      }
                    }
                  })
                ]
              }),
              _vm.dataList.length < 5 && _vm.$hasMethod("#edit")
                ? _c(
                    "div",
                    { staticClass: "add-div", on: { click: _vm.openSelect } },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(
                        "添加商品(" +
                          _vm._s(_vm.dataList.length) +
                          "/5)\n        "
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _vm.$hasMethod("#edit")
        ? _c(
            "div",
            { staticClass: "page-footer-btns" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.reset } },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        : _vm._e(),
      _c("goods-dialog", {
        ref: "goodsDialog",
        attrs: { multiple: true, maxNum: 5, "show-checked-tool": true },
        on: { setSelect: _vm.setSelect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }