var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "topDiv" }, [
        _c("p", { staticClass: "title p1" }, [_vm._v("订单物流信息")]),
        _c("div", { staticClass: "logistics_info" }, [
          _c("div", { staticClass: "info_title flexBetween" }, [
            _c("div", [
              _c("label", { attrs: { for: "" } }, [_vm._v("物流公司：")]),
              _c("span", [
                _vm._v(_vm._s(_vm.logisticsData.logistics_name || "无"))
              ])
            ]),
            _c("div", [
              _c("label", { attrs: { for: "" } }, [_vm._v("物流单号：")]),
              _c("span", [
                _vm._v(_vm._s(_vm.logisticsData.logistics_no || "无"))
              ])
            ]),
            _c("div", [
              _c("label", { attrs: { for: "" } }, [_vm._v("物流状态：")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.logisticsData.status == 0
                      ? "其他"
                      : _vm.logisticsData.status == 1
                      ? "已发货"
                      : _vm.logisticsData.status == 2
                      ? "运输中"
                      : "已签收"
                  )
                )
              ])
            ])
          ])
        ]),
        _c(
          "div",
          [
            _c(
              "el-timeline",
              { attrs: { reverse: _vm.reverse } },
              _vm._l(_vm.activities, function(item, index) {
                return _c(
                  "el-timeline-item",
                  {
                    key: index,
                    attrs: { placement: "top", timestamp: item.time }
                  },
                  [_vm._v("\n          " + _vm._s(item.context) + "\n        ")]
                )
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "delivery" }, [
        _c("div", { staticClass: "title flexBetween" }, [
          _c("p", { staticClass: "p1" }, [_vm._v("订单发货凭证")]),
          _vm.logisticsData.deliver_time
            ? _c("p", { staticClass: "p1" }, [
                _vm._v("发货时间：\n        "),
                _c("span", { staticStyle: { color: "#666" } }, [
                  _vm._v(_vm._s(_vm.logisticsData.deliver_time))
                ])
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "flex imgDiv" },
          _vm._l(_vm.deliver_imgList, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "imgs" },
              [
                _c("img", {
                  staticClass: "itemImg",
                  attrs: { src: _vm.baseUrl + item, alt: "img" }
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "checkBtn check",
                    attrs: { size: "medium" },
                    on: {
                      click: function($event) {
                        return _vm.toView(1, item)
                      }
                    }
                  },
                  [_vm._v("查看")]
                )
              ],
              1
            )
          }),
          0
        )
      ]),
      _c("div", { staticClass: "take_delivery" }, [
        _c("div", { staticClass: "title flexBetween" }, [
          _c("p", { staticClass: "p1" }, [_vm._v("订单签收凭证")]),
          _vm.logisticsData.confirm_time
            ? _c("p", { staticClass: "p1" }, [
                _vm._v("签收时间：\n        "),
                _c("span", [_vm._v(_vm._s(_vm.logisticsData.confirm_time))])
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "flex imgDiv" },
          _vm._l(_vm.confirm_imgList, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "imgs" },
              [
                _c("img", {
                  staticClass: "itemImg",
                  attrs: { src: _vm.baseUrl + item, alt: "" }
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "checkBtn check",
                    attrs: { size: "medium" },
                    on: {
                      click: function($event) {
                        return _vm.toView(2, item)
                      }
                    }
                  },
                  [_vm._v("查看")]
                )
              ],
              1
            )
          }),
          0
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            staticClass: "dialogImg",
            attrs: { src: _vm.baseUrl + _vm.imgurl, alt: "photo" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }