var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "audit_dialog",
      attrs: {
        width: "500px",
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.dialogType != 3
        ? _c("p", { staticClass: "text-center tip" }, [
            _vm._v(
              _vm._s(
                _vm.dialogType == 1
                  ? "您确定要拒绝审核该商品吗？"
                  : "是否确认下列商品批量通过审核？"
              )
            )
          ])
        : _vm._e(),
      _vm.dialogType != 2
        ? [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "ruleForm",
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-width": "100px"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "原因", prop: "reason" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.reason,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "reason", $$v)
                          },
                          expression: "form.reason"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            label: "图片不符合规范",
                            value: "图片不符合规范"
                          }
                        }),
                        _c("el-option", {
                          attrs: {
                            label: "商品内容有敏感字符",
                            value: "商品内容有敏感字符"
                          }
                        }),
                        _c("el-option", {
                          attrs: { label: "其他原因", value: 3 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.reason == 3
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "其他原因" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 4,
                            placeholder:
                              "请输入备注原因信息！例如：图片不符合规范"
                          },
                          model: {
                            value: _vm.form.other,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "other", $$v)
                            },
                            expression: "form.other"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.dialogType == 3
        ? _c("p", { staticClass: "text-center tip" }, [
            _vm._v("是否确认下列商品批量拒绝审核？")
          ])
        : _vm._e(),
      _vm.goodsList.length > 0
        ? _c(
            "div",
            { staticClass: "goods_list" },
            _vm._l(_vm.goodsList, function(item) {
              return _c("div", { key: item.id }, [
                _c(
                  "div",
                  { staticClass: "flex align-center goods_info" },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "60px", height: "60px" },
                        attrs: { src: _vm.$getImgUrl(item.imgurl) }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error"
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "flex-1 text-left info" }, [
                      _c("p", [_vm._v(_vm._s(item.id))]),
                      _c("p", [_vm._v(_vm._s(item.title))])
                    ])
                  ],
                  1
                )
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.goodsList.length > 0
        ? _c(
            "p",
            {
              staticClass: "text-center color-999 tip",
              staticStyle: { "margin-bottom": "0" }
            },
            [_vm._v("共" + _vm._s(_vm.goodsList.length) + "个商品")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }