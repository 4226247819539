"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["id"],
  data: function data() {
    return {
      form: {
        name: '',
        idNumber: '',
        legalPerson: '',
        legalPersonId: '',
        platform_id: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      (0, _user.getmaYiPlatform)({
        member_id: this.id
      }).then(function (res) {
        if (Object.keys(res).length > 0) {
          _this.form = res;
        }
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      var data = this.form;
      data.member_id = this.id;

      if (data.name == '' || data.idNumber == '' || data.legalPerson == '' || data.legalPersonId == '') {
        this.$message.error('参数都必填');
        return false;
      }

      (0, _user.maYiPlatformCreate)(data).then(function (res) {
        _this2.getList();
      });
    }
  }
};
exports.default = _default;