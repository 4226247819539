var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "footer-page"
    },
    [
      _c("div", { staticClass: "flex align-center table-top-btns" }, [
        _c(
          "h5",
          { staticClass: "flex align-center" },
          [
            _c("svg-icon", {
              staticStyle: { "margin-right": "15px" },
              attrs: { "icon-class": "list", size: 15 }
            }),
            _vm._v("oss详情 - " + _vm._s(_vm.supplierInfo.shopname) + " ")
          ],
          1
        )
      ]),
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "formData" },
            _vm._l(_vm.formData, function(item, key) {
              return _c("div", { staticClass: "flex flex-wrap list_info" }, [
                _c(
                  "div",
                  { staticClass: "list_item" },
                  [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(key) + " ：")
                    ]),
                    _c("el-input", {
                      staticClass: "flex-1",
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.formData[key],
                        callback: function($$v) {
                          _vm.$set(_vm.formData, key, $$v)
                        },
                        expression: "formData[key]"
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            0
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-footer-btns" },
        [
          [
            _c(
              "el-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [_vm._v("返回")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.submit()
                  }
                }
              },
              [_vm._v("提交")]
            )
          ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }