var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.form, "label-width": "100px" } },
    [
      _vm._v("\n\t需要完成蚂蚁平台注册并开通蚂蚁链和蚂蚁合同\n"),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("页面说明")
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "公司名称" } },
        [
          _c("el-input", {
            attrs: { placeholder: "龙岩市鹿峰台网络科技有限公司" },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "营业执照号" } },
        [
          _c("el-input", {
            attrs: { placeholder: "91350802MAC1CNGDGE" },
            model: {
              value: _vm.form.idNumber,
              callback: function($$v) {
                _vm.$set(_vm.form, "idNumber", $$v)
              },
              expression: "form.idNumber"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "法人姓名" } },
        [
          _c("el-input", {
            attrs: { placeholder: "张三" },
            model: {
              value: _vm.form.legalPerson,
              callback: function($$v) {
                _vm.$set(_vm.form, "legalPerson", $$v)
              },
              expression: "form.legalPerson"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "法人身份证" } },
        [
          _c("el-input", {
            attrs: { placeholder: "350815548745487569" },
            model: {
              value: _vm.form.legalPersonId,
              callback: function($$v) {
                _vm.$set(_vm.form, "legalPersonId", $$v)
              },
              expression: "form.legalPersonId"
            }
          })
        ],
        1
      ),
      _vm.form.organization_id == null || _vm.form.organization_id == ""
        ? _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("立即创建")]
              ),
              _c("el-button", [_vm._v("取消")])
            ],
            1
          )
        : _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "supplierMayiMerchantApply",
                        params: { id: _vm.id }
                      })
                    }
                  }
                },
                [_vm._v("已入驻，请进入下一步")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }