"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _components = require("./components");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Layout',
  data: function data() {
    return {};
  },
  components: {
    Navbar: _components.Navbar,
    Sidebar: _components.Sidebar,
    AppMain: _components.AppMain,
    TopNav: _components.TopNav,
    PwdEdit: _components.PwdEdit
  },
  created: function created() {}
};
exports.default = _default;