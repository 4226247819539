var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-page" },
    [
      _c(
        "el-form",
        {
          ref: "viewForm",
          attrs: {
            model: _vm.viewForm,
            rules: _vm.rules,
            "label-width": "200px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "模板名称", prop: "sms_title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.viewForm.sms_title,
                  callback: function($$v) {
                    _vm.$set(_vm.viewForm, "sms_title", $$v)
                  },
                  expression: "viewForm.sms_title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "模板内容", prop: "default_template" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 5 },
                model: {
                  value: _vm.viewForm.default_template,
                  callback: function($$v) {
                    _vm.$set(_vm.viewForm, "default_template", $$v)
                  },
                  expression: "viewForm.default_template"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "触发规则", prop: "trigger_rule" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 5 },
                model: {
                  value: _vm.viewForm.trigger_rule,
                  callback: function($$v) {
                    _vm.$set(_vm.viewForm, "trigger_rule", $$v)
                  },
                  expression: "viewForm.trigger_rule"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收费规则", prop: "" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.viewForm.charge_rule,
                  callback: function($$v) {
                    _vm.$set(_vm.viewForm, "charge_rule", $$v)
                  },
                  expression: "viewForm.charge_rule"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "模板类型", prop: "sms_type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.viewForm.sms_type,
                    callback: function($$v) {
                      _vm.$set(_vm.viewForm, "sms_type", $$v)
                    },
                    expression: "viewForm.sms_type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("供应商提醒")
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("用户提醒")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$hasMethod("#edit")
        ? _c(
            "div",
            { staticClass: "page-footer-btns" },
            [
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.$router.back()
                      }
                    }
                  },
                  [_vm._v("返回")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.submit()
                      }
                    }
                  },
                  [_vm._v("提交")]
                )
              ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }