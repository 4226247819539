"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _finance = require("@/api/finance");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['leaseId'],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        refuse_reason: ""
      }
    };
  },
  methods: {
    close: function close() {
      this.dialogVisible = false;
      this.form = {
        refuse_reason: ""
      };
    },
    submit_refuse: function submit_refuse() {
      var _this = this;

      if (!this.form.refuse_reason) {
        this.$message.warning('拒绝原因不能为空');
        return false;
      }

      var obj = {
        lease_id: this.leaseId,
        refuse_reason: this.form.refuse_reason
      };
      (0, _finance.orderRefuseSettle)(obj).then(function (res) {
        _this.dialogVisible = false;
        _this.form = {
          refuse_reason: ""
        };

        _this.$parent.getList();
      });
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;