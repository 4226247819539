"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));

var _bus = _interopRequireDefault(require("../bus"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SidebarItem: _SidebarItem.default
  },
  data: function data() {
    return {
      hasRoutes: []
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['permission_routes'])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    variables: function variables() {
      return _variables2.default;
    }
  }),
  created: function created() {
    var _this = this;

    _bus.default.$on("setType", function (val) {
      _this.hasRoutes = val;
    });
  },
  beforeDestroy: function beforeDestroy() {
    _bus.default.$off('setType');
  }
};
exports.default = _default;