"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMenuList = getMenuList;
exports.addMenu = addMenu;
exports.editMenu = editMenu;
exports.setMenu = setMenu;
exports.delMenu = delMenu;
exports.groupList = groupList;
exports.addGroup = addGroup;
exports.setGroup = setGroup;
exports.delGroup = delGroup;
exports.editGroup = editGroup;
exports.userList = userList;
exports.getMemberList = getMemberList;
exports.getGroupList = getGroupList;
exports.setUser = setUser;
exports.delUser = delUser;
exports.addUser = addUser;
exports.editUser = editUser;
exports.getTemplateRuleList = getTemplateRuleList;
exports.getTemplateRuleInfo = getTemplateRuleInfo;
exports.editTemplateRule = editTemplateRule;
exports.delTemplateRule = delTemplateRule;
exports.upAliapp = upAliapp;
exports.getBlockIpConfig = getBlockIpConfig;
exports.saveBlockIpConfig = saveBlockIpConfig;
exports.getBlockIpList = getBlockIpList;
exports.clearBlockIp = clearBlockIp;
exports.getWhiteIpList = getWhiteIpList;
exports.getAllWhiteListInfo = getAllWhiteListInfo;
exports.saveWhiteIpList = saveWhiteIpList;
exports.getPayMentList = getPayMentList;
exports.editPayMent = editPayMent;
exports.delPayMent = delPayMent;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

/**
 * 菜单管理
 */
// 获取菜单列表
function getMenuList() {
  return _fetch.default.post("/Admin/Auth/menuList");
} // 添加菜单


function addMenu(params) {
  return _fetch.default.post("/Admin/Auth/addMenu", params, true);
} // 修改菜单


function editMenu(params) {
  return _fetch.default.post("/Admin/Auth/editMenu", params, true);
} // 禁用菜单


function setMenu(params) {
  return _fetch.default.post("/Admin/Auth/setMenu", params, true);
} // 删除菜单


function delMenu(id) {
  return _fetch.default.post("/Admin/Auth/delMenu", {
    id: id
  }, true);
}
/**
 * 权限管理
 */
// 角色列表


function groupList(params) {
  return _fetch.default.post("/Admin/Auth/groupList", params);
} // 添加角色


function addGroup(params) {
  return _fetch.default.post("/Admin/Auth/addGroup", params, true);
} // 启用/禁用角色


function setGroup(params) {
  return _fetch.default.post("/Admin/Auth/setGroup", params, true);
} // 删除角色


function delGroup(id) {
  return _fetch.default.post("/Admin/Auth/delGroup", {
    id: id
  }, true);
} // 编辑角色


function editGroup(params) {
  return _fetch.default.post("/Admin/Auth/editGroup", params, true);
}
/**
 * 账号管理
 */
// 获取用户列表


function userList(params) {
  return _fetch.default.post("/Admin/Auth/userList", params);
} // 获取商户列表


function getMemberList(params) {
  return _fetch.default.post("/Admin/Auth/getMemberList", params);
} // 获取选择角色列表


function getGroupList() {
  return _fetch.default.post("/Admin/Auth/getGroupList");
} // 批量操作设置状态


function setUser(params) {
  return _fetch.default.post("/Admin/Auth/setUser", params, true);
} // 批量删除


function delUser(ids) {
  return _fetch.default.post("/Admin/Auth/delUser", {
    ids: ids
  }, true);
} // 添加账户


function addUser(params) {
  return _fetch.default.post("/Admin/Auth/addUser", params, true);
} // 编辑账户


function editUser(params) {
  return _fetch.default.post("/Admin/Auth/editUser", params, true);
}
/**
 * 短信管理
 */
// 短信模板列表


function getTemplateRuleList(params) {
  return _fetch.default.post("/Admin/SmsConfig/getTemplateRuleList", params);
} // 短信模板详情


function getTemplateRuleInfo(id) {
  return _fetch.default.get("/Admin/SmsConfig/getTemplateRuleInfo", {
    id: id
  });
} // 编辑模板详情


function editTemplateRule(params) {
  return _fetch.default.post("/Admin/SmsConfig/editTemplateRule", params);
} // 删除模板


function delTemplateRule(id) {
  return _fetch.default.post("/Admin/SmsConfig/delTemplateRule", {
    id: id
  });
} // 更新支付宝小程序


function upAliapp(params) {
  return _fetch.default.post("/Admin/Version/add", params, true);
}
/**
 * 安全管理
 */
// 获取IP封禁配置


function getBlockIpConfig() {
  return _fetch.default.post("/Admin/Safe/getBlockIpConfig", {});
} // 保存IP封禁配置


function saveBlockIpConfig(params) {
  return _fetch.default.post("/Admin/Safe/saveBlockIpConfig", params, true);
} // 获取封禁IP列表


function getBlockIpList() {
  return _fetch.default.post("/Admin/Safe/getBlockIpList", {});
} // 清除封禁IP


function clearBlockIp() {
  return _fetch.default.post("/Admin/Safe/clearBlockIp", {}, true);
} // 获取IP白名单列表


function getWhiteIpList(params) {
  return _fetch.default.post("/Admin/Safe/getWhiteIpList", params);
} // 获取所有IP白名单列表


function getAllWhiteListInfo() {
  return _fetch.default.get("/Admin/Safe/getAllWhiteListInfo");
} // 保存IP白名单配置


function saveWhiteIpList(params) {
  return _fetch.default.post("/Admin/Safe/saveWhiteIpList", params, true);
}
/**
 * 支付通道管理
 */
// 获取支付通道列表


function getPayMentList(params) {
  return _fetch.default.post("/Admin/Auth/getPayMentList", params);
} // 编辑支付通道列表


function editPayMent(params) {
  return _fetch.default.post("/Admin/Auth/editPayMent", params, true);
} // 删除支付通道列表


function delPayMent(id) {
  return _fetch.default.post("/Admin/Auth/delPayMent", {
    id: id
  }, true);
}