var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "off_dialog",
      attrs: {
        width: "500px",
        title: "下架",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("p", { staticClass: "text-center tip" }, [
        _vm._v("您确定要下架处理该商品吗？")
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "ruleForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原因", prop: "reason" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.reason,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "reason", $$v)
                    },
                    expression: "form.reason"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { label: "图片不符合规范", value: "图片不符合规范" }
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "商品内容有敏感字符",
                      value: "商品内容有敏感字符"
                    }
                  }),
                  _c("el-option", { attrs: { label: "其他原因", value: 3 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.reason == 3,
                  expression: "form.reason==3"
                }
              ],
              attrs: { label: "其他原因" }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请输入备注原因信息！例如：图片不符合规范"
                },
                model: {
                  value: _vm.form.other,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "other", $$v)
                  },
                  expression: "form.other"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }