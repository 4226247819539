var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            "label-width": "45%",
            label:
              "本页面操作需要开通支付宝小程序相应配置,创建前先下载学习文档学习,确认前置条件是否满足,否则必然报错-->"
          }
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.download } },
            [_vm._v("下载")]
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "卡名字" } },
        [
          _c("el-input", {
            attrs: { placeholder: "爽爽租会员卡" },
            model: {
              value: _vm.form.card_show_name,
              callback: function($$v) {
                _vm.$set(_vm.form, "card_show_name", $$v)
              },
              expression: "form.card_show_name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "开卡信息" } },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.form.type,
                callback: function($$v) {
                  _vm.$set(_vm.form, "type", $$v)
                },
                expression: "form.type"
              }
            },
            [
              _c(
                "el-checkbox",
                { attrs: { label: "OPEN_FORM_FIELD_MOBILE", name: "type" } },
                [_vm._v("手机号")]
              ),
              _c(
                "el-checkbox",
                { attrs: { label: "OPEN_FORM_FIELD_GENDER", name: "type" } },
                [_vm._v("性别")]
              ),
              _c(
                "el-checkbox",
                { attrs: { label: "OPEN_FORM_FIELD_NAME", name: "type" } },
                [_vm._v("姓名")]
              ),
              _c(
                "el-checkbox",
                { attrs: { label: "OPEN_FORM_FIELD_BIRTHDAY", name: "type" } },
                [_vm._v("生日")]
              ),
              _c(
                "el-checkbox",
                { attrs: { label: "OPEN_FORM_FIELD_IDCARD", name: "type" } },
                [_vm._v("身份证")]
              ),
              _c(
                "el-checkbox",
                { attrs: { label: "OPEN_FORM_FIELD_EMAIL", name: "type" } },
                [_vm._v("邮箱")]
              ),
              _c(
                "el-checkbox",
                { attrs: { label: "OPEN_FORM_FIELD_ADDRESS", name: "type" } },
                [_vm._v("地址")]
              ),
              _c(
                "el-checkbox",
                { attrs: { label: "OPEN_FORM_FIELD_CITY", name: "type" } },
                [_vm._v("城市")]
              ),
              _c(
                "el-checkbox",
                {
                  attrs: { label: "OPEN_FORM_FIELD_IS_STUDENT", name: "type" }
                },
                [_vm._v("是否学生认证")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "卡模板ID" } },
        [
          _c("el-input", {
            staticStyle: { width: "20%" },
            attrs: { type: "", disabled: "" },
            model: {
              value: _vm.form.template_id,
              callback: function($$v) {
                _vm.$set(_vm.form, "template_id", $$v)
              },
              expression: "form.template_id"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "领卡链接" } },
        [
          _c("el-input", {
            staticStyle: { width: "50%" },
            attrs: {
              type: "textarea",
              disabled: "",
              autosize: { minRows: 2, maxRows: 4 }
            },
            model: {
              value: _vm.form.apply_card_url,
              callback: function($$v) {
                _vm.$set(_vm.form, "apply_card_url", $$v)
              },
              expression: "form.apply_card_url"
            }
          })
        ],
        1
      ),
      _vm.form.apply_card_url == ""
        ? _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("立即创建")]
              ),
              _c("el-button", [_vm._v("取消")])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }