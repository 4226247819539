var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { padding: "0" } }, [
    _c("div", { staticClass: "flex justify-center preview_page" }, [
      _c(
        "div",
        { staticClass: "ipX_wrap", staticStyle: { "margin-right": "100px" } },
        [_c("page-content", { attrs: { type: 1, pageData: _vm.pageData } })],
        1
      ),
      _c(
        "div",
        { staticClass: "ip7_wrap" },
        [_c("page-content", { attrs: { type: 2, pageData: _vm.pageData } })],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "fix-footer-btns", staticStyle: { "margin-top": "0" } },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function($event) {
                return _vm.$router.go(-1)
              }
            }
          },
          [_vm._v("返回")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }