"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _finance = require("@/api/finance");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["leaseId"],
  data: function data() {
    return {
      dialogVisible: false,
      which_period: "",
      orderData: []
    };
  },
  methods: {
    sureSettle: function sureSettle() {
      var _this = this;

      (0, _finance.orderSettle)(this.leaseId).then(function (res) {
        _this.dialogVisible = false;

        _this.$parent.getList();
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;

      if (val) {
        (0, _finance.getSettleData)(this.leaseId).then(function (res) {
          _this2.which_period = res.which_period;
          _this2.orderData = [{}];

          for (var i in res) {
            _this2.$set(_this2.orderData[0], i, res[i]);
          }
        }).catch(function (err) {
          _this2.orderData = [];
        });
      }
    }
  }
};
exports.default = _default;