"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _order = require("@/api/order");

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ["orderId"],
  data: function data() {
    return {
      dialogVisible: false,
      activeName: "supplier",
      infoData: {},
      dataList: [],
      innerVisible: false,
      innerTitle: '',
      folwForm: {
        logistics_name: '',
        logistics_no: ''
      },
      editCode: 'logistics_name',
      logisticsNames: []
    };
  },
  methods: {
    getSelect: function getSelect() {
      var _this = this;

      (0, _order.getLogisticAll)().then(function (res) {
        _this.logisticsNames = res || [];
      });
    },
    handleClick: function handleClick(tab, event) {
      this.getInfo();
    },
    editFlow: function editFlow(type) {
      this.innerVisible = true;
      this.innerTitle = type == 'name' ? '修改物流名称' : '修改物流单号';
      this.editCode = type == 'name' ? 'logistics_name' : 'logistics_no';
    },
    editFlowInfo: function editFlowInfo() {
      var _this2 = this;

      var _this$folwForm = this.folwForm,
          logistics_name = _this$folwForm.logistics_name,
          logistics_no = _this$folwForm.logistics_no;
      var params = {
        orderId: this.orderId,
        editCode: this.editCode,
        editValue: this.editCode == 'logistics_name' ? logistics_name : logistics_no
      };

      if (this.editCode == 'logistics_name') {
        if (!logistics_name) {
          this.$message.warning('物流名称不能为空');
          return false;
        }
      }

      if (this.editCode == 'logistics_no') {
        if (!logistics_no) {
          this.$message.warning('物流单号不能为空');
          return false;
        }

        if (logistics_no && !/^[a-zA-Z0-9]{1,20}$/.test(logistics_no)) {
          this.$message.warning('物流单号格式错误');
          return false;
        }
      }

      (0, _order.editOrderExpress)(params).then(function (res) {
        _this2.cancelEdit();

        _this2.getInfo();
      });
    },
    cancelEdit: function cancelEdit() {
      this.folwForm = {
        logistics_name: '',
        logistics_no: ''
      };
      this.innerVisible = false;
    },
    imgChange: function imgChange(val) {
      var _this3 = this;

      (0, _order.setOrderVoucher)({
        orderId: this.orderId,
        type: 1,
        images: val
      }).then(function (res) {
        _this3.infoData.images.push(val);

        _this3.$refs.cropUpload.sortList = _this3.infoData.images;
      });
    },
    proofChange: function proofChange(val) {
      var _this4 = this;

      (0, _order.setOrderVoucher)({
        orderId: this.orderId,
        type: 3,
        images: val
      }).then(function (res) {
        _this4.infoData.sign_proof.push(val);

        _this4.$refs.cropUpload1.sortList = _this4.infoData.sign_proof;
      });
    },
    getInfo: function getInfo() {
      var _this5 = this;

      (0, _order.getLogisticInfo)({
        orderId: this.orderId,
        type: this.activeName === "supplier" ? 1 : 2
      }).then(function (res) {
        _this5.infoData = res || {};
        _this5.infoData.images = _this5.infoData.images ? _this5.infoData.images.split(",") : [];
        _this5.infoData.sign_proof = _this5.infoData.sign_proof ? _this5.infoData.sign_proof.split(",") : [];
        _this5.dataList = _this5.infoData.info ? _this5.infoData.info.data : [];
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getInfo();
        this.getSelect();
      } else {
        this.activeName = "supplier";
      }
    }
  }
};
exports.default = _default;