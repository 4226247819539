"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _complaint = require("@/api/complaint");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["suggestId"],
  data: function data() {
    return {
      dialogVisible: false,
      formLabelWidth: "120px",
      form: {
        content: ""
      }
    };
  },
  methods: {
    close: function close() {
      this.dialogVisible = false;
      this.form = {
        content: ""
      };
    },
    submit: function submit() {
      var _this = this;

      if (!this.form.content) {
        this.$message.warning("请输入备注信息");
        return false;
      }

      (0, _complaint.intervene)({
        suggest_id: this.suggestId,
        content: this.form.content
      }).then(function (res) {
        _this.close();

        _this.$parent.getList();
      });
    }
  }
};
exports.default = _default;