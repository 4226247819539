"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _finance = require("@/api/finance");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["editItem"],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        id: 0,
        rate_name: "",
        rate_value: "",
        is_percentage: 0,
        rate_default: 0.00,
        is_member: 0
      },
      rules: {
        rate_name: [{
          required: true,
          message: '项目名称不能为空',
          trigger: 'blur'
        }],
        rate_value: [{
          required: true,
          message: '项目代号不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editItem ? "编辑扣费" : "添加扣费";
    },
    unit: function unit() {
      return this.form.is_percentage ? '%' : '元';
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        if (this.editItem) {
          this.form = (0, _objectSpread2.default)({}, this.editItem);
        } else {
          this.form = {
            id: 0,
            rate_name: "",
            rate_value: "",
            is_percentage: 0,
            is_member: 0,
            rate_default: 0.00
          };
        }
      } else {
        this.$refs['form'].clearValidate();
      }
    },
    'form.is_percentage': {
      handler: function handler(newValue, oldValue) {
        if (newValue) {
          this.calcRate();
        }
      },
      deep: true,
      // 是否深度监听
      immediate: true // 页面加载立即执行

    }
  },
  methods: {
    calcRate: function calcRate() {
      if (this.form.is_percentage) {
        if (this.form.rate_default < 1 && this.form.rate_default > 0) {
          this.form.rate_default = this.form.rate_default * 100;
        }
      }
    },
    submitCalcRate: function submitCalcRate() {
      var form = JSON.parse(JSON.stringify(this.form));

      if (form.is_percentage) {
        if (form.rate_default > 1) {
          form.rate_default = form.rate_default / 100;
        }
      }

      return form;
    },
    submit: function submit() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var form = _this.submitCalcRate();

          (0, _finance.editRate)((0, _objectSpread2.default)({}, form)).then(function (res) {
            _this.dialogVisible = false;

            _this.$emit("getList");
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    }
  }
};
exports.default = _default;