var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-title-page goods_list" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "flex-1 nav_tabs",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: {
              label: "已上架商品(" + _vm.count.status_put + ")",
              name: "1"
            }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "已下架商品(" + _vm.count.status_off + ")",
              name: "2"
            }
          })
        ],
        1
      ),
      _c("el-divider", { staticClass: "middle_divider" }),
      _c(
        "div",
        { staticClass: "child_page" },
        [
          _c(
            "div",
            { staticClass: "common_form_search" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  attrs: { inline: true, model: _vm.searchForm }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        model: {
                          value: _vm.searchForm.goods_name,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "goods_name", $$v)
                          },
                          expression: "searchForm.goods_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "供应商" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入供应商", clearable: "" },
                        model: {
                          value: _vm.searchForm.shop_name,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "shop_name", $$v)
                          },
                          expression: "searchForm.shop_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品品类" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择商品品类",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.cate_id,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "cate_id", $$v)
                            },
                            expression: "searchForm.cate_id"
                          }
                        },
                        _vm._l(_vm.categoryList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "租赁方式" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.searchForm.rent_type,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "rent_type", $$v)
                            },
                            expression: "searchForm.rent_type"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "随租随还", value: 3 }
                          }),
                          _c("el-option", {
                            attrs: { label: "固定租期", value: 1 }
                          }),
                          _c("el-option", {
                            attrs: { label: "租完即送", value: 2 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [_c("my-date-picker", { ref: "myDatePicker" })],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.search()
                            }
                          }
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.reset()
                            }
                          }
                        },
                        [_vm._v("重置")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticStyle: { padding: "0 30px" } },
            [
              _c("div", { staticClass: "flex align-center table-top-btns" }, [
                _c(
                  "h5",
                  { staticClass: "flex align-center" },
                  [
                    _c("svg-icon", {
                      staticStyle: { "margin-right": "15px" },
                      attrs: { "icon-class": "list", size: 15 }
                    }),
                    _vm._v("数据列表\n          ")
                  ],
                  1
                )
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tableData.length > -1,
                      expression: "tableData.length > -1"
                    }
                  ],
                  attrs: { data: _vm.tableData, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", width: "150", label: "租赁平台" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "blue-font",
                                on: {
                                  click: function($event) {
                                    _vm.$func.openBlank(
                                      _vm.$router.resolve({
                                        path:
                                          "/supplier/view/" + scope.row.shopid
                                      })
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(_vm._s(scope.row.shopname) + " "),
                                _c("p", [_vm._v(_vm._s(scope.row.shopmobile))])
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "商品信息", width: "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex align-center goods_info" },
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticStyle: {
                                      width: "60px",
                                      height: "60px"
                                    },
                                    attrs: {
                                      src: _vm.$getImgUrl(scope.row.imgurl)
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture-outline"
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex-1 text-left info" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "activity" },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.id) +
                                            "\n                    "
                                        ),
                                        _vm._l(scope.row.activity, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              staticClass:
                                                "inline-block v-middle"
                                            },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    width: "160",
                                                    trigger: "hover",
                                                    content: item.activity_title
                                                  }
                                                },
                                                [
                                                  item.activity_img
                                                    ? _c("img", {
                                                        staticClass:
                                                          "w-100 h-100",
                                                        attrs: {
                                                          slot: "reference",
                                                          src:
                                                            _vm.$globalObj
                                                              .imgApiUrl +
                                                            item.activity_img,
                                                          alt:
                                                            item.activity_title
                                                        },
                                                        slot: "reference"
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    ),
                                    _c("p", [_vm._v(_vm._s(scope.row.title))])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      width: "100",
                      prop: "pact_type",
                      label: "租赁方式"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      width: "100",
                      prop: "rent_type",
                      label: "租期类型"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "库存", prop: "stock" }
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "cate_name", label: "品类" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "create_time",
                      label: "创建时间",
                      width: "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "shopname",
                      label: "供应商"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "status_name",
                      label: "状态"
                    }
                  }),
                  _vm.activeName == 2
                    ? _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "check_reason",
                          label: "原因"
                        }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      align: "center",
                      label: "操作",
                      width: "340px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.$hasMethod("#edit")
                              ? _c(
                                  "el-dropdown",
                                  {
                                    staticStyle: { "margin-right": "10px" },
                                    on: {
                                      command: function(command) {
                                        return _vm.handleCommand(
                                          command,
                                          scope.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary", size: "mini" }
                                      },
                                      [
                                        _vm._v("编辑"),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "1" } },
                                          [_vm._v("商品信息")]
                                        ),
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "2" } },
                                          [_vm._v("套餐信息")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$hasMethod("#enable")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "warning", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeStatus(
                                          scope.row.id,
                                          scope.row.status
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.status == 1 ? "下架" : "上架"
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.$hasMethod("#set") && _vm.activeName == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setActivity(scope.row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("设置活动")]
                                )
                              : _vm._e(),
                            _vm.$hasMethod("#view") || _vm.activeName == 1
                              ? _c(
                                  "el-dropdown",
                                  {
                                    on: {
                                      command: function(command) {
                                        return _vm.handleMore(
                                          command,
                                          scope.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-dropdown-link blue-font",
                                        staticStyle: { "margin-left": "10px" }
                                      },
                                      [
                                        _vm._v("\n                  更多操作"),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "1" } },
                                          [_vm._v("查看")]
                                        ),
                                        _vm.activeName == 1
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "2" } },
                                              [_vm._v("分享链接")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("custom-page", {
                ref: "customPage",
                attrs: { total: _vm.total },
                on: { getList: _vm.getList }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("off-dialog", { ref: "offDialog", on: { getList: _vm.getList } }),
      _c("set-dialog", { ref: "setDialog", on: { getList: _vm.getList } }),
      _c("share-dialog", { ref: "shareDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }