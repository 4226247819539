var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看明细",
            visible: _vm.dialogVisible,
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticClass: "orderTable",
              attrs: { data: _vm.orderData, border: "" }
            },
            [
              _c(
                "el-table-column",
                { attrs: { label: "订单信息", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      property: "trade_no",
                      label: "订单编号",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "pay_type",
                      label: "支付方式",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "租期", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.month_total
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.month_total) + "期")
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "租期金额", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.rent_total != 0
                              ? _c("span", [
                                  _vm._v("￥" + _vm._s(scope.row.rent_total))
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "用户已付金额", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.paid_total_rent != 0
                              ? _c("span", [
                                  _vm._v(
                                    "￥" + _vm._s(scope.row.paid_total_rent)
                                  )
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "is_risk",
                      label: "是否风控",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "financing_type",
                      label: "是否上链融资",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "保险费用", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.insure_fee != 0
                              ? _c("span", [
                                  _vm._v("￥" + _vm._s(scope.row.insure_fee))
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "退款信息", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "退款金额", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.refund_amount != 0
                              ? _c("span", [
                                  _vm._v("￥" + _vm._s(scope.row.refund_amount))
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "total_service_fee",
                      label: "应付总手续费",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.total_service_fee != 0
                              ? _c("span", [
                                  _vm._v(
                                    "￥" + _vm._s(scope.row.total_service_fee)
                                  )
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "paid_service_fee",
                      label: "已付手续费",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.paid_service_fee != 0
                              ? _c("span", [
                                  _vm._v(
                                    "￥" + _vm._s(scope.row.paid_service_fee)
                                  )
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "deduct_service_fee",
                      label: "应补足手续费",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.deduct_service_fee != 0
                              ? _c("span", [
                                  _vm._v(
                                    "￥" + _vm._s(scope.row.deduct_service_fee)
                                  )
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "deduct_refund_amount",
                      label: "应补足退款金额",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.deduct_refund_amount != 0
                              ? _c("span", [
                                  _vm._v(
                                    "￥" +
                                      _vm._s(scope.row.deduct_refund_amount)
                                  )
                                ])
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "需补足总额", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.deduct_amount != 0
                              ? _c(
                                  "span",
                                  { staticClass: "red-font bold-font" },
                                  [
                                    _vm._v(
                                      "￥" + _vm._s(scope.row.deduct_amount)
                                    )
                                  ]
                                )
                              : _c("span", [_vm._v("--")])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "centerDiv", on: { click: _vm.clickShow } },
            [
              _c("p", [_vm._v("展开查看明细")]),
              _c("img", {
                attrs: {
                  src: require("../../../assets/images/pullDown.png"),
                  alt: ""
                }
              })
            ]
          ),
          _vm.isShow
            ? _c(
                "el-table",
                {
                  staticClass: "rateTable",
                  attrs: {
                    data: _vm.rateData,
                    border: "",
                    "show-summary": "",
                    "summary-method": _vm.getSummaries
                  }
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { label: "账单支付明细", align: "center" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "期数", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      "第" +
                                        _vm._s(scope.row.which_period) +
                                        "期"
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3839855794
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          property: "pay_type",
                          label: "支付方式",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          property: "settle_status_name",
                          label: "结算状态",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          property: "pay_status",
                          label: "支付状态",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "租期金额", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.rent != 0
                                    ? _c("span", [
                                        _vm._v("￥" + _vm._s(scope.row.rent))
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1419038739
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "实付金额", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.rent != 0
                                    ? _c("span", [
                                        _vm._v("￥" + _vm._s(scope.row.rent))
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1419038739
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { label: "手续费率", align: "center" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "合同", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.smartcontract_amount != 0
                                    ? _c("span", [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(
                                              scope.row.smartcontract_amount
                                            )
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1055243603
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "实名认证", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.realname_amount != 0
                                    ? _c("span", [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(scope.row.realname_amount)
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1123671123
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "代扣", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.withhold_rate != 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.withhold_rate) + "%"
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1378682771
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "存证", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.file_rate != 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.file_rate) + "%"
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1860822259
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "平台手续费", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.platform_rate != 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.platform_rate) + "%"
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          897405523
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "保险", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.insure_rate != 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.insure_rate) + "%"
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          933824147
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "总费率",
                          align: "center",
                          width: "120px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.which_period == 1 &&
                                  scope.row.fixed_amount != 0
                                    ? _c("span", [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(scope.row.fixed_amount) +
                                            "+"
                                        )
                                      ])
                                    : _vm._e(),
                                  scope.row.shop_rate != 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.shop_rate) + "%"
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4182599402
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { label: "结算手续费信息", align: "center" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "结算金额", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.settle_amount != 0
                                    ? _c("span", [
                                        _vm._v(
                                          "￥" + _vm._s(scope.row.settle_amount)
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2777931411
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "应付手续费",
                          align: "center",
                          property: "payable_fee"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.payable_fee != 0
                                    ? _c("span", [
                                        _vm._v(
                                          "￥" + _vm._s(scope.row.payable_fee)
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1222607379
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "已付手续费",
                          align: "center",
                          property: "paid_fee"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.paid_fee != 0
                                    ? _c("span", [
                                        _vm._v(
                                          "￥" + _vm._s(scope.row.paid_fee)
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2642081459
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { label: "退款信息", align: "center" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "退款金额",
                          align: "center",
                          width: "120px",
                          property: "refund_amount"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.refund_amount != 0
                                    ? _c("span", [
                                        _vm._v(
                                          "￥" + _vm._s(scope.row.refund_amount)
                                        )
                                      ])
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4117410131
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "应补足手续费",
                          align: "center",
                          width: "120px",
                          property: "deduct_fee"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.deduct_fee != 0
                                    ? _c(
                                        "span",
                                        { staticClass: "red-font bold-font" },
                                        [
                                          _vm._v(
                                            "￥" + _vm._s(scope.row.deduct_fee)
                                          )
                                        ]
                                      )
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1029621411
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "应补足退款金额",
                          align: "center",
                          width: "120px",
                          property: "deduct_refund_amount"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.deduct_refund_amount != 0
                                    ? _c(
                                        "span",
                                        { staticClass: "red-font bold-font" },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(
                                                scope.row.deduct_refund_amount
                                              )
                                          )
                                        ]
                                      )
                                    : _c("span", [_vm._v("--")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1330815811
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }