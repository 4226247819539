var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: {
              maxlength: 200,
              disabled: true,
              placeholder: "目前仅通过供应商列表添加平台",
              clearable: ""
            },
            model: {
              value: _vm.input,
              callback: function($$v) {
                _vm.input = $$v
              },
              expression: "input"
            }
          }),
          _c(
            "el-button",
            { attrs: { disabled: true }, on: { click: _vm.createPlatform } },
            [_vm._v("创建平台")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "blk" },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "", clearable: true, placeholder: "请选择" },
              on: { change: _vm.changPlatform },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.platformStatisList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.value.length },
              on: {
                click: function($event) {
                  return _vm.insertOrderInfo(4)
                }
              }
            },
            [_vm._v("插入含有芝麻风控数据")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "blk" },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "", clearable: true, placeholder: "请选择" },
              on: { change: _vm.changPlatform },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.platformStatisList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.value.length },
              on: {
                click: function($event) {
                  return _vm.insertOrderInfo(1)
                }
              }
            },
            [_vm._v("插入含有布尔风控数据")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "blk" },
        [
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: true,
                placeholder: "请选择要计算的平台"
              },
              on: { change: _vm.changPlatform },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.platformStatisList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.value.length },
              on: { click: _vm.useMultiTable }
            },
            [_vm._v("使用其他平台数据")]
          )
        ],
        1
      ),
      _vm.showDetail
        ? _c(
            "div",
            { staticClass: "blk" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", [_vm._v("nd1:有数据浓度，nd2:无数据浓度")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right", padding: "3px 0" },
                          attrs: { type: "text" }
                        },
                        [_vm._v("操作按钮")]
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.multiDataList, function(item, index) {
                    return [
                      _c("div", { key: index, staticClass: "text item" }, [
                        _c("p", [_vm._v("字段: " + _vm._s(item.field) + ": ")]),
                        _c("p", [_vm._v("数量: " + _vm._s(item.count) + ": ")]),
                        _c("p", [
                          _vm._v("总数: " + _vm._s(item.total_count) + ": ")
                        ]),
                        _c("p", [
                          _vm._v("逾期数: " + _vm._s(item.overdue_count) + ": ")
                        ]),
                        _c("p", [
                          _vm._v(
                            "浓度: " +
                              _vm._s(item.value) +
                              "（nd1: " +
                              _vm._s(item.nd1) +
                              ", nd2: " +
                              _vm._s(item.nd2) +
                              "）"
                          )
                        ])
                      ])
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c("el-button", { on: { click: _vm.decryptPlatformID } }, [
        _vm._v("后端解密平台ID")
      ]),
      _c(
        "div",
        { staticClass: "blk" },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "", clearable: true, placeholder: "请选择" },
              on: { change: _vm.changPlatform },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.platformStatisList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.value.length },
              on: { click: _vm.updateOrderStatus }
            },
            [_vm._v(" 更新订单状态")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "blk" },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "", clearable: true, placeholder: "请选择" },
              on: { change: _vm.changPlatform },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.platformStatisList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.value.length },
              on: { click: _vm.execCronTask }
            },
            [_vm._v(" 执行定时计划任务")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }