var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category_page footer-page" }, [
    _c(
      "div",
      { staticClass: "top_tabs" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          _vm._l(_vm.tabList, function(item) {
            return _c("el-tab-pane", {
              key: item.id,
              attrs: { label: item.cate_name, name: item.id + "" }
            })
          }),
          1
        ),
        _vm.tabList.length > 0 && _vm.$hasMethod("#add")
          ? _c(
              "el-link",
              {
                attrs: {
                  href: "/webConfig/category",
                  underline: false,
                  type: "primary"
                }
              },
              [_vm._v("添加品类")]
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "flex tab_content" }, [
      _c(
        "div",
        { staticClass: "img_wrapper" },
        [
          _c(
            "el-image",
            { attrs: { src: require("../../../assets/images/demo_cate.jpg") } },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "error" },
                  slot: "error"
                },
                [_c("i", { staticClass: "el-icon-picture-outline" })]
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "config_wrapper" }, [
        !_vm.$hasMethod("#edit")
          ? _c("div", { staticClass: "mask" })
          : _vm._e(),
        _c("h5", [_vm._v(_vm._s(_vm.configTitle))]),
        _c(
          "div",
          { staticClass: "main" },
          [
            _c(
              "div",
              { staticClass: "warn-box" },
              [
                _c("svg-icon", {
                  staticStyle: { "margin-right": "17px" },
                  attrs: { "icon-class": "warn", size: 20 }
                }),
                _vm._m(0)
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pos_list" },
              _vm._l(_vm.dataList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "pos_item" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-between-center title" },
                      [
                        _c("span", [_vm._v("推荐位" + _vm._s(index + 1))]),
                        _vm.$hasMethod("#edit")
                          ? _c("p", [
                              _c(
                                "span",
                                {
                                  staticClass: "pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.upSortPos(index)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-top" })]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.downSortPos(index)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-bottom" })]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.deletePos(index)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c("goods-form", {
                      attrs: {
                        "show-tool": _vm.$hasMethod("#edit"),
                        "form-data": item,
                        "show-checked-tool": true,
                        "show-subtitle": false
                      }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _vm.dataList.length > 0 &&
            _vm.dataList.length < 4 &&
            _vm.$hasMethod("#edit")
              ? _c("el-divider")
              : _vm._e(),
            _vm.dataList.length < 4 && _vm.$hasMethod("#edit")
              ? _c(
                  "div",
                  { staticClass: "add-div", on: { click: _vm.addRecommend } },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v(
                      "添加推荐位(" +
                        _vm._s(_vm.dataList.length) +
                        "/4)\n        "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]),
    _vm.$hasMethod("#edit")
      ? _c(
          "div",
          { staticClass: "page-footer-btns" },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.reset } },
              [_vm._v("重置")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.submit }
              },
              [_vm._v("保存")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("商品图片要求PNG格式透明底图片；图片尺寸要求500×500px；")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }