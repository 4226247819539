var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "code1", label: "新密码" } },
        [
          _c("el-input", {
            attrs: {
              minlength: "6",
              placeholder: "请输入密码",
              autocomplete: "off",
              "show-password": ""
            },
            model: {
              value: _vm.form.code1,
              callback: function($$v) {
                _vm.$set(_vm.form, "code1", $$v)
              },
              expression: "form.code1"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "code2", label: "重复密码" } },
        [
          _c("el-input", {
            attrs: {
              minlength: "6",
              placeholder: "请再次输入密码",
              autocomplete: "off",
              "show-password": ""
            },
            model: {
              value: _vm.form.code2,
              callback: function($$v) {
                _vm.$set(_vm.form, "code2", $$v)
              },
              expression: "form.code2"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("确认修改")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }