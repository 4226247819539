"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _lease = require("@/api/lease");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _paramDialog = _interopRequireDefault(require("./child/paramDialog.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    paramDialog: _paramDialog.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0,
      searchForm: {
        orderNo: "",
        status: ""
      },
      spanArr: [],
      // 一个空的数组，用于存放每一行记录的合并数
      pos: 0,
      // spanArr 的索引
      dialogVisible: false,
      dialogType: 1,
      requestInfo: {},
      responseInfo: {}
    };
  },
  methods: {
    search: function search() {
      // const pickerVal = this.$refs.myDatePicker.getVal();
      // if (pickerVal !== 'error') {
      //   this.searchForm.leaseTime = pickerVal;
      //   this.$refs.customPage.page = 1;
      //   this.getList();
      // }
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = Object.assign({});
      this.getList();
    },
    getParams: function getParams(params) {
      this.dialogType = 2;
      this.dialogVisible = true;
      var info = JSON.parse(params);
      this.requestInfo = info.request ? JSON.parse(info.request.biz_content) : {};
      this.responseInfo = info.response ? info.response : {};
    },
    closeDialog: function closeDialog() {
      this.dialogVisible = false;
    },
    arraySpanMethod: function arraySpanMethod(_ref) {// console.log("row", row.leaseList, column, rowIndex, columnIndex );
      // if (columnIndex == 0 || columnIndex == 1 || columnIndex == 6 || columnIndex == 7 || columnIndex == 8 || columnIndex == 9) {
      // console.log("row",this.spanArr)
      // const _row = this.spanArr[rowIndex]
      // const _col = _row > 0 ? 1 : 0
      // return {
      //   rowspan: _row,
      //   colspan: _col
      // }
      // }

      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;
    },
    getSpanArr: function getSpanArr(data) {// data.sort((a, b) => {
      //   return a.which_period > b.which_period ? 1 : -1
      // })
      // this.spanArr = []
      // for (let i = 0; i < data.length; i++) {
      //   if (i === 0) {
      //     this.spanArr.push(1)
      //     this.pos = 0
      //   } else {
      //     if (data[i].trade_no === data[i - 1].trade_no) {
      //       // 如果trade_no相等就累加，并且push 0
      //       this.spanArr[this.pos] += 1
      //       this.spanArr.push(0)
      //     } else {
      //       this.spanArr.push(1)
      //       this.pos = i
      //     }
      //   }
      // }
    },
    // 获取列表
    getList: function getList() {
      var _this = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _lease.getPromiseList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data;
        _this.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;