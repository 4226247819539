"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.array.find");

var _xcx = require("../../api/xcx");

var _compForm = _interopRequireDefault(require("./components/compForm"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    compForm: _compForm.default
  },
  computed: {
    sectionList: function sectionList() {
      switch (this.pageType) {
        case '办公设备':
          return [{
            title: "商品（1+3）推荐位",
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '电脑平板':
          return [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '5.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '7.jpg',
            num: 6
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '健康理疗':
          return [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '品牌手机':
          return [{
            title: "商品（1+4）推荐位",
            key: 'first_level',
            imgName: '6.jpg',
            num: 5
          }, {
            title: "商品（1+4）推荐位",
            key: 'second_level',
            imgName: '6.jpg',
            num: 5
          }];

        case '能源设备':
          return [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '开店赋能':
          return [{
            title: "商品（1+3）推荐位",
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '数码摄影':
          return [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '5.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '7.jpg',
            num: 6
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '游戏设备':
          return [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '智能家居':
          return [{
            title: "商品（1+3）推荐位",
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '花呗专区':
          return [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '热租爆品':
          return [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '短租精选':
          return [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        case '租完即送':
          return [{
            title: "商品（1+3）推荐位",
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];

        default:
          return [];
      }
    }
  },
  data: function data() {
    return {
      activeNum: 1,
      pageId: '',
      configData: {},
      pageType: '办公设备',
      page_title: '',
      page_id: 0
    };
  },
  watch: {
    activeNum: function activeNum(val) {
      if (val) {
        this.setData(val);
      }
    }
  },
  mounted: function mounted() {
    this.pageType = this.$route.meta.title;
    this.page_title = this.$route.meta.title;

    switch (this.page_title) {
      case '办公设备':
        this.page_id = 1;
        break;

      case '电脑平板':
        this.page_id = 2;
        break;

      case '健康理疗':
        this.page_id = 3;
        break;

      case '品牌手机':
        this.page_id = 4;
        break;

      case '能源设备':
        this.page_id = 5;
        break;

      case '开店赋能':
        this.page_id = 6;
        break;

      case '数码摄影':
        this.page_id = 7;
        break;

      case '游戏设备':
        this.page_id = 8;
        break;

      case '智能家居':
        this.page_id = 9;
        break;

      case '花呗专区':
        this.page_id = 10;
        break;

      case '热租爆品':
        this.page_id = 11;
        break;

      case '短租精选':
        this.page_id = 12;
        break;

      case '租完即送':
        this.page_id = 13;
        break;
    }

    this.getData();
  },
  methods: {
    submit: function submit() {
      (0, _xcx.release)(this.pageId).then(function (res) {});
    },
    setData: function setData(pos) {
      var _this = this;

      var key = this.sectionList[pos - 1].key;
      var data = {
        main_title: this.configData[key].main_title || '',
        goods: this.configData[key].goods || [],
        background_image: this.configData[key].background_image || []
      };
      this.$nextTick(function () {
        _this.$refs.compForm.formData = data;
      });
    },
    getData: function getData() {
      var _this2 = this;

      (0, _xcx.getActivityPage)().then(function (res) {
        var result = res.find(function (item) {
          return item.title === _this2.pageType;
        });

        if (result) {
          _this2.pageId = result.id;
          _this2.configData = result.details ? JSON.parse(result.details) : {
            first_level: {
              goods: [],
              main_title: ''
            },
            second_level: {
              goods: [],
              main_title: ''
            },
            third_level: {
              goods: [],
              main_title: ''
            }
          };

          _this2.setData(_this2.activeNum);
        }
      });
    },
    save: function save() {
      var _this3 = this;

      var params = this.$refs.compForm.save();

      if (params) {
        (0, _xcx.editActivityPage)((0, _objectSpread2.default)({
          id: this.page_id,
          page_title: this.page_title
        }, params)).then(function (res) {
          _this3.getData();
        });
      }
    },
    preview: function preview() {},
    release: function release() {}
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.activeNum = 1;
    this.pageType = to.$route.meta.title;
    this.getData();
  }
};
exports.default = _default;