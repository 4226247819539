"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  props: ['orderId'],
  data: function data() {
    return {
      dialogVisible: false,
      tableData: [],
      total: 0
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _order.getOrderRemark)({
        page: page,
        pageSize: pageSize,
        orderId: this.orderId
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total || 0;
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;

      if (val) {
        this.$nextTick(function () {
          _this2.getList();
        });
      }
    }
  }
};
exports.default = _default;