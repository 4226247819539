"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _audit = require("@/api/audit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['goodsId'],
  data: function data() {
    return {
      goodsInfo: {},
      baseMeta: [{
        label: "商品品类",
        name: 'cate_name'
      }, {
        label: "商品名称",
        name: 'title'
      }, {
        label: "推广关键字",
        name: 'spread_title'
      }, {
        label: "商品属性",
        name: 'attribute'
      }, {
        label: "新旧",
        name: 'new_grade'
      }, {
        label: "租赁标签",
        name: 'rent_label'
      }, {
        label: "商品封面",
        name: 'imgurl'
      }, {
        label: "商品图片",
        name: 'carousel_images'
      }, {
        label: "商品详情",
        name: 'goods_content'
      }, {
        label: "产品合同类型",
        name: 'pact_type'
      }, {
        label: "服务城市",
        name: 'service_province'
      }, {
        label: "租赁地址",
        name: 'service_address'
      }, {
        label: "运费",
        name: 'transfer_cost'
      }, {
        label: "配送方式",
        name: 'convey_type'
      }],
      mealMeta: [{
        label: "套餐名字",
        name: 'title'
      }, {
        label: "租期设置",
        name: 'lease_set'
      }, {
        label: "花呗分期",
        name: 'is_huabei'
      }, {
        label: "花呗设置",
        name: 'huabei_data'
      }, {
        label: "数量",
        name: 'quantity'
      }, {
        label: "押金",
        name: 'foregift'
      }, {
        label: "是否买断",
        name: 'is_buy_out'
      }, {
        label: "销售价格",
        name: 'price'
      }]
    };
  },
  methods: {
    getData: function getData() {
      var _this = this;

      (0, _audit.getGoodsInfo)(this.goodsId).then(function (res) {
        _this.goodsInfo = res || {};
        _this.goodsInfo.carousel_images = _this.goodsInfo.carousel_images.split(',');
      });
    }
  },
  mounted: function mounted() {
    document.getElementsByClassName('app-main')[0].scrollTop = 0;
    this.getData();
  }
};
exports.default = _default;