"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _finance = require("@/api/finance");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['orderId', 'whichPeriod'],
  data: function data() {
    return {
      form: {
        remark: ''
      },
      rules: {
        remark: [{
          required: true,
          message: '备注不能为空',
          trigger: 'blur'
        }]
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          (0, _finance.setOrderRemark)({
            remark: '第' + _this.whichPeriod + '期-' + _this.form.remark,
            orderId: _this.orderId
          }).then(function (res) {
            _this.dialogVisible = false;

            _this.$emit('getList');
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (!val) {
        this.form.remark = '';
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};
exports.default = _default;