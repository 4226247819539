var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入店铺名称", clearable: "" },
                    model: {
                      value: _vm.searchForm.shopname,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "shopname", $$v)
                      },
                      expression: "searchForm.shopname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入账号", clearable: "" },
                    model: {
                      value: _vm.searchForm.account_num,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "account_num", $$v)
                      },
                      expression: "searchForm.account_num"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部", clearable: "" },
                      model: {
                        value: _vm.searchForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "status", $$v)
                        },
                        expression: "searchForm.status"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "已审核", value: 1 } }),
                      _c("el-option", { attrs: { label: "待审核", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider"),
      _c("div", { staticClass: "flex align-center table-top-btns" }, [
        _c(
          "h5",
          { staticClass: "flex align-center" },
          [
            _c("svg-icon", {
              staticStyle: { "margin-right": "15px" },
              attrs: { "icon-class": "list", size: 15 }
            }),
            _vm._v("数据列表 ")
          ],
          1
        )
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length > -1,
              expression: "tableData.length > -1"
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", label: "ID", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "member_id", label: "member_id" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "shopname", label: "店铺名称" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "account_num", label: "账号" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "店铺头像" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "50px", height: "50px" },
                        attrs: {
                          fit: "fill",
                          src: _vm.$getImgUrl(scope.row.photo)
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error"
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "people", label: "联系人姓名" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "contact_tel",
              width: "120",
              label: "联系人手机号"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "category_name", label: "经营项目" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "faptype_name", label: "发票类型" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "status", label: "审核状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            scope.row.status == 1
                              ? "success"
                              : scope.row.status == 2
                              ? "warning"
                              : "danger"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.status == 1
                              ? "已审核"
                              : scope.row.status == 2
                              ? "待审核"
                              : "拒绝"
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "240"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { staticClass: "btn-box" },
                      [
                        _vm.$hasMethod("#view")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push({
                                      name: "supplierView",
                                      params: { viewId: scope.row.id }
                                    })
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "info", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleIp(scope.row)
                              }
                            }
                          },
                          [_vm._v("IP管理")]
                        ),
                        _vm.$hasMethod("#config")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push({
                                      name: "supplierConfig",
                                      params: { id: scope.row.member_id }
                                    })
                                  }
                                }
                              },
                              [_vm._v("配置")]
                            )
                          : _vm._e(),
                        _vm.$hasMethod("#enable")
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type:
                                    scope.row.endisable == 1
                                      ? "danger"
                                      : "warning",
                                  size: "mini"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.enable(
                                      scope.row.id,
                                      scope.row.endisable
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.endisable == 1 ? "禁用" : "启用"
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.$hasMethod("#login")
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  plain: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.loginSupplier(scope.row)
                                  }
                                }
                              },
                              [_vm._v("登录供应商")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.$hasMethod("#edit")
                      ? _c(
                          "el-dropdown",
                          {
                            on: {
                              command: function(command) {
                                return _vm.handleMore(command, scope.row)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "el-dropdown-link blue-font pointer",
                                staticStyle: { "margin-left": "10px" }
                              },
                              [
                                _vm._v(" 更多操作"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right"
                                })
                              ]
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.$hasMethod("#config")
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          type: "success",
                                          size: "mini"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.upAliapp(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("更新小程序")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "supplierOpenVip",
                                          params: { id: scope.row.member_id }
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v("小程序用户vip")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "supplierCopyGoods",
                                          params: { id: scope.row.member_id }
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v("复制商品")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "supplierMayiPlatAdd",
                                          params: { id: scope.row.member_id }
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v("蚂蚁平台方注册")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "supplierChangePwd",
                                          params: { id: scope.row.member_id }
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v("修改密码")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.addSupplierToPlatform(
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("添加供应商到浓度统计平台")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "supplierChangePayPwd",
                                          params: { id: scope.row.member_id }
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v("修改支付密码")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "supplierChangeOss",
                                          params: {
                                            id: scope.row.id,
                                            memberId: scope.row.member_id
                                          }
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v("oss迁移")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("ipmanagerVue", { ref: "ip", attrs: { "edit-item": _vm.editItem } }),
      _c("uploadApp", { ref: "uploadApp", attrs: { memberId: _vm.memberId } }),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }