var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "拒绝", visible: _vm.dialogVisible, width: "500px" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原因：", "label-width": "120px" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autocomplete: "off",
                  rows: 3,
                  placeholder: "请输入拒绝结算原因"
                },
                model: {
                  value: _vm.form.refuse_reason,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "refuse_reason", $$v)
                  },
                  expression: "form.refuse_reason"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit_refuse } },
            [_vm._v("确定拒绝")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }