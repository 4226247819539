"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _info = require("@/api/info");

var _addCategory = _interopRequireDefault(require("../list/addCategory"));

var _addChild = _interopRequireDefault(require("../list/addChild"));

var _customPage = _interopRequireDefault(require("@/components/customPage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addCategory: _addCategory.default,
    addChild: _addChild.default,
    customPage: _customPage.default
  },
  data: function data() {
    return {
      tableData: [],
      firstLevel: [],
      parentVisible: false,
      childVisible: false,
      dialogTitle: '',
      total: 0,
      parentForm: {
        id: "",
        addType: "parent",
        parentid: 0,
        name: "",
        image: "",
        link: "",
        enabled: 1,
        displayorder: ""
      },
      childForm: {
        id: "",
        addType: "children",
        parentid: "",
        name: "",
        image: "",
        link: "",
        enabled: 1,
        displayorder: "",
        rent_category: "",
        // insurance_category: "",
        zlb_class: ""
      },
      rent_list: [],
      insurance_list: [],
      zlb_list: [],
      insure_list: []
    };
  },
  methods: {
    // 列表项删除
    removeItem: function removeItem(id) {
      var _this = this;

      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _info.delCategory)({
          id: id
        }).then(function (res) {
          _this.getList();
        });
      });
    },
    handleEnable: function handleEnable(id, enabled) {
      var _this2 = this;

      this.$confirm('确认要修改状态吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        var status = enabled == 1 ? 0 : 1;
        (0, _info.updateCategory)({
          id: id,
          enabled: status
        }).then(function (res) {
          _this2.getList();
        });
      });
    },
    editItem: function editItem(row) {
      // if (this.$refs.cateForm.$refs.parentForm) {
      //   this.$refs.cateForm.$refs.parentForm.resetFields();
      // }
      var obj = (0, _objectSpread2.default)({}, row);

      if (row.parentid == 0) {
        this.dialogTitle = '编辑品类';
        this.parentVisible = true;
        this.parentForm = obj;
      } else {
        this.dialogTitle = '编辑子菜单';
        this.childVisible = true;
        this.childForm = obj;
      }
    },
    // 更改排序
    changeSort: function changeSort(id, num) {
      var _this3 = this;

      (0, _info.updateCategory)({
        id: id,
        displayorder: num
      }).then(function (res) {
        _this3.getList();
      });
    },
    setUrl: function setUrl(val) {
      this.parentForm.image = val;
    },
    setChileUrl: function setChileUrl(val) {
      this.childForm.image = val;
    },
    addCategorys: function addCategorys() {
      // if (this.$refs.cateForm.$refs.parentForm) {
      //   this.$refs.cateForm.$refs.parentForm.resetFields();
      // }
      // this.$nextTick(() => {
      //   this.$refs.cateForm.$refs.parentForm.resetFields();
      // })
      this.dialogTitle = '新增品类';
      this.parentForm = {
        id: "",
        addType: "parent",
        parentid: 0,
        name: "",
        image: "",
        link: "",
        enabled: 1,
        displayorder: ""
      };
      this.parentVisible = true;
    },
    submitForm: function submitForm(val) {
      var _this4 = this;

      var firetForm = (0, _objectSpread2.default)({}, this.parentForm);
      var obj = {
        id: this.parentForm.id || "",
        addType: "parent",
        parentid: 0
      };

      if (this.parentForm.id > 0) {
        delete firetForm.children;
        delete obj.addType;
      }

      firetForm.displayorder = firetForm.displayorder + '';
      (0, _info.updateCategory)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, obj), firetForm)).then(function (res) {
        _this4.parentVisible = false;

        _this4.getList();
      });
    },
    submitChildForm: function submitChildForm(val) {
      var _this5 = this;

      var obj = {
        id: this.childForm.id || "",
        addType: "children"
      };

      if (obj.id) {
        delete obj.addType;
      }

      var params = (0, _objectSpread2.default)({}, this.childForm);
      params.displayorder = params.displayorder + '';
      (0, _info.updateCategory)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, obj), params)).then(function (res) {
        _this5.childVisible = false;

        _this5.getList();
      });
    },
    addItemChild: function addItemChild(row) {
      var pid = row.id;
      this.dialogTitle = '添加子菜单';
      this.childForm = {
        id: "",
        addType: "children",
        parentid: "",
        name: "",
        image: "",
        link: "",
        enabled: 1,
        displayorder: "",
        rent_category: "",
        zlb_class: ""
      };
      this.childForm.enabled = 1;
      this.childVisible = true;
      this.childForm['parentid'] = pid || '';
    },
    closeDialog: function closeDialog() {
      this.parentVisible = false;
      this.childVisible = false;
    },
    // 获取列表
    getList: function getList() {
      var _this6 = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _info.categoryList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this6.tableData = res.data;
        _this6.total = res.total;
        res.data.forEach(function (item) {
          _this6.firstLevel.push({
            id: item.id,
            name: item.name
          });
        });
      });
    },
    getSelect: function getSelect() {
      var _this7 = this;

      (0, _info.getAlipayCategory)().then(function (res) {
        _this7.rent_list = res.rent_list;
        _this7.zlb_list = res.zlb_list;
        _this7.insure_list = res.insure_list;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
    this.getSelect();
    this.rent_list = [{
      id: "RENT_PHONE",
      name: "物品租赁-手机"
    }, {
      id: "RENT_COMPUTER",
      name: "物品租赁-电脑\平板"
    }, {
      id: "RENT_CAMERA",
      name: "物品租赁-数码摄像"
    }, {
      id: "RENT_DIGITAL",
      name: "物品租赁-数码其他"
    }, {
      id: "RENT_STATIONERY",
      name: "物品租赁-电子词典\电纸书\文化用品"
    }, {
      id: "RENT_CLOTHING",
      name: "物品租赁-服装"
    }, {
      id: "RENT_LUGGAGE",
      name: "物品租赁-箱包"
    }, {
      id: "RENT_OUTDOOR_EQUIPMENT",
      name: "物品租赁-户外装备"
    }, {
      id: "RENT_GYM_EQUIPMENT",
      name: "物品租赁-健身器材"
    }, {
      id: "RENT_INSTRUMENT",
      name: "物品租赁-乐器"
    }, {
      id: "RENT_MATERNAL_INFANT_GOODS",
      name: "物品租赁-母婴用品"
    }, {
      id: "RENT_MATERNAL_INFANT_TOY",
      name: "物品租赁-玩具"
    }, {
      id: "RENT_ORNAMENT",
      name: "物品租赁-家具饰品"
    }, {
      id: "RENT_COOK_UTENSIL",
      name: "物品租赁-厨房/烹饪用具"
    }, {
      id: "RENT_HARDWARE_STORAGE",
      name: "物品租赁-收纳整理"
    }, {
      id: "RENT_HARDWARE",
      name: "物品租赁-五金/工具"
    }, {
      id: "RENT_OFFICE_ELECTRIC",
      name: "物品租赁-办公电器"
    }, {
      id: "RENT_OFFICE_FURNITURE",
      name: "物品租赁-办公家具"
    }, {
      id: "RENT_PET",
      name: "物品租赁-宠物"
    }, {
      id: "RENT_FESTIVE_GOODS",
      name: "物品租赁-节庆用品"
    }, {
      id: "RENT_PHOTOGRAPHY_SERVICE",
      name: "物品租赁-婚庆/摄影/摄像服务"
    }, {
      id: "RENT_CAR_GOODS",
      name: "物品租赁-汽车/用品/配件/改装"
    }, {
      id: "RENT_HOUSEHOLD_ELECTRIC",
      name: "物品租赁-大家电"
    }, {
      id: "RENT_LIFE_ELECTRIC",
      name: "物品租赁-生活电器"
    }, {
      id: "RENT_VIRTUAL_GOODS",
      name: "物品租赁-游戏相关"
    }, {
      id: "RENT_MEDICAL_INSTRUMENT",
      name: "物品租赁-OTC药品/医疗器械/计生用品"
    }, {
      id: "RENT_VIDEO_GAME",
      name: "物品租赁-电玩/配件/游戏/攻略"
    }, {
      id: "RENT_GOLD",
      name: "物品租赁-珠宝/钻石/翡翠/黄金"
    }, {
      id: "RENT_HOME_CARE",
      name: "物品租赁-家庭保健"
    }, {
      id: "RENT_GREEN_PLANT",
      name: "物品租赁-鲜花速递/花卉仿真/绿植园艺"
    }, {
      id: "RENT_BRAND_SERVER",
      name: "物品租赁-品牌台机/品牌一体机/服务器"
    }, {
      id: "RENT_SECONDHAND_DIGITAL",
      name: "物品租赁-二手数码"
    }, {
      id: "RENT_NETWORK_EQUIPMENT",
      name: "物品租赁-网络设备"
    }, {
      id: "RENT_KITCHENWARE",
      name: "物品租赁-厨房电器"
    }, {
      id: "RENT_ACCESSORIES",
      name: "物品租赁-配件"
    }, {
      id: "RENT_INTELLIGENT_EQUIPMENT",
      name: "物品租赁-智能设备"
    }, {
      id: "RENT_DIGITAL_LOW",
      name: "物品租赁-数码小额"
    }, {
      id: "RENT_UMBRELLA",
      name: "物品租赁-雨伞"
    }];
    this.zlb_list = [{
      id: "3c_mobile",
      name: "3C办公-手机"
    }, {
      id: "3c_pc",
      name: "3C办公-电脑"
    }, {
      id: "3c_camera",
      name: "3C办公-摄影"
    }, {
      id: "3c_office",
      name: "3C办公-办公设备"
    }, {
      id: "3c_other",
      name: "3C办公-其他"
    }, {
      id: "iot_auto_container",
      name: "IOT-售卖柜"
    }, {
      id: "iot_stage",
      name: "IOT-驿站"
    }, {
      id: "iot_other",
      name: "IOT-其他"
    }, {
      id: "ne_battery",
      name: "新能源-电池"
    }, {
      id: "ne_electric_car",
      name: "新能源-电动车"
    }, {
      id: "ne_other",
      name: "新能源-其他"
    }, {
      id: "general_furniture",
      name: "泛行业-家具"
    }, {
      id: "general_tv",
      name: "泛行业-家电"
    }, {
      id: "general_other",
      name: "泛行业-其他"
    }];
  }
};
exports.default = _default;