"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog"));

var _recommend = require("@/api/recommend");

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsDialog: _goodsDialog.default,
    cropUpload: _cropUpload.default
  },
  props: ["editId"],
  data: function data() {
    return {
      dialogVisible: false,
      urlName: "",
      form: {
        banner_img: "",
        banner_url: "",
        jump_type: 1,
        link_url: ""
      },
      rules: {
        banner_img: [{
          required: true,
          message: '请上传轮播图',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    setUrl: function setUrl(val) {
      this.form.banner_img = val;
    },
    // 提交
    submit: function submit() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this.form.jump_type == 2) {
            _this.form.banner_url = _this.form.link_url;
          }

          console.log(_this.form);
          (0, _recommend.addBanner)((0, _objectSpread2.default)({
            banner_type: 3
          }, _this.form)).then(function (res) {
            _this.dialogVisible = false;

            _this.$emit("getList");
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    },
    // 设置选中的商品
    setSelect: function setSelect(goodsList) {
      var obj = goodsList[0];
      this.form.banner_url = obj.goods_id;
      this.urlName = obj.title;
    },
    // 打开商品选择对话框
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = this.form.banner_url ? [{
        goods_id: this.form.banner_url,
        title: this.urlName
      }] : [];
    },
    reset: function reset() {
      var _this2 = this;

      this.urlName = "";
      this.form = {
        banner_img: "",
        banner_url: "",
        jump_type: 1,
        link_url: ""
      };
      this.$nextTick(function () {
        _this2.$refs['form'].resetFields();
      });
    },
    // 获取轮播图数据
    getData: function getData() {
      var _this3 = this;

      (0, _recommend.getBannerById)(this.editId).then(function (res) {
        _this3.form.banner_id = res.id;
        _this3.form.jump_type = res.jump_type;
        _this3.form.banner_img = res.banner_img;

        if (_this3.form.jump_type == 1) {
          _this3.form.banner_url = res.banner_url;
          _this3.form.link_url = "";
        } else {
          _this3.form.link_url = res.banner_url;
          _this3.form.banner_url = "";
        }

        _this3.urlName = res.goods_name;
      });
    }
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editId ? "编辑轮播图" : "添加轮播图";
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.reset();

        if (this.editId) {
          this.getData();
        }
      }
    }
  }
};
exports.default = _default;