var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "查看备注", visible: _vm.dialogVisible, width: "1000px" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "备注信息", prop: "remark" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作人员",
              prop: "username",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "时间", prop: "create_time" }
          })
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.add } },
            [_vm._v("添加备注")]
          )
        ],
        1
      ),
      _c("add-dialog", {
        ref: "addDialog",
        attrs: { "order-id": _vm.orderId, "which-period": _vm.whichPeriod },
        on: { getList: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }