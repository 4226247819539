var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("数据列表\n    ")
            ],
            1
          ),
          _vm.$hasMethod("#add")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.openAddDialog()
                    }
                  }
                },
                [_vm._v("添加菜单")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-key": "id",
            border: "",
            "tree-props": { children: "children" }
          }
        },
        [
          _c("el-table-column", { attrs: { align: "center", label: "展开" } }),
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    !_vm.$hasMethod("#sort")
                      ? _c("span", [_vm._v(_vm._s(scope.row.sort_num))])
                      : _c("el-input", {
                          attrs: {
                            oninput: "value=value.replace(/[^\\d]/g,'')",
                            maxLength: "9"
                          },
                          on: {
                            change: function($event) {
                              return _vm.changeSort(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.sort_num,
                            callback: function($$v) {
                              _vm.$set(scope.row, "sort_num", $$v)
                            },
                            expression: "scope.row.sort_num"
                          }
                        })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", prop: "id", width: "80" }
          }),
          _c("el-table-column", {
            attrs: { align: "left", label: "菜单名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      scope.row.pid != 0
                        ? _c(
                            "span",
                            { staticStyle: { margin: "0 5px 0 20px" } },
                            [_vm._v("├─")]
                          )
                        : _vm._e(),
                      _vm._v(_vm._s(scope.row.label))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "控制器", prop: "controller" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "方法", prop: "action" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "路径", prop: "path" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "所属平台", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.type == 1 ? "管理后台" : "供应商后台")
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态", width: "70", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$hasMethod("#enable")
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "pointer",
                            attrs: {
                              type: scope.row.status == 1 ? "success" : "danger"
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeStatus(
                                  scope.row.id,
                                  scope.row.status
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "启用" : "禁用")
                            )
                          ]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type: scope.row.status == 1 ? "success" : "danger"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "启用" : "禁用")
                            )
                          ]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "230",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$hasMethod("#add")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.openAddDialog(scope.row, "add")
                              }
                            }
                          },
                          [_vm._v("添加子菜单")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.openAddDialog(scope.row, "edit")
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.removeItem(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("add-dialog", {
        ref: "addDialog",
        attrs: {
          "menu-list": _vm.tableData,
          pItem: _vm.pItem,
          "edit-item": _vm.editItem
        },
        on: { getList: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }