"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _system = require("@/api/system");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: ["viewId"],
  data: function data() {
    return {
      tableData: [],
      viewForm: {
        id: 0,
        sms_title: '',
        default_template: '',
        trigger_rule: '',
        charge_rule: '',
        sms_type: 1
      },
      rules: {
        sms_title: [{
          required: true,
          message: '模板名称不能为空',
          trigger: 'blur'
        }],
        default_template: [{
          required: true,
          message: '模板内容不能为空',
          trigger: 'blur'
        }],
        trigger_rule: [{
          required: true,
          message: '触发规则不能为空',
          trigger: 'blur'
        }],
        charge_rule: [{
          required: true,
          message: '收费规则不能为空',
          trigger: 'blur'
        }]
      },
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      regionList: [],
      areaList: [],
      shopcity: '',
      companyArea: ''
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;

      if (this.viewId > 0) {
        (0, _system.getTemplateRuleInfo)(this.viewId).then(function (res) {
          // console.log('getTemplateRuleInfo', res)
          _this.viewForm = res;
        });
      }
    },
    // 保存提交
    submit: function submit() {
      var _this2 = this;

      this.$refs['viewForm'].validate(function (valid) {
        if (valid) {
          (0, _system.editTemplateRule)((0, _objectSpread2.default)({}, _this2.viewForm)).then(function (res) {
            _this2.$message.success('操作成功');

            _this2.$router.back();
          });
        } else {
          _this2.$message({
            message: "表单信息不完整或有错误，请检查完整表单",
            type: "error"
          });

          return false;
        }
      });
    }
  }
};
exports.default = _default;