"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoryList = categoryList;
exports.editUserIscheck = editUserIscheck;
exports.delUserInfo = delUserInfo;
exports.getSourceInfo = getSourceInfo;
exports.getUserByIdStr = getUserByIdStr;
exports.getSourceAndRate = getSourceAndRate;
exports.setRate = setRate;
exports.getGoodsCheckList = getGoodsCheckList;
exports.goodsCheckPass = goodsCheckPass;
exports.getGoodsShelfCheckList = getGoodsShelfCheckList;
exports.getGoodsInfo = getGoodsInfo;
exports.goodsCheckRefuse = goodsCheckRefuse;
exports.goodsShelfCheckPass = goodsShelfCheckPass;
exports.goodsShelfCheckRefuse = goodsShelfCheckRefuse;
exports.getGoodsAll = getGoodsAll;
exports.getActivityList = getActivityList;
exports.addActivity = addActivity;
exports.goodsShare = goodsShare;
exports.itemDownShelf = itemDownShelf;
exports.goodsGrounding = goodsGrounding;
exports.getShopCategory = getShopCategory;
exports.getCategory = getCategory;
exports.getGoodsAreas = getGoodsAreas;
exports.getGoodsRentData = getGoodsRentData;
exports.getGoodsData = getGoodsData;
exports.getGoodsParameter = getGoodsParameter;
exports.getGoodsSetmealList = getGoodsSetmealList;
exports.getParameterList = getParameterList;
exports.getGoodsSetmealInfo = getGoodsSetmealInfo;
exports.editGoodsData = editGoodsData;
exports.editGoodsSetmeal = editGoodsSetmeal;
exports.withdrawalExamineList = withdrawalExamineList;
exports.withdrawalPass = withdrawalPass;
exports.getExamineMobile = getExamineMobile;
exports.withdrawalRefuse = withdrawalRefuse;
exports.withdrawalExamineListExport = withdrawalExamineListExport;
exports.merchantWithdrawalExamineList = merchantWithdrawalExamineList;
exports.merchantWithdrawalPass = merchantWithdrawalPass;
exports.getMerchantExamineMobile = getMerchantExamineMobile;
exports.merchantWithdrawalRefuse = merchantWithdrawalRefuse;
exports.merchantWithdrawalExamineListExport = merchantWithdrawalExamineListExport;
exports.supplierCheckList = supplierCheckList;
exports.shopUserById = shopUserById;
exports.editShopUserById = editShopUserById;
exports.editShopUserStatus = editShopUserStatus;
exports.delShopUser = delShopUser;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

/**
 * 品类审核
 */
// 品类审核列表
function categoryList(params) {
  return _fetch.default.post("/Admin/CategoryExamine/categoryExamineList", params);
} // 根据id编辑商户分类信息的审核状态(通过、拒绝)


function editUserIscheck(params) {
  return _fetch.default.post("/Admin/CategoryExamine/editCategoryUserIscheck", params, true);
} // 根据id删除商户分类信息


function delUserInfo(params) {
  return _fetch.default.post("/Admin/CategoryExamine/delCategoryUser", params, true);
} // 根据member_id、category_id获取订单来源信息


function getSourceInfo(params) {
  return _fetch.default.get("/Admin/CategoryExamine/getSource", params);
} // 根据idStr获取商户分类信息


function getUserByIdStr(id) {
  return _fetch.default.get("/Admin/CategoryExamine/categoryUserByIdStr?idStr=377,376", {
    id: id
  }, true);
} // 根据member_id、category_id获取订单来源和费率信息


function getSourceAndRate(params) {
  return _fetch.default.get("/Admin/CategoryExamine/getSourceAndRate", params);
} // 设置费率


function setRate(params) {
  return _fetch.default.post("/Admin/CategoryExamine/setRate", params, true);
}
/**
 * 商品审核
 */
// 商品审核列表


function getGoodsCheckList(params) {
  return _fetch.default.post("/Admin/GoodsCheck/getGoodsCheckList", params);
} // 商品审核通过


function goodsCheckPass(ids) {
  return _fetch.default.post("/Admin/GoodsCheck/goodsCheckPass", {
    goods_id: ids
  }, true);
} // 商品下架审核列表


function getGoodsShelfCheckList(params) {
  return _fetch.default.post("/Admin/GoodsCheck/getGoodsShelfCheckList", params);
} // 查看商品详情


function getGoodsInfo(id) {
  return _fetch.default.get("/Admin/Goods/getGoodsInfo", {
    goods_id: id
  });
} // 商品审核拒绝


function goodsCheckRefuse(params) {
  return _fetch.default.post("/Admin/GoodsCheck/goodsCheckRefuse", params);
} // 商品下架审核通过


function goodsShelfCheckPass(ids) {
  return _fetch.default.post("/Admin/GoodsCheck/goodsShelfCheckPass", {
    goods_id: ids
  }, true);
} // 商品审核拒绝


function goodsShelfCheckRefuse(params) {
  return _fetch.default.post("/Admin/GoodsCheck/goodsShelfCheckRefuse", params);
} // 商品列表


function getGoodsAll(params) {
  return _fetch.default.post("/Admin/Goods/getGoodsAll", params);
} // 获取设置活动


function getActivityList(id) {
  return _fetch.default.get("/Admin/Goods/getActivityList", {
    goods_id: id
  });
} // 设置活动


function addActivity(params) {
  return _fetch.default.post("/Admin/Goods/addActivity", params);
} // 商品分享


function goodsShare(id) {
  return _fetch.default.get("/Admin/Goods/goodsShare", {
    goods_id: id
  });
} // 商品下架


function itemDownShelf(params) {
  return _fetch.default.post("/Admin/Goods/itemDownShelf", params, true);
} // 商品上架


function goodsGrounding(id) {
  return _fetch.default.post("/Admin/Goods/goodsGrounding", {
    goods_id: id
  }, true);
} // 获取所有申请品类


function getShopCategory(id) {
  return _fetch.default.get("/Admin/Goods/getShopCategory", {
    goods_id: id
  });
} // 获取所有商品品类


function getCategory() {
  return _fetch.default.get("/Admin/Goods/getCategory");
} // 获取商品服务城市


function getGoodsAreas(params) {
  return _fetch.default.get("/Admin/Goods/getGoodsAreas", params);
} // 获取商品添加各参数


function getGoodsRentData() {
  return _fetch.default.get("/Admin/Goods/getGoodsRentData");
} // 编辑获取商品基础信息


function getGoodsData(id) {
  return _fetch.default.get("/Admin/Goods/getGoodsData", {
    goods_id: id
  });
} // 通过分类id获取商品参数


function getGoodsParameter(id) {
  return _fetch.default.post("/Admin/Goods/getGoodsParameter", {
    cate_id: id
  });
} // 编辑获取商品套餐列表


function getGoodsSetmealList(id) {
  return _fetch.default.get("/Admin/Goods/getGoodsSetmealList", {
    goods_id: id
  });
} // 套餐获取参数


function getParameterList() {
  return _fetch.default.get("/Admin/Goods/getParameterList");
} // 编辑获取套餐信息


function getGoodsSetmealInfo(id) {
  return _fetch.default.get("/Admin/Goods/getGoodsSetmealInfo", {
    setmeal_id: id
  });
} // 编辑商品基础信息


function editGoodsData(params) {
  return _fetch.default.post("/Admin/Goods/editGoodsData", params);
} // 编辑套餐信息


function editGoodsSetmeal(params) {
  return _fetch.default.post("/Admin/Goods/editGoodsSetmeal", params);
} // 提现审核列表


function withdrawalExamineList(params) {
  return _fetch.default.post("/Admin/Withdrawal/withdrawalExamineList", params);
} // 提现审核通过


function withdrawalPass(params) {
  return _fetch.default.post("/Admin/Withdrawal/withdrawalPass", params, true);
} // 获取提现审核验证码


function getExamineMobile() {
  return _fetch.default.post("/Admin/Withdrawal/getExamineMobile");
} // 提现审核拒绝


function withdrawalRefuse(params) {
  return _fetch.default.post("/Admin/Withdrawal/withdrawalRefuse", params, true);
} // 提现审核列表导出


function withdrawalExamineListExport(params) {
  return _fetch.default.export("/Admin/Withdrawal/withdrawalExamineListExport", params, "提现审核列表");
}
/**
 * 商户提现审核
 */
// 商户提现审核列表


function merchantWithdrawalExamineList(params) {
  return _fetch.default.post("/Admin/Withdrawal/merchantWithdrawalExamineList", params);
} // 商户提现审核通过


function merchantWithdrawalPass(params) {
  return _fetch.default.post("/Admin/Withdrawal/merchantWithdrawalPass", params, true);
} // 获取商户提现审核验证码


function getMerchantExamineMobile() {
  return _fetch.default.post("/Admin/Withdrawal/getMerchantExamineMobile");
} // 商户提现审核拒绝


function merchantWithdrawalRefuse(params) {
  return _fetch.default.post("/Admin/Withdrawal/merchantWithdrawalRefuse", params, true);
} // 商户提现审核列表导出


function merchantWithdrawalExamineListExport(params) {
  return _fetch.default.export("/Admin/Withdrawal/merchantWithdrawalExamineListExport", params, "商户提现审核列表");
}
/**
 * 供应商审核
 */
// 供应商审核列表


function supplierCheckList(params) {
  return _fetch.default.post("/Admin/SupplierCheck/supplierCheckList", params);
} // 根据id查看供应商信息


function shopUserById(id) {
  return _fetch.default.get("/Admin/SupplierCheck/shopUserById", {
    id: id
  });
} // 根据id编辑供应商信息


function editShopUserById(params) {
  return _fetch.default.post("/Admin/SupplierCheck/editShopUserById", params, true);
} // 根据id编辑审核状态(通过)


function editShopUserStatus(id) {
  return _fetch.default.post("/Admin/SupplierCheck/editShopUserStatus", {
    id: id,
    status: 1
  }, true);
} // 根据id删除供应商信息


function delShopUser(id) {
  return _fetch.default.post("/Admin/SupplierCheck/delShopUser", {
    id: id
  }, true);
}