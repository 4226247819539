"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.replace");

var func = {
  openBlank: function openBlank(url) {
    // let routeData = this.VueRouter.resolve({ path:url}); 
    window.open(url.href, '_blank');
  },
  openWindow: function openWindow(url) {
    // let routeData = this.VueRouter.resolve({ path:url}); 
    window.open(url, '_blank');
  },
  // 深度克隆
  deepClone: function deepClone(source) {
    return JSON.parse(JSON.stringify(source));
  },
  // 整数过滤
  filterNum: function filterNum(val) {
    return val.replace(/[^[0-9-]*/g, '');
  },
  // 浮点数过滤
  filterFloat: function filterFloat(val) {
    return val.replace(/[^[0-9.-]*/g, '');
  }
}; // 生成随机数

func.random = function () {
  var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
  var m = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var isStr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var max = Math.max(n, m);
  var min = Math.min(n, m);
  var num = Math.random() * (max - min) + min;
  num = Math.round(num);

  if (isStr) {
    num = num.toString();
    var len = max.toString().length - num.length;

    for (var i = 0; i < len; i++) {
      num = '0' + num;
    }
  }

  return num;
};

var _default = func;
exports.default = _default;