var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "备注记录", visible: _vm.dialogVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "备注记录", prop: "operator_content" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作人员", prop: "operator", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "时间", prop: "create_time", width: "200" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addNotes } },
            [_vm._v("添加备注")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: "添加备注",
            visible: _vm.innerVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.innerVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "备注：", "label-width": _vm.formLabelWidth }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autocomplete: "off",
                      rows: 3,
                      placeholder: "请输入备注内容"
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }