var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
    [
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "loading-box",
            attrs: { "element-loading-text": "分批复制中,请稍后......" }
          })
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { "label-width": "15%", label: "商品源供应商" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "全部", clearable: "" },
              model: {
                value: _vm.form.memberId,
                callback: function($$v) {
                  _vm.$set(_vm.form, "memberId", $$v)
                },
                expression: "form.memberId"
              }
            },
            _vm._l(_vm.memberList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.shopname, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("立即复制")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onClear } },
            [_vm._v("取消复制操作")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }