var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "share_dialog",
      attrs: {
        width: "1000px",
        "show-close": false,
        visible: _vm.dialogVisible
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c("h3", { staticClass: "text-center" }, [_vm._v("分享商品")]),
          _c(
            "div",
            { staticClass: "flex align-center goods_info" },
            [
              _c(
                "el-image",
                {
                  staticStyle: { width: "60px", height: "60px" },
                  attrs: { src: _vm.goodsData.imgurl }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "image-slot",
                      attrs: { slot: "error" },
                      slot: "error"
                    },
                    [_c("i", { staticClass: "el-icon-picture-outline" })]
                  )
                ]
              ),
              _c("div", { staticClass: "flex-1 text-left info" }, [
                _c("p", { staticClass: "color-999" }, [
                  _vm._v(_vm._s(_vm.goodsData.id))
                ]),
                _c("p", { staticClass: "color-666" }, [
                  _vm._v(_vm._s(_vm.goodsData.title))
                ])
              ])
            ],
            1
          ),
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              {
                staticClass: "text-center item_wrap",
                staticStyle: { "margin-right": "20px" }
              },
              [
                _c("p", { staticClass: "text-center title" }, [
                  _vm._v("分享二维码")
                ]),
                _c("el-image", { attrs: { src: _vm.goodsData.qrcode_url } }, [
                  _c(
                    "div",
                    {
                      staticClass: "image-slot",
                      attrs: { slot: "error" },
                      slot: "error"
                    },
                    [_c("i", { staticClass: "el-icon-picture-outline" })]
                  )
                ]),
                _c("p", [_vm._v("右键点击复制图片")])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item_wrap", staticStyle: { margin: "0 20px" } },
              [
                _c(
                  "p",
                  {
                    staticClass: "text-center title",
                    staticStyle: { "margin-bottom": "20px" }
                  },
                  [_vm._v("分享海报")]
                ),
                _c("div", { staticClass: "poster" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.posterImg,
                          expression: "!posterImg"
                        }
                      ],
                      staticClass: "poster",
                      attrs: { id: "posterHtml" }
                    },
                    [
                      _c("div", { staticClass: "img_wrap" }, [
                        _c("img", {
                          staticClass: "w-100",
                          attrs: { src: _vm.goodsData.imgurl, alt: "" }
                        })
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "flex align-center justify-between info"
                        },
                        [
                          _c("div", { staticClass: "flex-1 left" }, [
                            _c("p", [_vm._v(_vm._s(_vm.goodsData.title))]),
                            _c(
                              "p",
                              {
                                staticClass: "red-font",
                                staticStyle: { "margin-top": "15px" }
                              },
                              [_vm._v(_vm._s(_vm.goodsData.min_price) + "/天")]
                            )
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("img", {
                              staticStyle: { width: "50px" },
                              attrs: { src: _vm.goodsData.qrcode_url, alt: "" }
                            })
                          ])
                        ]
                      )
                    ]
                  )
                ]),
                _c("img", {
                  staticStyle: { "z-index": "100" },
                  attrs: { src: _vm.posterImg }
                }),
                _c("p", { staticStyle: { "margin-top": "20px" } }, [
                  _vm._v("右键点击复制图片")
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "item_wrap" },
              [
                _c("p", { staticClass: "text-center title" }, [
                  _vm._v("小程序链接")
                ]),
                _c("p", [_vm._v(_vm._s(_vm.goodsData.alipay_url))]),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.goodsData.alipay_url,
                        expression: "goodsData.alipay_url",
                        arg: "copy"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error"
                      }
                    ],
                    staticStyle: { width: "fit-content" },
                    attrs: { size: "mini" }
                  },
                  [_vm._v("复制")]
                )
              ],
              1
            )
          ]),
          _c(
            "p",
            {
              staticClass: "text-center color-999",
              staticStyle: { "margin-top": "20px" }
            },
            [_vm._v("您可以通过以上三种方式分享至微信群、QQ群或发微博")]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("关 闭")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }