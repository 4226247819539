"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var orderStatus = [{
  id: "all",
  name: "所有订单"
}, {
  id: "1",
  name: "订单关闭",
  type: "success"
}, {
  id: "2",
  name: "未支付",
  type: "warning"
}, {
  id: "3",
  name: "签约未支付",
  type: "warning"
}, {
  id: "9",
  name: "待风控",
  type: "warning"
}, {
  id: "10",
  name: "待发货",
  type: "warning"
}, {
  id: "11",
  name: "申请退款",
  type: "warning"
}, {
  id: "12",
  name: "申请退款商家同意",
  type: "warning"
}, {
  id: "13",
  name: "申请退款商家拒绝",
  type: "warning"
}, {
  id: "14",
  name: "申请退款平台拒绝",
  type: "warning"
}, {
  id: "15",
  name: "部分退款",
  type: "warning"
}, {
  id: "16",
  name: "退款成功",
  type: "success"
}, {
  id: "20",
  name: "已发货",
  type: "warning"
}, {
  id: "30",
  name: "租用中",
  type: "warning"
}, {
  id: "31",
  name: "退货退款平台待审核",
  type: "warning"
}, {
  id: "32",
  name: "退货退款用户待发货",
  type: "warning"
}, {
  id: "33",
  name: "退货退款用户已发货",
  type: "warning"
}, {
  id: "34",
  name: "退货退款商家确认收货",
  type: "warning"
}, {
  id: "40",
  name: "待归还",
  type: "warning"
}, {
  id: "41",
  name: "申请归还",
  type: "warning"
}, {
  id: "42",
  name: "申请归还同意",
  type: "warning"
}, {
  id: "43",
  name: "申请归还拒绝",
  type: "warning"
}, {
  id: "44",
  name: "归还用户已发货",
  type: "warning"
}, {
  id: "45",
  name: "归还商家确认收货",
  type: "warning"
}, {
  id: "50",
  name: "续租申请",
  type: "warning"
}, {
  id: "51",
  name: "续租申请同意",
  type: "warning"
}, {
  id: "52",
  name: "续租申请拒绝",
  type: "warning"
}, {
  id: "60",
  name: "交易完成",
  type: "success"
}, {
  id: "61",
  name: "续租已完成",
  type: "warning"
}, {
  id: "62",
  name: "买断完成",
  type: "success"
}, {
  id: "63",
  name: "提前归还交易完成",
  type: "success"
}, {
  id: "64",
  name: "退货退款完成",
  type: "success"
}, {
  id: "65",
  name: "归还完成",
  type: "success"
}];
var _default = {
  orderStatus: orderStatus
};
exports.default = _default;