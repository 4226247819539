var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info_config_page" },
    [
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("数据列表\n    ")
            ],
            1
          ),
          _vm.$hasMethod("#add")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "h5ConfigShowAdd" })
                    }
                  }
                },
                [_vm._v("新添板块")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    !_vm.$hasMethod("#sort")
                      ? _c("span", [_vm._v(_vm._s(scope.row.module_sort))])
                      : _c("el-input", {
                          attrs: {
                            oninput: "value=value.replace(/[^\\d]/g,'')",
                            maxLength: "9"
                          },
                          on: {
                            change: function($event) {
                              return _vm.changeSort(
                                scope.row.id,
                                scope.row.module_sort
                              )
                            }
                          },
                          model: {
                            value: scope.row.module_sort,
                            callback: function($$v) {
                              _vm.$set(scope.row, "module_sort", $$v)
                            },
                            expression: "scope.row.module_sort"
                          }
                        })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", label: "ID" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.module_type == 1 ? "热门推荐" : "自定义模块"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "main_title", label: "名称" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态", width: "180", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$hasMethod("#enable")
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "pointer",
                            attrs: {
                              type: scope.row.status == 1 ? "success" : "danger"
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeStatus(
                                  scope.row.id,
                                  scope.row.status
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "显示" : "隐藏")
                            )
                          ]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type: scope.row.status == 1 ? "success" : "danger"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "显示" : "隐藏")
                            )
                          ]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "280",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$hasMethod("#edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "h5ConfigShowAdd",
                                  params: { editId: scope.row.id }
                                })
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.removeItem(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }