"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _recommend = require("@/api/recommend");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["editItem"],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        name: "",
        title: "",
        status: 2
      },
      rules: {
        name: [{
          required: true,
          message: '分类名称不能为空',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '标语不能为空',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editItem ? "编辑推广分类" : "添加推广分类";
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          (0, _recommend.addOrEditH5PromotionCate)(_this.form).then(function (res) {
            _this.dialogVisible = false;

            _this.$emit("getList");
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;

      if (val) {
        this.$nextTick(function () {
          _this2.$refs['form'].resetFields();

          if (_this2.editItem) {
            // 编辑
            _this2.form.id = _this2.editItem.id;
            _this2.form.name = _this2.editItem.name;
            _this2.form.title = _this2.editItem.title;
            _this2.form.status = _this2.editItem.status;
          }
        });
      } else {
        this.form = {
          name: "",
          title: "",
          status: 2
        };
      }
    }
  }
};
exports.default = _default;