var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.form, "label-width": "100px" } },
    [
      _vm._v(
        "\n\t商家进件（创建收款账号）---需要完成蚂蚁平台注册并开通蚂蚁链和蚂蚁合同以及公司入驻 上一个页面的公司信息\n"
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("页面说明")
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "法人手机" } },
        [
          _c("el-input", {
            attrs: { placeholder: "18050222222" },
            model: {
              value: _vm.form.phone,
              callback: function($$v) {
                _vm.$set(_vm.form, "phone", $$v)
              },
              expression: "form.phone"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "商家实体别名" } },
        [
          _c("el-input", {
            attrs: { placeholder: "建议小程序名" },
            model: {
              value: _vm.form.alias_name,
              callback: function($$v) {
                _vm.$set(_vm.form, "alias_name", $$v)
              },
              expression: "form.alias_name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "支付宝账号" } },
        [
          _c("el-input", {
            attrs: { placeholder: "shuangshuangzu66@163.com" },
            model: {
              value: _vm.form.alipay_logon_id,
              callback: function($$v) {
                _vm.$set(_vm.form, "alipay_logon_id", $$v)
              },
              expression: "form.alipay_logon_id"
            }
          }),
          _vm._v(
            " 结算账号，实名认证支付宝账户，后续二级商户增值产品服务签约时使用 很重要\n"
          )
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "公司地址" } }, [
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-cascader", {
              attrs: { options: _vm.options },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.form.address_code,
                callback: function($$v) {
                  _vm.$set(_vm.form, "address_code", $$v)
                },
                expression: "form.address_code"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "公司详细地址" } },
        [
          _c("el-input", {
            attrs: { placeholder: "曹溪街道双龙路1号B区B1幢万达中心1816室" },
            model: {
              value: _vm.form.address,
              callback: function($$v) {
                _vm.$set(_vm.form, "address", $$v)
              },
              expression: "form.address"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "公司内景\n  限制1M以内 ", prop: "imgurl_neij" } },
        [
          _c("crop-upload", {
            ref: "coverUpload",
            attrs: {
              "show-type": "photo",
              fixScale: true,
              "is-square": false,
              "always-crop": true,
              fixedBox: false,
              "init-val": _vm.form.imgurl_neij,
              "wrapper-width": "120px",
              "wrapper-height": "120px",
              "photo-width": 120,
              "photo-height": 120,
              autoCropWidth: 150,
              autoCropHeight: 150,
              cropperWidth: "400px",
              cropperHeight: "400px",
              dialogWidth: "600px",
              enlarge: 5
            },
            on: { setVal: _vm.setUrl }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "公司外景\n  限制1M以内 ", prop: "imgurl_waij" } },
        [
          _c("crop-upload", {
            ref: "coverUpload",
            attrs: {
              "show-type": "photo",
              fixScale: true,
              "is-square": false,
              "always-crop": true,
              fixedBox: false,
              "init-val": _vm.form.imgurl_waij,
              "wrapper-width": "120px",
              "wrapper-height": "120px",
              "photo-width": 120,
              "photo-height": 120,
              autoCropWidth: 150,
              autoCropHeight: 150,
              cropperWidth: "400px",
              cropperHeight: "400px",
              dialogWidth: "600px",
              enlarge: 5
            },
            on: { setVal: _vm.setUrl2 }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "营业执照\n    限制1M以内 ", prop: "imgurl_yyzz" } },
        [
          _c("crop-upload", {
            ref: "coverUpload",
            attrs: {
              "show-type": "photo",
              fixScale: true,
              "is-square": false,
              "always-crop": true,
              fixedBox: false,
              "init-val": _vm.form.imgurl_yyzz,
              "wrapper-width": "120px",
              "wrapper-height": "120px",
              "photo-width": 120,
              "photo-height": 120,
              autoCropWidth: 150,
              autoCropHeight: 150,
              cropperWidth: "400px",
              cropperHeight: "400px",
              dialogWidth: "600px",
              enlarge: 5
            },
            on: { setVal: _vm.setUrl3 }
          })
        ],
        1
      ),
      _vm.form.order_code
        ? _c(
            "el-form-item",
            { attrs: { label: "进件码" } },
            [
              _c("el-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.order_code,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "order_code", $$v)
                  },
                  expression: "form.order_code"
                }
              }),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.checkJJCodeStatus }
                },
                [_vm._v("商家进件审核结果")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.form.order_code == null || _vm.form.order_code == ""
        ? _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("商家进件")]
              ),
              _c("el-button", [_vm._v("取消")])
            ],
            1
          )
        : _c("el-form-item", { attrs: { label: "进件审核结果" } }, [
            _vm.form.order_status != 0
              ? _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.getorderstatustype(_vm.form.order_status))
                  )
                ])
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }