"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _complaint = require("@/api/complaint");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["suggestId"],
  data: function data() {
    return {
      tableData: [],
      dialogVisible: false,
      innerVisible: false,
      formLabelWidth: "120px",
      form: {
        content: ""
      }
    };
  },
  methods: {
    close: function close() {
      this.dialogVisible = false;
      this.innerVisible = false;
      this.form.content = '';
    },
    addNotes: function addNotes() {
      this.dialogVisible = false;
      this.innerVisible = true;
    },
    getData: function getData() {},
    submit: function submit() {
      var _this = this;

      if (!this.form.content) {
        this.$message.warning("请输入备注信息");
        return false;
      }

      (0, _complaint.addRemarks)({
        suggest_id: this.suggestId,
        content: this.form.content
      }).then(function (res) {
        _this.close();

        _this.$parent.getList();
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;

      if (val) {
        (0, _complaint.remarksRecord)(this.suggestId).then(function (res) {
          _this2.tableData = res;
        });
      }
    }
  },
  mounted: function mounted() {
    this.getData();
  }
};
exports.default = _default;