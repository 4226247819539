var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: _vm.dialogType === 1 ? "20%" : "60%",
        title: _vm.dialogType === 1 ? "上链" : "参数接口",
        "before-close": _vm.closeDialog,
        "custom-class": "custom-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.dialogType === 1
        ? _c("span", [_vm._v("您确定要上链吗？")])
        : _vm._e(),
      _vm.dialogType === 2
        ? _c("div", { staticClass: "flex justify-center" }, [
            _c("div", { staticClass: "json_wrapper" }, [
              _c("h5", [_vm._v("入参接口")]),
              _c("pre", [_vm._v(_vm._s(_vm.requestInfo))])
            ]),
            _c("div", { staticClass: "json_wrapper" }, [
              _c("h5", [_vm._v("返回接口")]),
              _c("pre", [_vm._v(_vm._s(_vm.responseInfo))])
            ])
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _vm.dialogType === 1
            ? _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ])
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.closeDialog } },
            [_vm._v(_vm._s(_vm.dialogType === 1 ? "确 定" : "关 闭"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }