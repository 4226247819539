"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: Number
    },
    requestInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    responseInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    closeDialog: function closeDialog(param) {
      this.$emit("close", param);
    }
  }
};
exports.default = _default2;