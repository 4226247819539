"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title;
    var vnodes = [];

    if (icon) {
      if (icon.includes('el-icon')) {
        vnodes.push(h("i", {
          "class": [icon, 'sub-el-icon']
        }));
      } else {
        vnodes.push(h("svg-icon", {
          "attrs": {
            "icon-class": icon
          }
        }));
      }
    }

    if (title) {
      vnodes.push(h("span", {
        "slot": 'title'
      }, [title]));
    }

    return vnodes;
  }
};
exports.default = _default;