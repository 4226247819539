var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-title-page" },
    [
      _c(
        "div",
        { staticClass: "nav_tabs" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.tabList, function(item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.label, name: item.name }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("el-divider", { staticClass: "middle_divider" }),
      _c(
        "div",
        { staticClass: "child_page" },
        [_c(_vm.activeName, { tag: "component" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }