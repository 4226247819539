"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["id"],
  data: function data() {
    return {
      form: {
        code1: "",
        code2: ""
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    onSubmit: function onSubmit() {
      var data = this.form;

      if (data.code1 == '' || data.code2 == '') {
        this.$message.error('密码不能为空');
        return false;
      }

      if (data.code1 != data.code2) {
        this.$message.error('两次密码不一样');
        return false;
      }

      (0, _user.editPwdForShop)({
        member_id: this.id,
        code1: this.$md5(data.code1),
        code2: this.$md5(data.code2)
      }).then(function (res) {
        console.log(res);
      });
    }
  }
};
exports.default = _default;