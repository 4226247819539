"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

require("core-js/modules/web.dom.iterable");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    setRateVisible: {
      type: Boolean,
      default: false
    },
    // setRateData: {
    //   type: Array,
    //   default: () => [],
    // },
    sourceList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    sourceForm: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectVal: {
      type: String
    }
  },
  data: function data() {
    var checkCode = function checkCode(rule, value, callback) {
      var reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;

      if (!value) {
        callback(new Error("请输入数字"));
      } else if (!reg.test(value)) {
        callback(new Error("费率为正数且最多两位小数"));
      } else {
        callback();
      }
    };

    return {
      rules: {
        smartcontract: [{
          required: true,
          message: "请输入合同费用",
          trigger: "blur"
        }, {
          validator: checkCode,
          trigger: 'blur'
        }],
        realname: [{
          required: true,
          message: "请输入实名认证费用",
          trigger: "blur"
        }],
        platform: [{
          required: true,
          message: "请输入平台费用",
          trigger: "blur"
        }],
        withhold: [{
          required: true,
          message: "请输入代扣费率",
          trigger: "blur"
        }],
        file: [{
          required: true,
          message: "请输入存证费率",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    changeSourceId: function changeSourceId(val) {
      this.$emit("changeSourceId", val);
    },
    closeDialog: function closeDialog(param) {
      this.$emit("closeDialog", param);
    },
    deleteFrom: function deleteFrom(item, index) {
      this.$emit("deleteFrom", item, index);
    },
    addSource: function addSource(param) {
      this.$emit("addSource");
    },
    submitSource: function submitSource(formName) {
      var _this = this;

      var flag = false;
      this.sourceForm.forEach(function (item) {
        for (var key in item) {
          if (key == "source_id" && !item[key]) {
            _this.$message.error("请选择订单来源");

            flag = false;
            return false;
          } else if (key != "source_id" && key != "is_delete") {
            if (!item[key]) {
              _this.$message.error("请确保已设置费率");

              flag = false;
              return false;
            } else if (item[key]) {
              var isNum = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;

              if (!isNum.test(item[key])) {
                _this.$message.error("费率为正数且最多两位小数");

                flag = false;
                return false;
              } else {
                flag = true;
                return;
              }
            }
          } else {
            flag = true;
          }
        }
      });

      if (this.sourceForm.length > 1) {
        var ids = this.sourceForm.map(function (item) {
          return item.source_id;
        });
        var idsSet = new Set(ids);

        if (idsSet.size != ids.length) {
          this.$message.error("订单来源不能重复");
          flag = false;
          return false;
        } else {
          flag = true;
        }
      } // console.log('flag',flag)


      if (flag) {
        this.$emit("submitSource", this.sourceForm);
      }
    }
  }
};
exports.default = _default2;