"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _finance = require("@/api/finance");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _agreeRefund = _interopRequireDefault(require("./dialog/agreeRefund.vue"));

var _refuseRefund = _interopRequireDefault(require("./dialog/refuseRefund.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    agreeRefund: _agreeRefund.default,
    refuseRefund: _refuseRefund.default
  },
  data: function data() {
    return {
      searchForm: {
        order_no: "",
        shop_name: "",
        pay_type: "",
        create_time: ""
      },
      activeName: "3",
      currentId: "",
      orderId: "",
      count: {
        no_check: 0,
        checked: 0,
        check_refuse: 0
      },
      total: 0,
      tableData: [],
      repealVisible: false
    };
  },
  methods: {
    // 通过
    agree: function agree(row) {
      this.currentId = row.id;
      this.orderId = row.order_id;
      this.$refs.agreeRefund.dialogVisible = true;
      this.$refs.agreeRefund.isSuccess = true;
    },
    // 拒绝
    refuse: function refuse(id) {
      this.currentId = id;
      this.$refs.refuseRefund.dialogVisible = true;
    },
    checkDetails: function checkDetails(id) {
      this.currentId = id;
      this.$router.push({
        name: 'financeRefundDetail',
        params: {
          id: id
        }
      }); // this.repealVisible = true;
    },
    repealRefused: function repealRefused() {
      var _this = this;

      (0, _finance.cancelRefuseSettle)(this.currentId).then(function (res) {
        _this.repealVisible = false;
      });
    },
    jumpOrder: function jumpOrder(orderId) {
      this.$router.push({
        name: 'orderList',
        params: {
          orderId: orderId
        }
      });
    },
    // 获取数据
    getList: function getList(flag) {
      var _this2 = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _finance.orderRefundCheckList)((0, _objectSpread2.default)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        refund_status: this.activeName
      })).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;

        for (var key in _this2.count) {
          _this2.count[key] = res.count[key];
        }
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== "error") {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        order_no: "",
        shop_name: "",
        pay_type: "",
        create_time: ''
      };
      this.getList();
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;