var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-page" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "300px"
          }
        },
        [
          _c("div", { staticClass: "base_info" }, [
            _c("p", { staticClass: "edit-header" }, [
              _c("span", [_vm._v("访问白名单")])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "info_list"
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "配置平台：", prop: "member_id" } },
                  [_c("span", [_vm._v(_vm._s(_vm.platform))])]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "白名单：", prop: "white_list" } },
                  [
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        type: "textarea",
                        rows: 6,
                        placeholder: "请输入正确的IP地址"
                      },
                      model: {
                        value: _vm.formData.white_list,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "white_list", $$v)
                        },
                        expression: "formData.white_list"
                      }
                    }),
                    _c("div", { staticClass: "tips" }, [
                      _vm._v("若IP地址有多个，则需要用英文逗号隔开")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态 ：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.formData.status,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "status", $$v)
                          },
                          expression: "formData.status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("启用")
                        ]),
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v("禁用")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm.$hasMethod("#edit")
        ? _c(
            "div",
            { staticClass: "page-footer-btns" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }