import { render, staticRenderFns } from "./mealList.vue?vue&type=template&id=2ddb0134&scoped=true&"
import script from "./mealList.vue?vue&type=script&lang=js&"
export * from "./mealList.vue?vue&type=script&lang=js&"
import style0 from "./mealList.vue?vue&type=style&index=0&id=2ddb0134&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddb0134",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\xingxingzu\\multi_merchant_web\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ddb0134')) {
      api.createRecord('2ddb0134', component.options)
    } else {
      api.reload('2ddb0134', component.options)
    }
    module.hot.accept("./mealList.vue?vue&type=template&id=2ddb0134&scoped=true&", function () {
      api.rerender('2ddb0134', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/goods/mealList.vue"
export default component.exports