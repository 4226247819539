var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "工单处理", visible: _vm.dialogVisible, width: "500px" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "内容：", "label-width": _vm.formLabelWidth } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autocomplete: "off",
                  rows: 3,
                  placeholder: "请输入备注信息！例如：商家已向用户赔偿"
                },
                model: {
                  value: _vm.form.content,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确认处理")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }