var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category" },
    [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 }
          }),
          _vm._m(0)
        ],
        1
      ),
      _vm._l(_vm.dataList, function(item, index) {
        return [
          _c("category-form", {
            key: index,
            attrs: { "form-data": item, title: "推荐位" + (index + 1) },
            on: {
              upSortItem: function($event) {
                return _vm.upSortItem(index)
              },
              downSortItem: function($event) {
                return _vm.downSortItem(index)
              },
              deleteItem: function($event) {
                return _vm.deleteItem(index)
              }
            }
          })
        ]
      }),
      _c("div", { staticClass: "add-div", on: { click: _vm.addItem } }, [
        _c("i", { staticClass: "el-icon-plus" }),
        _vm._v("添加品类商品推荐位\n  ")
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("图片宽度650px，高度220px。")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }