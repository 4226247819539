var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-page" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-width": "160px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "上级分类:", prop: "module_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择上级分类" },
                  model: {
                    value: _vm.form.module_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "module_type", $$v)
                    },
                    expression: "form.module_type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "热门推荐", value: "1" } }),
                  _c("el-option", {
                    attrs: { label: "自定义模块", value: "2" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "板块名称:", prop: "main_title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.main_title,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "main_title", $$v)
                  },
                  expression: "form.main_title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序:" } },
            [
              _c("el-input-number", {
                model: {
                  value: _vm.form.module_sort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "module_sort", $$v)
                  },
                  expression: "form.module_sort"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-footer-btns" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.resetForm } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitForm }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }