"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _order = require("@/api/order");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      form: {
        orderId: "",
        deviceNumber: []
      },
      dialogVisible: false,
      deviceRule: {
        value: [{
          required: true,
          message: '设备编号不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)({}, _this.form);
          params.deviceNumber = params.deviceNumber ? params.deviceNumber.map(function (item) {
            return item.value;
          }).join(',') : '';
          (0, _order.setDeviceNumber)(params).then(function (res) {
            _this.dialogVisible = false;

            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        var _this$editItem = this.editItem,
            orderId = _this$editItem.orderId,
            deviceNumber = _this$editItem.deviceNumber,
            amount = _this$editItem.amount;
        this.form.orderId = orderId;
        this.form.deviceNumber = [];

        for (var i = 0; i < amount; i++) {
          var obj = {
            label: '设备编号' + (i + 1),
            value: deviceNumber[i] || ''
          };
          this.form.deviceNumber.push(obj);
        }
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};
exports.default = _default;