"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _index = require("./index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      baseUrl: '',
      orderNo: "",
      goodsInfo: {},
      imageList: [],
      currentImg: "",
      totalRent: '',
      buyoutRent: ''
    };
  },
  created: function created() {
    this.orderNo = this.$route.query.orderNo;
  },
  methods: {
    showImg: function showImg(url) {
      this.currentImg = url;
    },
    getDetails: function getDetails() {
      var _this = this;

      (0, _index.getGoodsDetails)({
        orderNo: this.orderNo
      }).then(function (res) {
        // console.log('res',res)
        _this.goodsInfo = res;
        var urls = [];

        if (res.images && res.images.indexOf(",") == -1) {
          urls.push(res.images);
          _this.imageList = urls.map(function (item) {
            return _this.baseUrl + item;
          });
        } else {
          urls = res.images.split(',');
          _this.imageList = urls.map(function (item) {
            return _this.baseUrl + item;
          });
        }

        _this.currentImg = _this.imageList[0];
        var total1 = String(res.unit_price * res.month);
        _this.totalRent = total1 > 0 ? total1.substring(0, total1.indexOf(".") + 3) : 0;
        var price = String(res.market_price - _this.totalRent);
        _this.buyoutRent = price > 0 ? price.substring(0, price.indexOf(".") + 3) : 0;
      });
    }
  },
  mounted: function mounted() {
    this.getDetails();
  }
};
exports.default = _default;