"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["goodsId"],
  data: function data() {
    return {
      routeParam: {
        name: 'goodsList'
      },
      btnText: '查看商品列表'
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (from.name === "goodsMealEdit") {
      next(function (vm) {
        vm.routeParam = {
          name: 'goodsMealInfo',
          params: {
            goodsId: vm.goodsId
          }
        };
        vm.btnText = '返回套餐列表';
      });
    } else {
      next();
    }
  }
};
exports.default = _default;