"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _finance = require("@/api/finance");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["orderId"],
  data: function data() {
    return {
      dialogVisible: false,
      orderData: [],
      rateData: []
    };
  },
  methods: {
    huabeiSettle: function huabeiSettle() {
      var _this = this;

      (0, _finance.huabeiOrderSettle)(this.orderId).then(function (res) {
        _this.dialogVisible = false;

        _this.$parent.getList();
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;

      if (val) {
        (0, _finance.getHuabeiSettleData)(this.orderId).then(function (res) {
          _this2.orderData = [{}];

          for (var i in res) {
            _this2.$set(_this2.orderData[0], i, res[i]);
          }
        });
      }
    }
  }
};
exports.default = _default;