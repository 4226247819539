"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _imageViewer = _interopRequireDefault(require("element-ui/packages/image/src/image-viewer"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ElImageViewer: _imageViewer.default
  },
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      showFront: false,
      showBack: false,
      idcard_back: '',
      idcard_front: ''
    };
  },
  methods: {
    // 关闭预览
    closeFront: function closeFront() {
      this.showFront = false;
    },
    closeBack: function closeBack() {
      this.showBack = false;
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.idcard_back = this.editItem.idcard_back;
        this.idcard_front = this.editItem.idcard_front;
      } else {
        this.idcard_back = '';
        this.idcard_front = '';
      }
    }
  }
};
exports.default = _default;