"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es6.array.find");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _router = require("@/router");

/**
 * @param routes
 * @param routeName
 */
function getRoute(routes, routeName) {
  var _iterator = (0, _createForOfIteratorHelper2.default)(routes),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      if (item.name === routeName) return (0, _objectSpread2.default)({}, item);

      if (item.children && routeName.indexOf(item.name) > -1) {
        return getRoute(item.children, routeName);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return null;
}
/***
 * 获取匹配到的路由数组
 */


function getRouteList(routes, menu) {
  var res = [];
  menu.forEach(function (item) {
    var obj = getRoute(routes, item.path);

    if (obj) {
      // 分配按钮权限
      if (item.children) {
        var mList = item.children.filter(function (k) {
          return k.path.indexOf("#") > -1;
        });

        if (mList.length > 0) {
          obj.meta.methods = mList.map(function (p) {
            return p.path;
          });
        }
      }

      res.push(obj);
    }
  });
  return res;
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param menu
 */


function filterAsyncRoutes(routes, menu) {
  var res = [];
  menu.forEach(function (item) {
    if (item.path) {
      var obj = getRoute(routes, item.path);

      if (obj) {
        obj.children = getRouteList(routes, item.children);

        var _iterator2 = (0, _createForOfIteratorHelper2.default)(item.children),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var child = _step2.value;

            if (child.children) {
              var result = getRouteList(routes, child.children);
              obj.children = [].concat((0, _toConsumableArray2.default)(obj.children), (0, _toConsumableArray2.default)(result));
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        var rdObj = obj.children.find(function (k) {
          return !k.hidden;
        });
        obj.redirect = obj.path + "/" + rdObj.path;
        res.push(obj);
      }
    }
  });
  return res;
}

var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, menu) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes = []; // 过滤掉第一级

      var menuArr = [];

      var _iterator3 = (0, _createForOfIteratorHelper2.default)(menu),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var item = _step3.value;
          menuArr = menuArr.concat(item.children);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, menuArr); // 最后添加404

      accessedRoutes.push({
        path: "*",
        redirect: "/404",
        hidden: true
      }); // console.log(accessedRoutes);

      commit("SET_ROUTES", accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;