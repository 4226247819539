"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encrypt = encrypt;
exports.decrypt = decrypt;
exports.decryptID = exports.encryptID = void 0;

require("core-js/modules/es6.regexp.to-string");

var _cryptoJs = require("crypto-js");

var encryptID = function encryptID(plainText, key) {
  var encryptedBytes = _cryptoJs.AES.encrypt(plainText, _cryptoJs.enc.Utf8.parse(key), {
    mode: _cryptoJs.mode.ECB,
    padding: _cryptoJs.pad.Pkcs7
  }).ciphertext;

  var encryptedText = _cryptoJs.enc.Base64.stringify(encryptedBytes);

  return encryptedText;
}; // 解密函数


exports.encryptID = encryptID;

var decryptID = function decryptID(encryptedID, key) {
  var encryptedBytes = _cryptoJs.enc.Base64.parse(encryptedID);

  var decryptedBytes = _cryptoJs.AES.decrypt({
    ciphertext: encryptedBytes
  }, _cryptoJs.enc.Utf8.parse(key), {
    mode: _cryptoJs.mode.ECB,
    padding: _cryptoJs.pad.Pkcs7
  });

  var decryptedText = decryptedBytes.toString(_cryptoJs.enc.Utf8);
  return decryptedText;
};

exports.decryptID = decryptID;
var crypt_key = process.env.VUE_APP_AES_KEY;
var crypt_iv = process.env.VUE_APP_AES_IV; // console.log('crypt_key,crypt_iv', crypt_key, crypt_iv)
// 加密

function encrypt(data) {
  var aes_key = _cryptoJs.enc.Utf8.parse(crypt_key);

  var new_iv = _cryptoJs.enc.Utf8.parse(crypt_iv);

  var encrypted = _cryptoJs.AES.encrypt(data, aes_key, {
    iv: new_iv,
    mode: _cryptoJs.mode.CBC,
    padding: _cryptoJs.pad.ZeroPadding
  }); // 返回字符串


  return encrypted.toString();
} //解密


function decrypt(data) {
  var aes_key = _cryptoJs.enc.Utf8.parse(crypt_key);

  var aes_iv = _cryptoJs.enc.Utf8.parse(crypt_iv);

  var baseResult = _cryptoJs.enc.Base64.parse(data);

  var ciphertext = _cryptoJs.enc.Base64.stringify(baseResult);

  var decryptResult = _cryptoJs.AES.decrypt(ciphertext, aes_key, {
    iv: aes_iv,
    mode: _cryptoJs.mode.CBC,
    padding: _cryptoJs.pad.ZeroPadding
  }); // 返回字符串


  var resData = decryptResult.toString(_cryptoJs.enc.Utf8).toString();
  return resData;
}