"use strict";

var _interopRequireDefault = require("D:/www/xingxingzu/multi_merchant_web/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPlatformAPI = createPlatformAPI;
exports.updatePlatformAPI = updatePlatformAPI;
exports.insertOrderInfoAPI = insertOrderInfoAPI;
exports.useMultiTableAPI = useMultiTableAPI;
exports.updateOrderStatusAPI = updateOrderStatusAPI;
exports.decryptIDAPI = decryptIDAPI;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

/**
 * 浓度统计相关api
 * insertOrderInfo
 **/

/**
 * 创建平台
 * platform_id: string;
 **/
function createPlatformAPI(platform) {
  return _fetch.default.post('/Admin/Supplier/createPlatform', platform, true);
} // 更新平台信息


function updatePlatformAPI(platform) {
  return _fetch.default.post('/Admin/Supplier/updatePlatform', platform, true);
} // 插入订单信息


function insertOrderInfoAPI(platform) {
  return _fetch.default.post('/Admin/Supplier/insertOrderInfo', platform, true);
}
/**
 * 使用其他平台的统计数据，直接传一个平台ID即可
 **/


function useMultiTableAPI(platform) {
  return _fetch.default.post('/Admin/Supplier/useMultiTable', platform, true);
} // 更新平台订单状态


function updateOrderStatusAPI(params) {
  return _fetch.default.post('/Admin/Supplier/updateOrderStatus', params, true);
} // 后端解密数据


function decryptIDAPI(params) {
  return _fetch.default.post('/Admin/Supplier/decryptID', params, true);
}