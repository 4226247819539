var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-page" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-width": "160px" }
        },
        [
          _vm.type === "info"
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "品类一级名称:", prop: "cate_name" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.cate_name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "cate_name", $$v)
                        },
                        expression: "form.cate_name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "品类缩略图:", prop: "cate_img" } },
                  [
                    _c("crop-upload", {
                      attrs: {
                        "show-type": "photo",
                        "init-val": _vm.form.cate_img,
                        "file-size": "100K",
                        "wrapper-width": "95px",
                        "wrapper-height": "95px"
                      },
                      on: {
                        setVal: function(val) {
                          return _vm.setUrl(val)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.type === "child"
            ? _c(
                "el-form-item",
                { attrs: { label: "新增子品类:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        placeholder: "一级分类"
                      },
                      model: {
                        value: _vm.form.second_id,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "second_id", $$v)
                        },
                        expression: "form.second_id"
                      }
                    },
                    _vm._l(_vm.cateList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.type !== "child"
            ? _c(
                "el-form-item",
                { attrs: { label: "排序:" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.cate_sort,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cate_sort", $$v)
                      },
                      expression: "form.cate_sort"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "状态:" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.status,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status"
                  }
                },
                [_vm._v("显示")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.form.status,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status"
                  }
                },
                [_vm._v("隐藏")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "page-footer-btns" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.resetForm } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitForm }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }