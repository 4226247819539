var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col_form" },
    [
      _c(
        "p",
        { staticClass: "color-666", staticStyle: { "line-height": "35px" } },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm.type === "thirdHot"
        ? _c("crop-upload", {
            staticClass: "w-100 img_bg",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.backgroundImg,
              "wrapper-width": "359px",
              "wrapper-height": "215px",
              "img-width": 690,
              "img-height": 415,
              "photo-width": 359,
              "photo-height": 215
            },
            on: {
              setVal: function(val) {
                _vm.formData.backgroundImg = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "bannerCol"
        ? _c("crop-upload", {
            staticClass: "w-100 img_bg",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.backgroundImg,
              "wrapper-width": "359px",
              "wrapper-height": "258px",
              "img-width": 335,
              "img-height": 240,
              "photo-width": 359,
              "photo-height": 258
            },
            on: {
              setVal: function(val) {
                _vm.formData.backgroundImg = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "twoColGoods"
        ? _c("crop-upload", {
            staticClass: "w-100 img_bg",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.backgroundImg,
              "wrapper-width": "359px",
              "wrapper-height": "305px",
              "img-width": 344,
              "img-height": 292,
              "photo-width": 359,
              "photo-height": 305
            },
            on: {
              setVal: function(val) {
                _vm.formData.backgroundImg = val
              }
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "input_wrapper" }, [
        _c(
          "div",
          { staticClass: "flex name" },
          [
            _vm._m(0),
            _c("el-input", {
              attrs: { placeholder: "请输入主标题", clearable: "" },
              model: {
                value: _vm.formData.title,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "title", $$v)
                },
                expression: "formData.title"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex name" },
          [
            _vm._m(1),
            _c("el-input", {
              attrs: { placeholder: "请输入副标题", clearable: "" },
              model: {
                value: _vm.formData.subtitle,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "subtitle", $$v)
                },
                expression: "formData.subtitle"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex price" },
          [
            _vm._m(2),
            _c("el-input", {
              attrs: { placeholder: "请输入链接", clearable: "" },
              model: {
                value: _vm.formData.jumpUrl,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "jumpUrl", $$v)
                },
                expression: "formData.jumpUrl"
              }
            })
          ],
          1
        )
      ]),
      _vm.contentType === "goods"
        ? [
            _vm._l(_vm.goodsList, function(item, index) {
              return _c("goods-link", {
                key: item.goods_id,
                attrs: { "goods-data": item, "show-tool": true },
                on: {
                  handleDelete: function($event) {
                    return _vm.deleteGoods(index)
                  },
                  upSort: function($event) {
                    return _vm.upSort(index)
                  },
                  downSort: function($event) {
                    return _vm.downSort(index)
                  }
                }
              })
            }),
            _vm.goodsList.length < _vm.goodsNum
              ? _c(
                  "div",
                  { staticClass: "add-div", on: { click: _vm.openSelect } },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v(
                      "添加商品(" +
                        _vm._s(_vm.goodsList.length) +
                        "/" +
                        _vm._s(_vm.goodsNum) +
                        ")\n    "
                    )
                  ]
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm.contentType === "img"
        ? [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("crop-upload", {
                      staticClass: "w-100 img_bg",
                      attrs: {
                        "show-type": "photo",
                        "is-square": true,
                        "init-val": _vm.goods[0].imgurl,
                        "wrapper-width": "170px",
                        "wrapper-height": "170px",
                        "photo-width": 170,
                        "photo-height": 170
                      },
                      on: {
                        setVal: function(val) {
                          _vm.goods[0].imgurl = val
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("crop-upload", {
                      staticClass: "w-100 img_bg",
                      attrs: {
                        "show-type": "photo",
                        "is-square": true,
                        "init-val": _vm.goods[1].imgurl,
                        "wrapper-width": "170px",
                        "wrapper-height": "170px",
                        "photo-width": 170,
                        "photo-height": 170
                      },
                      on: {
                        setVal: function(val) {
                          _vm.goods[1].imgurl = val
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c("goods-dialog", {
        ref: "goodsDialog",
        attrs: {
          multiple: true,
          maxNum: _vm.goodsNum,
          "show-checked-tool": true
        },
        on: { setSelect: _vm.setSelect }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("主标题")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("副标题")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("链接")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }