"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _web = require("@/api/web");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["editId"],
  data: function data() {
    return {
      form: {
        module_type: null,
        main_title: "",
        module_sort: 0
      },
      rules: {
        module_type: [{
          required: true,
          message: '请选择上级分类',
          trigger: 'change'
        }],
        main_title: [{
          required: true,
          message: '板块名称不能为空',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    // 编辑获取信息
    getData: function getData() {
      var _this = this;

      (0, _web.h5HomeShowById)(this.editId).then(function (res) {
        _this.form.id = res.id;
        _this.form.main_title = res.main_title;
        _this.form.module_type = res.module_type + "";
        _this.form.module_sort = res.module_sort;
      });
    },
    // 表单提交
    submitForm: function submitForm() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          (0, _web.addOrEditH5HomeShow)(_this2.form).then(function (res) {
            if (!_this2.editId) {
              // 新增跳转列表页
              _this2.$router.push({
                name: "h5ConfigShow"
              });
            }
          });
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    },
    // 重置
    resetForm: function resetForm() {
      this.$refs['form'].resetFields();

      if (this.editId) {
        this.getData();
      } else {
        this.form = {
          module_type: null,
          main_title: "",
          module_sort: 0
        };
      }
    }
  },
  mounted: function mounted() {
    // console.log(this.editId);
    if (this.editId) {
      this.$route.meta.title = "编辑板块";
      this.getData();
    } else {
      this.$route.meta.title = "添加板块";
    }
  }
};
exports.default = _default;