"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lease = require("@/api/lease");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['orderNo'],
  data: function data() {
    return {
      tableData: [],
      orderList: [],
      topForm: {},
      rentStatus: '',
      shopname: ''
    };
  },
  methods: {
    checkFlow: function checkFlow(url) {
      if (!url) {
        this.$message.warning("暂无合同");
      } else {
        window.open(this.$globalObj.imgBaseUrl + url);
      }
    },
    checkPhoto: function checkPhoto(url) {
      if (!url) {
        this.$message.warning("暂无照片");
      } else {
        window.open(this.$globalObj.imgBaseUrl + url);
      }
    },
    toBack: function toBack() {
      this.$router.go(-1);
    },
    jumpGoodsShoot: function jumpGoodsShoot(id) {
      var _this$$router$resolve = this.$router.resolve({
        path: "/goodsDetails",
        query: {
          orderNo: id
        }
      }),
          href = _this$$router$resolve.href;

      window.open(href, '_blank');
    },
    jumpLogistics: function jumpLogistics(num) {
      var _this$$router$resolve2 = this.$router.resolve({
        path: "/logisticsH5",
        query: {
          orderNo: num
        }
      }),
          href = _this$$router$resolve2.href;

      window.open(href, '_blank');
    },
    // 获取列表
    getList: function getList() {
      var _this = this;

      (0, _lease.financDetails)({
        orderNo: this.orderNo
      }).then(function (res) {
        _this.topForm = res;
        _this.tableData = [res];
        _this.shopname = res.shopName.shopname;
        _this.rentStatus = res.orderLease[res.orderLease.length - 1].rent_status;
        _this.orderList = res.orderLease;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;