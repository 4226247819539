"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _index = require("./index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      baseUrl: '',
      orderNo: '',
      reverse: true,
      logisticsData: {},
      activities: [],
      deliver_imgList: [],
      confirm_imgList: [],
      dialogTitle: '',
      imgurl: '',
      dialogVisible: false
    };
  },
  created: function created() {
    var jumpId = this.$route.query.orderNo;
    this.orderNo = jumpId || '2021061110393635842';
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;

      (0, _index.getProof)({
        orderNo: this.orderNo
      }).then(function (res) {
        _this.logisticsData = res;
        _this.deliver_imgList = res.deliver_images ? res.deliver_images.split(',') : [];
        console.log('img', _this.deliver_imgList);
        _this.confirm_imgList = res.confirm_images ? res.confirm_images.split(',') : [];
        _this.activities = res.info ? JSON.parse(res.info).data : [];
      });
    },
    toView: function toView(num, url) {
      this.dialogVisible = true;
      this.imgurl = url;

      if (num == 1) {
        this.dialogTitle = "查看发货凭证";
      } else {
        this.dialogTitle = "查看签收凭证";
      }
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    }
  },
  mounted: function mounted() {
    this.getInfo();
  }
};
exports.default = _default;