"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["id"],
  data: function data() {
    return {
      form: {
        card_show_name: '',
        write_off_type: 'qrcode',
        type: [],
        year: '10',
        template_id: '',
        apply_card_url: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      (0, _user.getSupplierVipCard)(this.id).then(function (res) {
        console.log(res);
        _this.form = res; // if(res.data){
        // 	this.form = res.data
        // 	this.tableData = res.config
        // 	this.loading = false
        // }
      });
    },
    onSubmit: function onSubmit() {
      var data = this.form;
      data.member_id = this.id;

      if (data.type.length == 0) {
        this.$message.error('开卡信息 最少有一项');
        return false;
      }

      (0, _user.saveSupplierVipCard)(data).then(function (res) {
        console.log(res);
      });
    },
    download: function download() {
      window.location.href = process.env.VUE_APP_BASE_API + "/muban/vipOpenCatd.doc";
    }
  }
};
exports.default = _default;